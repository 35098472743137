import {createApp, ref} from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/assets/css/main.scss'
import store from './store'
import helper from "@/utils/helper";
import {dragscrollNext} from "vue-dragscroll";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import {createStaffHttpClient} from "@/API/HttpClient.js";

let app = createApp(App)
app.config.globalProperties.$loading = ref(false)
app.config.globalProperties.$errorModal = ref(false)
app.config.globalProperties.$infoModal = ref(false)
app.config.globalProperties.$sideMenu = ref(false)
app.config.globalProperties.$imageModal = ref(false)

createStaffHttpClient()

app
    .use(store)
    .use(router)
    .use(helper)
    .use(Toast)
    .use(FloatingVue)
    .directive('dragscroll', dragscrollNext)
    .mount('#app')



export default app

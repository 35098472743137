<template>
  <ModalView title="برگشت سفارش">

    <form @submit.prevent="returnInvoice">
      <VInput class="mt-4" placeholder="دلیل برگشت" v-model="description" required/>

      <VButton class="mt-4" type="submit" :loading="$loading.value">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {returnInvoice} from "@/API/API";

export default {
  name: "InvoiceReturnModal",
  props: ['invoice'],
  emits: ['returned'],
  components: {VButton, VInput, ModalView},
  data() {
    return {
      description: '',
      subSystemMenuId: 103070200
    }
  },
  methods: {
    returnInvoice() {
      returnInvoice(this.invoice.InvoiceId, this.description, this.subSystemMenuId)
          .then(resp => {
            this.$emit('returned')
            this.$infoModal.value = resp.data.message
            this.$router.replace({name: 'DistributorInvoiceListDelivery'})
          })
    }
  }
}
</script>

<style scoped>

</style>
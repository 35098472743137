<template>
  <ModalView title="لفو مرجوعی" @closed="resetData">

    <form @submit.prevent="cancelInvoiceReturn">

      <VInput class="mt-4" placeholder="توضیحات" v-model="cancel.rejectDescription" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import {cancelInvoiceReturn} from "@/API/API.js";
import VButton from "@/components/General/VButton.vue";
import VInput from "@/components/General/VInput.vue";

let cancelModel = {
  rejectReasonId: '',
  rejectDescription: '',
}

export default {
  name: "CancelInvoiceReturnModal",
  emits: ['canceled'],
  props: ['invoiceReturnId'],
  components: {ModalView, VButton, VInput},
  data() {
    return {
      rejectReasons: [],
      cancel: {...cancelModel}
    }
  },
  methods: {
    cancelInvoiceReturn() {
      cancelInvoiceReturn(this.invoiceReturnId, this.cancel)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('canceled')
          })
    },
    resetData() {
      this.cancel = {...cancelModel}
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="رد کالا" class="text-gray-500" @opened="modalOpened">

    <form @submit.prevent="reject">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="stockDetailReject.RejectReasonLId"
          :options="rejectReasons"
          :searchable="true"
          placeholder="دلیل رد"/>

      <VInput class="mt-4" placeholder="تعداد" type="number" min="1" v-model="stockDetailReject.RejectAmount"
              :max="stockDetail.Amount"
              required/>

      <div class="flex mt-4">
        <VButton @click.prevent="$emit('reset', stockDetail)" outline>لغو</VButton>
        <VButton class="mr-2" type="submit" :disabled="!stockDetailReject.RejectReasonLId">ثبت</VButton>
      </div>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {getLookupCombos, replaceStockDetail} from "@/API/API";
import Multiselect from "@vueform/multiselect";

export default {
  name: "StockDetailRejectModal",
  props: ['stockDetail', 'type'],
  emits: ['rejected', 'reset', 'replaced'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      rejectReasons: [],
      stockDetailReject: {
        StockDetailId: '',
        RejectReasonLId: '',
        RejectAmount: ''
      }
    }
  },
  methods: {
    async getRejectReasons() {
      if (this.rejectReasons.length === 0)
        this.rejectReasons = (await getLookupCombos(this.getSystemMenuCode())).data.data.Lookups.DenyReasonStates.map(reason => ({
          label: reason.Name,
          value: reason.LookupId
        }))
    },
    reject() {
      if (this.type === 'inventory-packer' || this.type === 'inventory-controller' )
        replaceStockDetail(this.stockDetail.StockDetailId, this.stockDetailReject)
            .then(resp => {
              this.$infoModal.value = resp.data.message
              this.$emit('replaced')
            })
      else
        this.$emit('rejected', this.stockDetailReject)
    },
    modalOpened() {
      this.getRejectReasons()

      this.stockDetailReject.StockDetailId = this.stockDetail.StockDetailId
      this.stockDetailReject.RejectReasonLId = this.stockDetail.RejectReasonLId
      this.stockDetailReject.RejectAmount = this.stockDetail.RejectAmount
    },
    getSystemMenuCode() {
      switch (this.type) {
        case 'inventory-packer':
            return 103050101
        case 'inventory-controller':
          return 103060101
      }
    }
  },
}
</script>

<style scoped>

</style>
import axios from 'axios';
import store from '@/store/index'
import app from '@/main'
import router from "@/router/index.js";

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_STAFF_URL,
    headers: {
        "Content-Type": "application/json",
    },
    params: {
        subSystemMenuId: ''
    }
});

function createStaffHttpClient() {
    if (store.getters.isLoggedIn) {
        httpClient.defaults.headers.common['Authorization'] = store.getters.token
    }

    httpClient.interceptors.request.use(config => {
        app.config.globalProperties.$loading.value = true
        config.params.subSystemMenuId ||= router.currentRoute.value.meta.code
        return config
    })

    httpClient.interceptors.response.use(
        response => {
            app.config.globalProperties.$loading.value = false
            return response
        },
        error => {
            app.config.globalProperties.$loading.value = false
            app.config.globalProperties.$errorModal.value = error.response.data.message
            if (error.response.status === 401) {
                store.dispatch('logout');
            }
            return Promise.reject(error);
        });
}

export {httpClient, createStaffHttpClient};
<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <!-- InvoiceReturn Info-->
      <InvoiceReturnInfoCard :invoiceReturn="invoiceReturn"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Items -->
      <InvoiceReturnProductsTable
          :items="invoiceReturn.Items"
          type="supporter"
          :canEdit="canEdit"
          @returnDetailEdited="returnDetailEdited"
          @delete-item="deleteItem"/>

      <!-- Coordination -->
      <CoordinationsTable
          :coordinations="invoiceReturn.Coordinations"
          type="invoice-return"
          :id="invoiceReturn.InvoiceReturnId"
          :canEdit="canEdit"
          @submitted="coordinationSubmitted"/>

      <InvoiceReturnLog
          class="mt-4"
          :invoice-return-id="this.invoiceReturn.InvoiceReturnId"/>

    </div>
  </div>
</template>

<script>
import {getInvoiceReturn} from "@/API/API";
import {mapState} from "vuex";
import InvoiceReturnInfoCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnInfoCard";
import CoordinationsTable from "@/components/CoordinationsTable";
import InvoiceReturnProductsTable from "@/components/InvoiceReturn/InvoiceReturnProductsTable";
import InvoiceReturnLog from "@/components/InvoiceReturn/InvoiceReturnLog.vue";

export default {
  name: "InvoiceReturnDetail",
  components: {
    InvoiceReturnLog,
    InvoiceReturnProductsTable,
    CoordinationsTable,
    InvoiceReturnInfoCard,
  },
  data() {
    return {
      invoiceReturn: {},
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id)).data.data
    },
  }
}
</script>

<style scoped>

</style>
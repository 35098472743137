<template>
  <div></div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>

<style scoped>

</style>

<template>
  <ModalView
      :title="editMode ? 'ویرایش برند' : 'ایجاد برند'">

    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <VInput type="text" placeholder="نام برند" v-model="brand.Name"/>
      <VInput type="text" placeholder="نام انگلیسی برند" v-model="brand.NameEnglish" class="mt-4"/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createBrand, updateBrand} from "@/API/AdminAPI";

const brandModel = {
  Name: '',
  NameEnglish: ''
}

export default {
  name: "CreateBrandModal",
  emits: ['created', 'updated'],
  props: ['editBrand'],
  components: {VButton, VInput, ModalView},
  data() {
    return {
      editMode: false,
      brand: {...brandModel},
    }
  },
  methods: {
    create() {
      createBrand(this.brand)
          .then(resp => {
            this.$emit('created', resp.data.data)
            this.brand = {...brandModel}
          })
    },
    update() {
      updateBrand(this.editBrand.BrandId, this.brand)
          .then(resp => this.$emit('updated', resp.data.data))
    }
  },
  watch: {
    editBrand(editBrand) {
      if (editBrand.BrandId) {
        this.editMode = true
        this.brand = this.$helper.cloneObject(editBrand, Object.keys(brandModel))
      } else {
        this.editMode = false
        this.brand = {...brandModel}
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="ثبت تعداد " @opened="modalOpened">

    <form @submit.prevent="submit">
      <!-- Distributor Related Fields -->
      <div v-if="type === 'distributor'">
        <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

        <VSelect class="mt-4" v-model="editInvoiceReturnDetail.ReturnReasonLId" required>
          <option value="" disabled>علت مرجوعی</option>
          <option
              v-for="reason in returnReasons"
              :key="reason.LookupId"
              :value="reason.LookupId">
            {{ reason.Name }}
          </option>
        </VSelect>

        <VInput class="mt-4" placeholder="توضیحات" v-model="editInvoiceReturnDetail.Description"/>
      </div>

      <!-- Support, Distributor Related Fields -->
      <div class="grid grid-cols-3 mt-4 gap-4" v-if="type === 'supporter' || this.type === 'distributor'">

        <NumberPicker class="col-span-3" label="تعداد کل" v-model.number="editInvoiceReturnDetail.Amount"
                      :max="editInvoiceReturnDetail.StockDetail.Amount - editInvoiceReturnDetail.StockDetail?.PreviousReturnAmount"
                      :min="1"
                      required/>

        <NumberPicker label="بدون نیاز بازگشت" v-model.number="editInvoiceReturnDetail.NoNeedAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.HealthyAmount - editInvoiceReturnDetail.DefectAmount"
                      required/>

        <NumberPicker label="تعداد سالم" v-model.number="editInvoiceReturnDetail.HealthyAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.NoNeedAmount - editInvoiceReturnDetail.DefectAmount"
                      required/>

        <NumberPicker label="تعداد ضایعاتی" v-model.number="editInvoiceReturnDetail.DefectAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.NoNeedAmount - editInvoiceReturnDetail.HealthyAmount"
                      required/>

      </div>

      <!-- Inventory-Staff-Receive, QC-staff Related Fields -->
      <div class="grid grid-cols-3 mt-4 gap-4" v-if="type === 'inventory-staff-receive' || type === 'QC-staff'">

        <div class="col-span-3">
          <label class="block text-primary mb-1">تعداد کل</label>
          <div class="border border-primary rounded p-1 text-center">
            {{ editInvoiceReturnDetail.Amount }}
          </div>
        </div>

        <div v-if="type === 'QC-staff'">
          <label class="block text-primary mb-1">بدون نیاز بازگشت</label>
          <div class="border border-primary rounded p-1 text-center">
            {{ editInvoiceReturnDetail.InventoryNoNeedAmount }}
          </div>
        </div>

        <NumberPicker label="بدون نیاز بازگشت" v-model.number="editInvoiceReturnDetail.InventoryNoNeedAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.InventoryDefectAmount - editInvoiceReturnDetail.InventoryHealthyAmount"
                      v-if="type === 'inventory-staff-receive'"
                      required/>

        <NumberPicker label="تعداد سالم" v-model.number="editInvoiceReturnDetail.InventoryHealthyAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.InventoryNoNeedAmount - editInvoiceReturnDetail.InventoryDefectAmount"
                      required/>

        <NumberPicker label="تعداد ضایعاتی" v-model.number="editInvoiceReturnDetail.InventoryDefectAmount"
                      :max="editInvoiceReturnDetail.Amount - editInvoiceReturnDetail.InventoryNoNeedAmount - editInvoiceReturnDetail.InventoryHealthyAmount"
                      required/>

      </div>

      <VButton class="mt-4" type="submit">ثبت موقت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import {Dropzone} from "dropzone";
import {openDefaultEditor} from "@/assets/pintura";
import {getLookupCombos} from "@/API/API";
import VSelect from "@/components/General/VSelect";
import NumberPicker from "@/components/General/NumberPicker";

export default {
  name: "EditInvoiceReturnDetailModal",
  props: ['invoiceReturnDetail', 'type'],
  emits: ['edited'],
  components: {NumberPicker, VSelect, VInput, VButton, ModalView},
  data() {
    return {
      returnReasons: [],

      editInvoiceReturnDetail: {
        stock_detail: {},
        edited: false,

        Amount: '',

        HealthyAmount: '',
        DefectAmount: '',
        NoNeedAmount: '',

        InventoryNoNeedAmount: '',
        InventoryHealthyAmount: '',
        InventoryDefectAmount: '',

        ReturnReasonLId: '',
        Description: '',
      },
      PreviousInventoryHealthyAmount: '',
      PreviousInventoryDefectAmount: '',
    }
  },
  methods: {
    async getReturnReasons() {
      if (this.type === 'distributor' && this.returnReasons.length === 0)
        this.returnReasons = (await getLookupCombos(103070201)).data.data.Lookups.Lookups7
    },
    submit() {
      if (this.type === 'supporter' || this.type === 'distributor') {
        let sum = this.editInvoiceReturnDetail.HealthyAmount + this.editInvoiceReturnDetail.DefectAmount + this.editInvoiceReturnDetail.NoNeedAmount

        if (sum !== this.editInvoiceReturnDetail.Amount) {
          this.$errorModal.value = 'جمع مقادیر ورودی برابر با تعداد ثبت شده نیست.'
          return
        }

        if (this.editInvoiceReturnDetail.Image?.length === 0) {
          this.$errorModal.value = 'لطفا برای مرجوعی عکس ثبت کنید.'
          return
        }

      } else if (this.type === 'inventory-staff-receive' || this.type === 'QC-staff') {
        let sum = this.editInvoiceReturnDetail.InventoryHealthyAmount + this.editInvoiceReturnDetail.InventoryDefectAmount + this.editInvoiceReturnDetail.InventoryNoNeedAmount

        if (sum !== this.editInvoiceReturnDetail.Amount) {
          this.$errorModal.value = 'جمع مقادیر ورودی برابر با تعداد ثبت شده نیست.'
          return
        }
      }

      this.editInvoiceReturnDetail.edited = true
      this.$emit('edited', this.editInvoiceReturnDetail)
    },
    initEditor() {
      let dropzone = new Dropzone(this.$refs.dropzone, {
        url: process.env.VUE_APP_STAFF_URL + 'user/file',
        headers: {'Authorization': this.$store.getters.token},
        maxFilesize: 1,
        dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
        dictDefaultMessage: 'انتخاب تصاویر',
        dictCancelUpload: 'لغو',
        maxFiles: 5,
        acceptedFiles: 'image/jpeg, image/png',
        sending(file, xhr, formData) {
          formData.append('type', 'image')
        },
        transformFile: (file, done) => {
          const editor = openDefaultEditor({
            imageCropAspectRatio: 1,
            src: file,
            imageWriter: {
              quality: 0.1,
            },
            utils: ['crop', 'annotate'],
          });
          // When done, assign the resulting file back to dropzone
          editor.on('process', (imageState) => {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(imageState.dest)
            fileReader.onload = () => {
              dropzone.emit('thumbnail', file, fileReader.result);
              done(imageState.dest)
            };
          });
        },
        success: (file, response) => {
          file.JCoFileId = response.JCoFileId;
          this.editInvoiceReturnDetail.Images.push({
            JCoFileId: response.JCoFileId,
            Path: response.Path
          })
        },
      });

      // Load Previous Images
      this.editInvoiceReturnDetail.Images.forEach(image => {
        var mockFile = {name: 'image', size: 0, JCoFileId: image.JCoFileId};
        dropzone.options.addedfile.call(dropzone, mockFile);
        dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(image.Path));
        mockFile.previewElement.classList.add('dz-success');
        mockFile.previewElement.classList.add('dz-complete');
        dropzone.options.maxFiles -= 1;
      })

    },
    modalOpened() {
      if (this.invoiceReturnDetail) {
        this.editInvoiceReturnDetail = this.$helper.cloneObject(this.invoiceReturnDetail)
        this.editInvoiceReturnDetail.StockDetailId = this.editInvoiceReturnDetail.StockDetail.StockDetailId

        this.getReturnReasons()

        if (this.type === 'distributor')
          setTimeout(() => this.initEditor(), 50)
      }
    }
  },
}
</script>

<style scoped>

</style>
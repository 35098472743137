<template>
  <ModalView title="لغو تخصیص" @closed="resetData" @opened="getRejectReasons">

    <form @submit.prevent="cancelAssignment">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          :options="rejectReasons"
          v-model="cancel.rejectReasonId"
          :searchable="true"
          placeholder="دلیل رد"
          required/>

      <VInput class="mt-4" placeholder="توضیحات" v-model="cancel.rejectDescription" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import {
  cancelDistributorVehicleAssignment,
  cancelDistributorVehicleInvoice,
  cancelDistributorVehicleInvoiceReturn,
  getLookupCombos
} from "@/API/API.js";
import Multiselect from "@vueform/multiselect";
import VButton from "@/components/General/VButton.vue";
import VInput from "@/components/General/VInput.vue";

let cancelModel = {
  rejectReasonId: '',
  rejectDescription: '',
}

export default {
  name: "CancelAssignmentModal",
  emits: ['submit'],
  props: ['type', 'cancelableId'],
  components: {ModalView, Multiselect, VButton, VInput},
  data() {
    return {
      rejectReasons: [],
      states: [],

      cancel: {...cancelModel}
    }
  },
  methods: {
    async getRejectReasons() {
      this.rejectReasons = (await getLookupCombos(this.$route.meta.code)).data.data.Lookups.DenyReasonStates.map(reason => ({
        label: reason.Name,
        value: reason.LookupId
      }))
    },
    cancelAssignment() {
      if (this.type === 'all')
        cancelDistributorVehicleAssignment(this.cancelableId, this.cancel)
            .then((resp) => {
              this.$infoModal.value = resp.data.message
              this.$emit('submit', resp.data.data)
              this.resetData()
            })
      else if (this.type === 'invoice')
        cancelDistributorVehicleInvoice(this.cancelableId, this.cancel)
            .then(resp => {
              this.$infoModal.value = resp.data.message
              this.$emit('submit', resp.data.data)
              this.resetData()
            })
      else if (this.type === 'invoice-return')
        cancelDistributorVehicleInvoiceReturn(this.cancelableId, this.cancel)
            .then(resp => {
              this.$infoModal.value = resp.data.message
              this.$emit('submit', resp.data.data)
              this.resetData()
            })
    },
    resetData() {
      this.cancel = {...cancelModel}
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="relative flex w-full">
    <input ref="input"
           :type="type"
           class="w-full border border-primary rounded-lg px-4 py-2.5 placeholder-transparent disabled:bg-gray-300"
           :class="{'border-none shadow' : shadow}"
           :placeholder="placeholder"
           :value="modelValue ?? value"
           @input="onInput"
           :max="max"
           :min="min"
           :required="required"
           :disabled="disabled"
           :autocomplete="autocomplete"
           @invalid="onInvalid"
           :step="step">
    <label class="absolute top-2.5 right-0 mr-4 text-gray-400 transition-all cursor-text"
           @click="$refs.input.focus()">{{ placeholder }}</label>
  </div>
</template>

<script>
export default {
  name: "VInput",
  props: {
    modelValue: undefined,
    modelModifiers: undefined,
    value: undefined,
    type: String,
    placeholder: String,
    required: Boolean,
    shadow: Boolean,
    max: [Number, String],
    min: [Number, String],
    disabled: Boolean,
    autocomplete: String,
    step: [Number, String]
  },
  data() {
    return {
      timeoutId: undefined
    }
  },
  methods: {
    // debounce input if .laze is set on input
    onInput(e) {
      e.target.setCustomValidity('')

      if (!this.modelModifiers?.lazy)
        this.$emit('update:modelValue', e.target.value)
      else {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.$emit('update:modelValue', e.target.value), 600)
      }
    },
    onInvalid(e) {
      let input = e.target

      if (input.validity.valueMissing)
        input.setCustomValidity('لطفا این فیلد را کامل کنید.');
      else if (input.validity.rangeOverflow)
        input.setCustomValidity(`بیشترین مقدار این فیلد ${this.max} میباشد.`);
      else if (input.validity.rangeUnderflow)
        input.setCustomValidity(`کمترین مقدار این فیلد ${this.min} میباشد.`);
      else
        input.setCustomValidity('')

      return true;
    }
  },
}
</script>

<style lang="scss" scoped>
input {
  &:focus {
    @apply outline-primary
  }

  &:focus,
  &:not(:placeholder-shown) {
    & + label {
      @apply bg-white -top-2 px-2 text-primary rounded-t-md
    }
  }
}
</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg">جزئیات اعلامیه قیمت</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3">
    <VInput placeholder="جستجو..." v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="priceList.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>
</template>

<script>
import {getPriceDetail} from "@/API/API.js";
import VInput from "@/components/General/VInput.vue";
import VCard from "@/components/General/VCard.vue";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "PriceDetail",
  components: {
    VCard,
    VInput,
    VPagination,
  },
  data() {
    return {
      priceList: {},

      filter: {
        page: 1,
        q: '',
      }
    }
  },
  created() {
    this.getPriceList()
  },
  mounted() {
    this.initTable()
  },
  methods: {
    async getPriceList() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.priceList = (await getPriceDetail(this.$route.params.id, this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.priceList.PriceList)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه پالت',
            field: 'StockId',
            vertAlign: 'middle',
          },
          {
            title: 'کالا',
            field: 'Product',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getValue().ImagePath)}" alt="" class="${cell.getValue().ImagePath ? 'w-14 h-14' : ''}">
                        <span class="whitespace-pre-wrap normal-digits ${cell.getValue().ImagePath ? 'mr-2' : ''}">${cell.getValue().Name}</span>
                      </div>`
            }
          },
          {
            title: 'قیمت',
            field: 'Price',
            vertAlign: 'middle',
            formatter: cell => this.$helper.priceFormatter(cell.getValue())
          },
          {
            title: 'درصد تخفیف',
            field: 'DiscountPercent',
            vertAlign: 'middle',
          },
        ],
      });
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getPriceList()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">مرجوعی ها</h1>
    <!-- <VButton class="!w-auto mr-2" @click="scanModal = true">اسکن مرجوعی</VButton> -->
  </div>

  <InvoiceReturnListTable
      type="distributor"
      @distributorAssignment="$emit('distributorAssignment', $event)"
      :show-filter="showFilter"
  />

  <QRScannerModal
      v-model="scanModal"
      @QR="openInvoiceReturn($event)"/>

</template>

<script>
import InvoiceReturnListTable from "@/components/InvoiceReturn/InvoiceReturnListTable";
//import VButton from "@/components/General/VButton";
import QRScannerModal from "@/components/Modal/General/QRScannerModal";

export default {
  name: "InvoiceReturnList",
  components: {QRScannerModal, InvoiceReturnListTable},
  emits: ['deliveryDatetimeId'],
  data() {
    return {
      scanModal: false,
      showFilter: true,

    }
  },
  methods: {
    openInvoiceReturn(invoiceReturnId) {
      this.$router.push({name: 'DistributorInvoiceReturnDetail', params: {id: invoiceReturnId}})
    },
  }
}
</script>

<style scoped>

</style>
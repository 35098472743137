<template>
  <ModalView title="دریافت مرجوعی">

    <form @submit.prevent="submit">

      <div class="flex gap-x-2 mt-4" v-if="type === 'distributor'">
        <VButton @click="sendDeliveryCode(true, 1)" outline>
          ارسال لینک
        </VButton>

        <VButton @click="sendDeliveryCode(true, 2)" outline>
          ارسال اطلاعات
        </VButton>
      </div>

      <VInput type="number" class="mt-4" placeholder="کد مرجوعی" v-model="deliveryCode" required/>

      <div class="flex mt-4">
        <VButton type="button" @click="$emit('close')" outline>لغو</VButton>
        <VButton class="mr-4" type="submit">تایید</VButton>
      </div>
    </form>

    <ConfirmModal
        v-model="sendDeliveryCodeModal"
        title="ارسال کد تحویل"
        text="از ارسال پیامک مطمئن هستید ؟"
        @cancel="sendDeliveryCodeModal = false"
        @confirm="sendDeliveryCode(false)"/>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import {sendInvoiceReturnDeliveryCode, submitInvoiceReturn} from "@/API/API";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";

export default {
  name: "ReceiveInvoiceReturnModal",
  props: ['invoiceReturnId', 'type'],
  emits: ['submitted', 'close'],
  components: {ConfirmModal, VInput, VButton, ModalView},
  data() {
    return {
      deliveryCode: '',
      sendDeliveryCodeModal: false,
      sendType: ''
    }
  },
  methods: {
    submit() {
      submitInvoiceReturn(this.invoiceReturnId, {deliveryCode: this.deliveryCode})
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted')
          })
    },
    sendDeliveryCode(confirm, sendType) {
      if (confirm) {
        this.sendType = sendType
        this.sendDeliveryCodeModal = true
        return
      }

      sendInvoiceReturnDeliveryCode(this.invoiceReturnId, this.sendType)
          .then(resp => {
            this.sendDeliveryCodeModal = false
            this.$infoModal.value = resp.data.message
          })
    },
  }
}
</script>

<style scoped>

</style>
<template>

  <div class="intro-y flex items-center justify-between">
    <h1 class="lg:text-lg cursor-pointer" @click="showFilter = !showFilter">دریافت از انبار</h1>
    <div class="border border-yellow-500 p-2 rounded-lg">
      وضعیت تخصیص:
      <span v-if="$loading.value">....</span>
      <span v-else>{{ state?.State?.Name ?? '-' }}</span>
    </div>
    <VButton class="!w-auto" @click="scanModal = true">اسکن دریافت</VButton>
  </div>

  <InvoiceListTable
      type="distributor-inventory"
      @distributorAssignment="distributorAssignment"
      @assignmentState="state = $event"
      :show-filter="showFilter"/>

  <QRScannerModal
      v-model="scanModal"
      @QR="confirmInvoice($event)"/>

</template>

<script>
import InvoiceListTable from "@/components/Invoice/InvoiceListTable";
import VButton from "@/components/General/VButton";
import QRScannerModal from "@/components/Modal/General/QRScannerModal";

export default {
  name: "InvoiceListInventory",
  components: {QRScannerModal, VButton, InvoiceListTable},
  emits: ['distributorAssignment'],
  data() {
    return {
      scanModal: false,
      state: {},
      showFilter: true,

    }
  },
  methods: {
    distributorAssignment(id) {
      console.log(id)
      this.$emit('distributorAssignment', id)
    },
    confirmInvoice(invoiceId) {
      this.scanModal = false
      this.$router.push({
        name: 'DistributorInvoiceDetail',
        params: {id: invoiceId},
        query: {QR: true}
      })
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <ModalView :title="`ویرایش پالت ${editStock.StockId} (${editStock.Product?.Name})`" @opened="getData">

    <form @submit.prevent="submit">
      <!-- State Filter -->
      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="stock.StateLId"
          :options="states"
          :searchable="true"
          placeholder="وضعیت"/>

      <div v-if="type === 'inventory-manager'">
        <div class="flex mt-4">
          <VInput placeholder="اولویت" v-model.number="stock.Priority" type="number"/>
          <VInput class="mr-4" placeholder="سقف سفارش" :max="100" v-model.number="stock.MaxOrder" type="number" min="0"/>
          
        </div>
        
        <VInput class="mt-4" placeholder="محل فیزیکی" v-model.number="stock.PhysicalPlace"/>
      </div>
      
      <VButton type="submit" class="mt-4" :loading="$loading.value">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import {getLookupCombos, updateStock} from "@/API/API";
import VInput from "@/components/General/VInput";
import Multiselect from "@vueform/multiselect";

const stockModel = {
  StateLId: '',
  Priority: '',
  MaxOrder: '',
  PhysicalPlace: '',
}

export default {
  name: "EditStockModal",
  emits: ['updated'],
  props: ['editStock', 'type'],
  components: {VInput, VButton, ModalView, Multiselect},
  data() {
    return {
      states: [],
      stock: {...stockModel}
    }
  },
  methods: {
    async getData() {
      this.states = (await getLookupCombos()).data.data.Lookups.StockStates.map(state => ({
        label: state.Name,
        value: state.LookupId,
      }))
    },
    submit() {
      updateStock(this.editStock.StockId, this.stock)
          .then(resp => {
            this.$emit('updated', resp.data.data)
            this.$infoModal.value = resp.data.message
          })
    }
  },
  watch: {
    editStock(editStock) {
      this.stock = this.$helper.cloneObject(editStock, Object.keys(stockModel))
      this.stock.StateLId = editStock.State.LookupId
    }
  }
}
</script>

<style scoped>

</style>
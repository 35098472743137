<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <div class="mb-4">
        <VButton @click="accept" v-if="invoiceReturn.Acceptable">قبول
        </VButton>
        <VButton outline @click="back" :loading="$loading.value" v-else-if="canEdit">
          برگشت مرجوعی به انباردارهای چیدمان
        </VButton>
      </div>

      <!-- InvoiceReturn Info-->
      <InvoiceReturnDetailCard :invoiceReturn="invoiceReturn"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Products -->
      <div class="mb-4 flex justify-between items-center">
        <h1 class="text-lg">محصولات مرجوعی</h1>
      </div>

      <InvoiceReturnProduct
          v-for="item in invoiceReturn.Items"
          :key="item.InvoiceReturnDetailId"
          type="inventory-carrier"
          :invoice-return-detail="item"
          :can-edit="canEdit"
          @edited="edited"
      @scanned="scanned"/>

      <!-- Button -->
      <VButton class="mt-4 xl:w-1/4 lg:mr-auto" @click="submit(true)" v-if="canEdit">تکمیل</VButton>
    </div>

  </div>

  <ConfirmModal
      v-model="confirmSubmitModal"
      title="ثبت چیدمان"
      text="از ثبت چیدمان مطمئن هستید ؟"
      @cancel="confirmSubmitModal = false"
      @confirm="submit(false)"/>

</template>

<script>
import {acceptInvoiceReturn, backInvoiceReturn, getInvoiceReturn, submitInvoiceReturn} from "@/API/API";
import VButton from "@/components/General/VButton";
import {mapState} from "vuex";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import InvoiceReturnDetailCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnDetailCard";
import InvoiceReturnProduct from "@/components/InvoiceReturn/InvoiceReturnProduct";

export default {
  name: "InvoiceReturnDetail",
  components: {
    InvoiceReturnProduct,
    InvoiceReturnDetailCard,
    ConfirmModal,
    VButton,
  },
  data() {
    return {
      invoiceReturn: {},

      confirmSubmitModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoiceReturn.StaffId === this.user.UserId
    },
    canSubmit() {
      return this.invoiceReturn.Items.reduce((errorCount, item) => {
        if (item.InventoryHealthyAmount > 0 && !item.HealthyPlacedDate)
          return ++errorCount
        if (item.InventoryDefectAmount > 0 && !item.DefectPlacedDate)
          return ++errorCount
        return errorCount
      }, 0) === 0
    }
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoiceReturn(this.invoiceReturn.InvoiceReturnId, 'inventory-carrier')
          .then(resp => Object.assign(this.invoiceReturn, resp.data.data))
    },
    back() {
      backInvoiceReturn(this.invoiceReturn.InvoiceReturnId, 'inventory-carrier')
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    submit(confirm) {
      if (!this.canSubmit) {
        this.$errorModal.value = 'کالای ثبت نشده وجود دارد.'
        return
      }

      if (confirm) {
        this.confirmSubmitModal = true
        return
      }

      submitInvoiceReturn(this.invoiceReturn.InvoiceReturnId)
          .then(async resp => {
            this.confirmSubmitModal = false
            this.$router.replace({name: 'InventoryCarrierInvoiceReturnList'})
            this.$infoModal.value = resp.data.message
          })
    },
    edited(invoiceReturnDetail) {
      let item = this.invoiceReturn.Items.find(item => item.InvoiceReturnDetailId === invoiceReturnDetail.InvoiceReturnDetailId)
      item.DefectPlacedDate = invoiceReturnDetail.DefectPlacedDate
    },
    scanned(invoiceReturnDetail) {
      let item = this.invoiceReturn.Items.find(item => item.InvoiceReturnDetailId === invoiceReturnDetail.InvoiceReturnDetailId)
      item.HealthyPlacedDate = true
    }
  }
}
</script>

<style scoped>

</style>
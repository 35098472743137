<template>
  <ModalView
      class="text-gray-500 !z-50">

    <p class="mt-4 text-sm" v-html="$infoModal.value"/>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";

export default {
  name: "InfoModal",
  props: {
    title: {
      default: 'خطا'
    },
    show: [Object, Boolean]
  },
  components: {ModalView},
}
</script>

<style scoped>

</style>
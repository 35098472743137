<template>
  <div>
    <label class="block text-primary mb-1" v-if="label">{{ label }}</label>
    <div class="relative flex bg-white">
      <div class="absolute h-8 bg-primary p-2 cursor-pointer rounded-r-md" @click="minus">
        <DynamicIcon class="w-4 text-white" icon="MinusIcon"/>
      </div>
      <input type="number" class="w-full h-8 text-center border border-primary rounded-md focus:outline-primary"
             v-model="number" :max="max" :min="min" @change="updated" :required="required">
      <div class="absolute h-8 left-0 bg-primary p-2 cursor-pointer rounded-l-md" @click="plus">
        <DynamicIcon class="w-4 text-white" icon="PlusIcon"/>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicIcon from "@/components/DynamicIcon";

export default {
  name: "NumberPicker",
  props: {
    label: String,
    modelValue: undefined,
    max: [String, Number],
    min: [String, Number],
    required: Boolean
  },
  components: {DynamicIcon},
  data() {
    return {
      number: this.modelValue || this.min || 0
    }
  },
  created() {
    this.updated()
  },
  methods: {
    plus() {
      if (this.max && this.number < this.max)
        this.number++
      else if (this.max == null)
        this.number++
      this.updated()
    },
    minus() {
      if (this.min && this.number > this.min)
        this.number--
      else if (!this.min && this.number > 0)
        this.number--
      this.updated()
    },
    updated() {
      this.$emit('update:modelValue', this.number)
    }
  }
}
</script>

<style scoped>

</style>
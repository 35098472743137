<template>
  <ModalView title="تایید سفارش" @opened="modalOpened">

    <form @submit.prevent="submit">

      <div class="mt-4 grid grid-cols-2 gap-4" v-if="type === 'inventory-packer'">
        <div v-for="basket in baskets"
             :key="basket.LookupId">
          <NumberPicker :label="basket.Name" :min="0" v-model="basket.Count"/>
        </div>
      </div>

      <div v-else-if="this.type === 'supporter'">
        <!--        <div class="flex justify-between items-center mb-2">-->
        <!--          <h1>زمان تحویل</h1>-->
        <!--          <div class="flex gap-x-4">-->
        <!--            <button-->
        <!--                class="px-2 py-1 border rounded"-->
        <!--                :class="selectedDeliveryDayIndex === index ? 'border-purple-700 text-purple-700' : ''"-->
        <!--                @click="selectedDeliveryDayIndex = index; deliveryDateTimeId = ''"-->
        <!--                v-for="(deliveryTime, index) in cartConfirmInfo?.DeliveryDateTimes"-->
        <!--                :key="deliveryTime">-->
        <!--              {{ deliveryTime.Label }}-->
        <!--            </button>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <Multiselect-->
        <!--            dir="rtl"-->
        <!--            class="w-full rounded bg-gray-100 mt-2"-->
        <!--            v-model="deliveryDateTimeId"-->
        <!--            :options="deliveryTimes"-->
        <!--            placeholder="زمان تحویل"-->
        <!--            :disabled="selectedDeliveryDayIndex === ''">-->
        <!--        </Multiselect>-->

        <h1 class="mt-4">زمان تحویل</h1>
        <Multiselect
            dir="rtl"
            class="w-full rounded bg-gray-100 mt-2"
            v-model="deliveryDateTimeId"
            :options="deliveryTimes"
            :searchable="true"
            placeholder="زمان تحویل">
        </Multiselect>

        <VSelect class="mt-4" v-model.number="roleId" required>
          <option value="" disabled selected>ارجاع به</option>
          <option
              v-for="role in roles"
              :key="role.RoleId"
              :value="role.RoleId">
            {{ role.Name }}
          </option>
        </VSelect>
      </div>

      <VButton class="mt-4" type="submit" :loading="$loading.value">ارجاع</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VSelect from "@/components/General/VSelect";
import VButton from "@/components/General/VButton";
import {changeInvoice, getLookupCombos, proceedInvoice} from "@/API/API";
import NumberPicker from "@/components/General/NumberPicker";
import Multiselect from "@vueform/multiselect";

export default {
  name: "ConfirmInvoiceModal",
  props: ['invoice', 'type'],
  emits: ['submitted'],
  components: {NumberPicker, VButton, VSelect, ModalView, Multiselect},
  data() {
    return {
      roleId: '',
      deliveryDateTimeId: '',
      selectedDistributor: '',
      selectedBaskets: [],

      distributorVehicles: [],
      deliveryTimes: [],
      baskets: [],
      roles: [],

      assignmentDone: '',
      selectedDeliveryDayIndex: '',

    }
  },
  computed: {
    basketCount() {
      return this.baskets.reduce((count, basket) => count += basket.Count, 0)
    }
  },
  methods: {
    async modalOpened() {
      if (this.type === 'inventory-packer')
        this.baskets = (await getLookupCombos(103050102)).data.data.Lookups.BasketTypes
      else if (this.type === 'supporter')
        this.getDeliveryTimes()
    },
    async getDeliveryTimes() {
      this.deliveryTimes = []
      if (this.type === 'supporter') {
        let data = (await getLookupCombos(103080102)).data.data
        this.roles = data.Roles
        this.deliveryTimes = data.DeliveryDateTimes?.map(item => ({
          label: item.Description,
          value: item.DeliveryDateTimeId,
          disabled: !item.CanChoose
        }))
      }
    },
    submit() {
      if (this.type === 'supporter') {
        let invoice = {
          roleId: this.roleId,
          deliveryDateTimeId: this.deliveryDateTimeId,
        }
        changeInvoice(this.invoice.InvoiceId, invoice)
            .then(resp => {
              this.$emit('submitted')
              this.$infoModal.value = resp.data.message
            })
      } else if (this.type === 'inventory-packer') {
        if (this.basketCount === 0) {
          this.$errorModal.value = 'لطفا تعداد سبد را انتخاب کنید'
          return
        }
        let selectedBaskets = this.baskets.filter(basket => basket.Count > 0)

        let baskets = this.$helper
            .filterObjectArray(selectedBaskets, ['LookupId', 'Count'])
            .map(basket => ({
              Count: basket.Count,
              BasketTypeLId: basket.LookupId
            }))

        proceedInvoice(this.invoice.InvoiceId, {baskets})
            .then(resp => {
              this.$emit('submitted')
              this.$infoModal.value = resp.data.message
            })
      }
    }
  },
}
</script>

<style scoped>

</style>
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCdjTIdkcDz-H1ACPMm6byOKi7i6UWB_X0",
    authDomain: "joonico-8207f.firebaseapp.com",
    projectId: "joonico-8207f",
    storageBucket: "joonico-8207f.appspot.com",
    messagingSenderId: "124914427560",
    appId: "1:124914427560:web:e0b3c4910af3a8f971449d",
    measurementId: "G-E19FZERCBK"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

export default firebaseApp
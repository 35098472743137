<template>
  <ModalView title="تحویل سفارش">

    <form ref="form" @submit.prevent="deliver">
      <VInput class="mt-4" type="number" placeholder="کد تحویل" v-model="deliveryCode" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {deliverInvoice} from "@/API/API";

export default {
  name: "InvoiceDeliverModal",
  props: ['invoice'],
  emits: ['delivered'],
  components: {VButton, VInput, ModalView},
  data() {
    return {
      deliveryCode: '',
    }
  },
  methods: {
    deliver() {
      deliverInvoice(this.invoice.InvoiceId, this.deliveryCode)
          .then(resp => {
            this.$emit('delivered')
            this.$infoModal.value = resp.data.message
            this.$router.replace({name: 'DistributorInvoiceListDelivery'})
          })
    },
  },
}
</script>

<style scoped>

</style>
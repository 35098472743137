<template>
  <ModalView title="افزودن موزعین" @opened="getDistributors()">

    <Multiselect
        dir="rtl"
        class="mt-4"
        v-model="distributor.id"
        :options="distributors"
        :searchable="true"
        placeholder="انتخاب موزع"/>

    <div class="flex items-center justify-between mt-1">
      <label>تاریخ فعالیت (در صورتیکه موزع مهمان است انتخاب شود)</label>

      <!-- <VSwitch v-model="distributor.isGuest" :value="0"/> -->

      <DatePicker
                  v-model="distributor.EffectDate"
                  format="YYYY-MM-DD"
                  display-format="jYYYY-jMM-jDD"
                  append-to="body"
                  auto-submit
                  clearable
                  :min="new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)"
                  placeholder="تاریخ فعالیت"
              />
    </div>

    <VButton class="mt-4" @click="submit">ثبت</VButton>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {addDistributorToShift, getDistributorsForShift} from "@/API/API";
import Multiselect from "@vueform/multiselect";
import VButton from "@/components/General/VButton";
//import VSwitch from "@/components/General/VSwitch.vue";
import DatePicker from "vue3-persian-datetime-picker";

export default {
  name: "AddDistributorToShiftModal",
  components: {VButton, ModalView, Multiselect,DatePicker},
  props: ['shiftId'],
  emits: ['submitted'],
  data() {
    return {
      distributors: [],
      distributor: {
        id: '',
        EffectDate: ''
      },
    }
  },
  methods: {
    async getDistributors() {
      this.distributors = (await getDistributorsForShift(this.$route.params.id)).data.data.map(distributor => ({
        label: distributor.Name,
        value: distributor.DistributorId
      }))
    },
    submit() {
      addDistributorToShift(this.$route.params.id, this.distributor)
          .then(resp => {
            this.$infoModal.value = resp.data.message;
            this.$emit('submitted', resp.data.data)
            this.getDistributors()
          })
    }
  },
}
</script>

<style scoped>

</style>
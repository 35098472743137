<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست سفارشات</h1>

    <VButton class="!w-auto intro-y order-1 lg:order-2" :disabled="selectedInvoices.length === 0"
             @click="suspendModal = true">
      لغو یا تعلیق سفارشات
    </VButton>
  </div>

  <InvoiceListTable
      type="inventory-manager"
      :show-filter="showFilter"
      @selectedInvoices="this.selectedInvoices = $event"
      :key="refreshKey"/>

  <SuspendInvoiceModal
      v-model="suspendModal"
      :invoice-ids="this.selectedInvoices"
      @submit="suspendModal = false; refreshKey++"/>
</template>

<script>

import InvoiceListTable from "@/components/Invoice/InvoiceListTable";
import VButton from "@/components/General/VButton.vue";
import SuspendInvoiceModal from "@/components/Modal/InventoryManager/SuspendInvoiceModal.vue";

export default {
  name: "InvoiceList",
  components: {SuspendInvoiceModal, VButton, InvoiceListTable},
  data() {
    return {
      selectedInvoices: [],
      suspendModal: false,
      refreshKey: 1,
      showFilter: true,
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
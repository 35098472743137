<template>
  <ModalView title="لیست کالا ها" @opened="modalOpened" @closed="modalClosed">

    <div class="max-h-96 overflow-y-scroll" v-if="items?.length > 0">
      <InvoiceProductCard
          v-for="item in items"
          :key="item.InvoiceDetailId"
          :invoiceDetail="item"
          :canEdit="canEdit"
          :can-return="true"
          @returned="returned"
          @resetReturn="resetReturn"
          type="distributor"/>

      <VButton class="mt-4" @click="submit" v-if="hasReturnedStocks">ثبت</VButton>
    </div>

    <LoadingSpinner class="mt-4 w-10 mx-auto" v-else-if="$loading.value"/>

    <h5 class="mt-4 text-gray-600" v-else>کالایی جهت مرجوعی وجود ندارد</h5>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {getInvoiceReturnReturnableProducts} from "@/API/API";
import InvoiceProductCard from "@/components/Invoice/InvoiceProductCard";
import VButton from "@/components/General/VButton";
import {mapState} from "vuex";
import LoadingSpinner from "@/components/General/LoadingSpinner";

export default {
  name: "InvoiceProductsModal",
  emits: ['addItems'],
  props: ['invoiceReturn'],
  components: {LoadingSpinner, VButton, InvoiceProductCard, ModalView},
  data() {
    return {
      invoice: {},
      items: [],

      invoiceReturnItems: []
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoice.StaffId === this.user.UserId
    },
    hasReturnedStocks() {
      return this.items?.some(item => item.StockDetails?.some(stockDetail => stockDetail.SentBack))
    }
  },
  methods: {
    async getProducts() {
      this.items = (await getInvoiceReturnReturnableProducts(this.invoiceReturn.InvoiceReturnId)).data.data
    },
    submit() {
      this.$emit('addItems', this.invoiceReturnItems)
    },
    returned(stockDetailReturn) {
      this.items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailReturn.StockDetailId) {
          stockDetail.SentBack = stockDetailReturn

          stockDetailReturn.StockDetail = {
            StockDetailId: stockDetail.StockDetailId,
            Amount: stockDetail.Amount,
            Product: {
              Name: item.Product.Name,
              ImageUrl: item.Product.Image
            }
          }
        }
      }))

      this.invoiceReturnItems.push(stockDetailReturn)
    },
    resetReturn(stockDetailReturn) {
      this.items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          if (stockDetail.StockDetailId === stockDetailReturn.StockDetailId)
            delete stockDetail.SentBack
        })
      })
    },
    modalOpened() {
      this.getProducts()
    },
    modalClosed() {
      this.invoice = {}
    }
  }
}
</script>

<style scoped>

</style>
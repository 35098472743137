<template>
  <ModalView title="جستجو محصولات">

    <VInput class="mt-4" placeholder="نام محصول" v-model.lazy="q"/>

    <!-- Result -->
    <div class="mt-4 grid grid-cols-3 gap-4 overflow-y-scroll max-h-[50vh]">
      <VCard class="relative overflow-hidden border"
             v-for="product in products"
             :key="product.ProductId">

        <img class="w-20 mx-auto" :src="$helper.imageURL(product.Image)" alt="">
        <span class="text-xs">{{ product.Name }}</span>

        <div class="flex justify-between text-xs mt-1">
          <span>قیمت:</span>
          {{ $helper.priceFormatter(product.JCoPrice, false) }}
        </div>

        <div class="mt-2 rounded-md py-1 bg-red-500 text-white text-xs w-full text-center"
             v-if="product.JCoPrice === 0">
          ناموجود
        </div>

        <VButton class="mt-2 text-xs !py-1" :loading="$loading.value" outline v-else
                 @click="addProduct(product.ProductId)">افزودن
        </VButton>
      </VCard>
    </div>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import {addProductToCampaign, addProductToInvoice, searchProducts} from "@/API/API";
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";

export default {
  name: "SearchProductModal",
  props: ['invoice', 'campaign', 'type'],
  emits: ['added'],
  components: {VButton, VCard, VInput, ModalView},
  data() {
    return {
      q: '',
      products: []
    }
  },
  methods: {
    async search() {
      if (this.q.length > 1) {
        this.products = (await searchProducts(this.q, this.invoice?.InventoryId ?? this.campaign?.InventoryId)).data.Products
      }
    },
    addProduct(productId) {
      if (this.type === 'supporter')
        addProductToInvoice(this.invoice.InvoiceId, productId)
            .then(resp => {
              this.$emit('added', resp.data.data)
              this.$infoModal.value = resp.data.message
            })
      else if (this.type === 'inventory-manager')
        addProductToCampaign(this.campaign.CampaignId, productId)
            .then(resp => {
              this.$emit('added')
              this.$infoModal.value = resp.data.message
            })
    }
  },
  watch: {
    q() {
      this.search()
    }
  }
}
</script>

<style scoped>

</style>
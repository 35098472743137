<template>
  <div class="intro-y flex items-center justify-between">
    <div>
      <h1 class="text-lg">{{ shift.Name }}</h1>

      <div class="flex gap-x-2 mt-2">
      <span v-for="timeSlice in shift.TimeSlices"
            :key="timeSlice.TimeSliceId"
            class="border border-slate-300 rounded px-2">
        {{ timeSlice.Description }}
      </span>
      </div>
    </div>

    <VButton class="w-auto" @click="addModal = true">افزودن موزع</VButton>
  </div>

  <VCard class="bg-transparent mt-4 text-slate-600" no-padding>

    <div ref="table"></div>

  </VCard>

  <AddDistributorToShiftModal
      v-model="addModal"
      :shift-id="shift.ShiftId"
      @submitted="distributorAdded"/>

  <!-- Confirm Remove Distributor -->
  <ConfirmModal
      v-model="removeDistributorModal"
      title="حذف موزع"
      text="آیا از حذف موزع از گروه مطمئن هستید ؟"
      @confirm="removeDistributor(false)"
      @cancel="removeDistributorModal = false"/>
</template>

<script>
import {getShift, removeDistributorFromShift, toggleDistributorInShift} from "@/API/API";
import VButton from "@/components/General/VButton";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VCard from "@/components/General/VCard";
import AddDistributorToShiftModal from "@/components/Modal/DistributorManager/AddDistributorToShiftModal.vue";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";

export default {
  name: "ShiftDetail",
  components: {ConfirmModal, AddDistributorToShiftModal, VCard, VButton},
  data() {
    return {
      shift: {},
      addModal: false,
      removeDistributorModal: false,
    }
  },
  created() {
    this.getShift()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // toggle Buttons
      let toggleButton = document.querySelectorAll("div[data-btn-type='toggle']")
      toggleButton.forEach(element => element.onclick = () => this.toggleDistributorActive(element.dataset.id, element.dataset.active !== "true"))
      // toggle Buttons
      let deleteButton = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButton.forEach(element => element.onclick = () => this.removeDistributor(true, element.dataset.id)
      )
    });
  },
  methods: {
    async getShift() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.shift = (await getShift(this.$route.params.id)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    toggleDistributorActive(distributorId, isActive) {
      toggleDistributorInShift(this.$route.params.id, distributorId, isActive)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.getShift()
          })
    },
    removeDistributor(confirm, distributorId) {
      if (confirm) {
        this.removeDistributorModal = distributorId
        return
      }
      removeDistributorFromShift(this.$route.params.id, this.removeDistributorModal)
          .then(resp => {
            this.removeDistributorModal = false
            this.$infoModal.value = resp.data.message
            let index = this.shift.Distributors.findIndex(distributor => distributor.UserId == this.removeDistributorModal)
            this.shift.Distributors.splice(index, 1)
            this.refreshTable()
            this.removeDistributorModal = false
          })
    },
    refreshTable() {
      this.tabulator.setData(this.shift.Distributors)
      window.scrollTo(0, 0)
    },
    distributorAdded(distributor) {
      this.shift.Distributors.push(distributor)
      this.addModal = false;
      this.refreshTable()
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'ردیف',
            field: 'Index',
            formatter: cell => cell.getRow().getPosition()
          },
          {
            title: 'نام',
            field: 'Name',
          },
          {
            title: 'وضعیت',
            field: 'IsActive',
            formatter: cell => {
              let state = cell.getValue();
              return `<span class="text-xs text-white rounded px-2 py-0.5 ${state ? 'bg-green-500' : 'bg-red-500'}">
                         ${state ? 'فعال' : 'غیرفعال'}
                      </span>`
            }
          },
          {
            title: 'تاریخ فعالیت',
            field: 'EffectDate'
          },
          // {
          //   title: 'مهمان',
          //   field: 'IsGuest',
          //   formatter: cell => cell.getValue() ? 'بلی' : 'خیر'
          // },
          {
            title: 'عملیات',
            field: 'UserId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                         <div class="flex items-center text-amber-500 mr-4 cursor-pointer" data-btn-type="toggle" data-active="${cell.getData().IsActive}" data-id="${cell.getValue()}">
                             <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                             </svg>
                             <span class="mr-1 text-xs">فعال/غیرفعال</span>
                        </div>
                         <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  }
}
</script>

<style scoped>

</style>
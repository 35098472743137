<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">
      {{ $route.name === 'SupporterInvoiceListAll' ? 'همه سفارشات' : 'سفارشات در انتظار' }}
    </h1>
  </div>

  <!--  we use key to reCreate component on route change -->
  <InvoiceListTable :key="$route.name"
                    :type="$route.name === 'SupporterInvoiceListAll' ? 'supporter-all' : 'supporter-pending'"
  :show-filter="showFilter"/>

</template>

<script>
import InvoiceListTable from "@/components/Invoice/InvoiceListTable";

export default {
  name: "InvoiceList",
  components: {InvoiceListTable},
  data() {
    return {
      showFilter: true,

    }
  },
}
</script>

<style scoped>

</style>
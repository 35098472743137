<template>
  <KeyValueCard title="اطلاعات مشتری">
    <KeyValueCardItem title="نام و نام خانوادگی" :value="invoice.User?.Name" icon="UserIcon"/>
    <KeyValueCardItem title="شماره موبایل" :value="invoice.User?.Mobile" icon="DeviceMobileIcon" last/>
  </KeyValueCard>
</template>

<script>
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
export default {
  name: "InvoiceCustomerCard",
  props: ['invoice'],
  components: {KeyValueCardItem, KeyValueCard}
}
</script>

<style scoped>

</style>
<template>

  <div class="intro-y flex items-center justify-between">
    <h1 class="lg:text-lg cursor-pointer" @click="showFilter = !showFilter">تحویل به مشتری</h1>

    <div class="border border-yellow-500 p-2 rounded-lg">
      وضعیت تخصیص:
      <span v-if="$loading.value">....</span>
      <span v-else>{{ state?.State?.Name ?? '-' }}</span>
    </div>

    <div></div>
  </div>

  <InvoiceListTable
      type="distributor-delivery"
      @assignmentState="state = $event"
      @distributorAssignment="distributorAssignment"
      :show-filter="showFilter"
  />

</template>

<script>
import InvoiceListTable from "@/components/Invoice/InvoiceListTable";

export default {
  name: "InvoiceListDelivery",
  components: {InvoiceListTable},
  emits: ['distributorAssignment'],
  data() {
    return {
      showFilter: true,
      state: {}
    }
  },
  methods: {
    distributorAssignment(id) {
      this.$emit('distributorAssignment', id)
    },
  },
}
</script>

<style scoped>

</style>
<template>

  <StockListTable
  type="QC-manager"/>

</template>

<script>
import "@/assets/css/pagination.css";
import StockListTable from "@/components/StockListTable";

export default {
  name: "StockList",
  components: {StockListTable},
}
</script>

<style scoped>

</style>
<template>
  <div class="flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست سفارشات</h1>

    <VButton class="mr-auto lg:!w-auto" @click="scanModal = true">اسکن</VButton>
  </div>

  <InvoiceListTable
      type="inventory-controller"
      :show-filter="showFilter"/>

  <QRScannerModal
      v-model="scanModal"
      @QR="acceptInvoice"/>

</template>

<script>

import VButton from "@/components/General/VButton";
import QRScannerModal from "@/components/Modal/General/QRScannerModal";
import InvoiceListTable from "@/components/Invoice/InvoiceListTable";
import {acceptInvoice} from "@/API/API";

export default {
  name: "InvoiceList",
  components: {InvoiceListTable, QRScannerModal, VButton},
  data() {
    return {
      scanModal: false,
      showFilter: true,

    }
  },
  methods: {
    acceptInvoice(invoiceId) {
      this.scanModal = false
      acceptInvoice(invoiceId, 'inventory-controller')
          .then(() => this.$router.push({
            name: 'InventoryControllerInvoiceDetail',
            params: {id: invoiceId}
          }))
    }
  }
}
</script>

<style scoped>

</style>
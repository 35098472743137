<template>
  <div class="flex justify-between py-4" :class="{'border-b' : !last}">
    <div class="flex items-center">
      <DynamicIcon :icon="icon" class="w-5"/>
      <span class="mr-2">{{ title }}</span>
    </div>
    <span class="text-gray-600 font-bold text-left" :class="valueClass">{{ value }}</span>
  </div>
</template>

<script>
import DynamicIcon from "@/components/DynamicIcon";
export default {
  name: "KeyValueCardItem",
  components: {DynamicIcon},
  props: {
    title: String,
    value: [String, Number],
    icon: String,
    last: Boolean,
    valueClass: String,
  }
}
</script>

<style scoped>

</style>
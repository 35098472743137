<template>
  <VCard class="mt-4" noPadding>

    <div class="flex justify-between items-center m-4">
      <h1 class="text-lg">هماهنگی ها</h1>
      <VButton class="!w-auto" @click="coordinationModal = true" v-if="canEdit">ثبت هماهنگی</VButton>
    </div>

    <div ref="table"></div>

    <CoordinationModal
        v-if="canEdit"
        v-model="coordinationModal"
        :type="type"
        :id="id"
        @submitted="coordinationSubmitted"/>

  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";
import CoordinationModal from "@/components/Modal/Operator/CoordinationModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "CoordinationsTable",
  props: ['id', 'coordinations', 'canEdit', 'type'],
  emits: ['submitted'],
  components: {CoordinationModal, VButton, VCard},
  data() {
    return {
      coordinationModal: false,
    }
  },
  mounted() {
    this.initTable()
  },
  methods: {
    coordinationSubmitted(coordination) {
      this.coordinationModal = false
      this.$emit('submitted', coordination)
    },
    refreshTable() {
      this.tabulator.setData(this.coordinations)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable:false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {title: 'نوع', field: 'Reason.Name'},
          {title: 'شرح', field: 'Description'},
          {title: 'تاریخ', field: 'CreateDate'}
        ],
      });
    }
  },
  watch: {
    coordinations: {
      deep: true,
      handler(coordinations) {
        if (coordinations) this.refreshTable()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <button type="button"
          class="inline-flex border-2 border-transparent rounded-full bg-gray-200 h-[32px] w-[74px]"
          @click="toggle">
    <span class="shadow-lg h-[28px] w-[28px] inline-block rounded-full shadow-lg transition duration-300"
          :class="active ? 'translate-x-0 bg-primary' : '-translate-x-10 bg-white'"></span>
  </button>
</template>

<script>
export default {
  name: "VSwitch",
  props: {
    value: {
      type: [Number, Boolean],
      default: () => 1
    }
  },
  data() {
    return {
      active: this.value
    }
  },
  methods: {
    toggle() {
      this.active =  this.active === 1 ? 0 : 1
      this.$emit('update:modelValue', this.active)
    }
  },
  watch: {
    value() {
      this.active = this.value
    }
  }
}
</script>

<style scoped>

</style>
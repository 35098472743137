<template>
  <ModalView :title="editMode ? 'ویرایش شیفت' : 'ایجاد شیفت'" @opened="getTimeSlices">
    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <VInput class="mt-4" placeholder="نام شیفت" v-model="shift.Name" required/>

      <Multiselect
          dir="rtl"
          class="mt-4"
          v-model="shift.TimeSlices"
          :options="timeSlices"
          mode="tags"
          :searchable="true"
          placeholder="انتخاب بازه های زمانی"
          required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {mapGetters} from "vuex";
import {createShift, getLookupCombos, updateShift} from "@/API/API";
import Multiselect from "@vueform/multiselect";

const shiftModel = {
  ShiftId: '',
  Name: '',
  TimeSlices: [],
}

export default {
  name: "CreateShiftModal",
  components: {VButton, VInput, ModalView, Multiselect},
  emits: ['created', 'updated'],
  props: ['editShift'],
  data() {
    return {
      editMode: false,
      shift: {...shiftModel},
      timeSlices: [],
    }
  },
  computed: {
    ...mapGetters(['selectedInventory']),
  },
  methods: {
    async getTimeSlices() {
      this.timeSlices = (await getLookupCombos(103040300)).data.data.TimeSlices.map(timeSLice => ({
        label: timeSLice.StartTime + ' تا ' + timeSLice.EndTime,
        value: timeSLice.TimeSliceId,
        disabled: !!timeSLice.ShiftId
      }))
    },
    create() {
      this.shift.InventoryId = this.selectedInventory
      createShift(this.shift)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('created', resp.data.data)
            this.getTimeSlices()
          })
    },
    update() {
      updateShift(this.editShift.ShiftId, this.shift)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('updated', resp.data.data)
            this.getTimeSlices()
          })
    }
  },
  watch: {
    editShift(editShift) {
      if (editShift.ShiftId) {
        this.editMode = true;
        this.shift = this.$helper.cloneObject(this.editShift, Object.keys(shiftModel));
        this.shift.TimeSlices = this.editShift.TimeSlices.map(timeSlice => timeSlice.TimeSliceId);
      } else {
        this.editMode = false
        this.shift = {...shiftModel}
      }
    }
  }
}
</script>

<style scoped>

</style>
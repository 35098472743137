<template>

  <TopBar/>

  <MobileSideMenu/>

  <div class="relative wrapper mt-16 md:mt-0 print:invisible">
    <div class="wrapper-box flex bg-primary rounded-3xl translate-y-[0px] h-[91vh] pb-6 md:pb-0">

      <SideMenu/>

      <div id="main" class="rounded-3xl bg-slate-100 min-w-0 p-3 flex-1 overflow-y-auto no-scrollbar">

        <!--        <BreadCrumb />-->

        <router-view/>

        <div class="h-20"></div>
      </div>

    </div>
  </div>
</template>

<script>
import TopBar from "@/components/Layout/TopBar";
import SideMenu from "@/components/Layout/SideMenu";
import MobileSideMenu from "@/components/Layout/MobileSideMenu";

export default {
  name: "DashboardPage",
  components: {MobileSideMenu, SideMenu, TopBar},
}
</script>

<style lang="scss">
.wrapper .wrapper-box:before {
  content: "";
  @apply absolute top-0 right-0 left-0 bottom-0 rounded-3xl -z-10 bg-black/20
}
</style>
<template>
  <div class="login fixed overflow-hidden inset-0 xl:bg-white ltr">
    <div class="container sm:px-10 mx-auto">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- Left Panel -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img alt="" class="w-6" src="@/assets/img/logo.svg">
            <span class="text-white text-lg ml-3">جونیکو</span>
          </a>
          <div class="my-auto">
            <img alt="" class="-intro-x w-1/2"
                 src="@/assets/img/login-cover.svg">
            <div class="-intro-x text-white font-medium text-4xl mt-10">
              ورود به پنل کارمندان
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70">
              مدیریت سایت جونیکو
            </div>
          </div>
        </div>

        <!-- Right Panel -->
        <div class="h-screen xl:h-auto flex py-5 px-4 -mr-8 overflow-y-scroll xl:overflow-hidden">

          <div class="m-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md
                     xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:3/4">

            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-right">ورود</h2>
            <VAlert v-if="message" type="error" style="direction: rtl">{{ message }}</VAlert>
            <form @submit.prevent="login" v-if="!showInventory">
              <div class="intro-x mt-8">
                <VInput type="tel" class="" placeholder="موبایل" v-model="mobile"
                        @keypress="isNumber" required id="input-mobile"/>

                <VInput type="password" class=" mt-4" placeholder="رمزعبور" v-model="password" required/>
              </div>
              <div class="intro-x mt-5 xl:mt-8">
                <VButton class="py-3 px-4 w-full xl:w-32">ورود</VButton>
              </div>
            </form>

            <div v-else>
              <form @submit.prevent="redirect">
                <VSelect class="mt-2 border-white shadow bg-transparent text-right"
                         @change="selectedInventory = parseInt($event.target.value)"
                         required>
                  <option disabled selected value="">انتخاب انبار</option>
                  <option
                      class="text-black"
                      v-for="inventory in loginResp.user.Inventories"
                      :key="inventory.InventoryId"
                      :value="inventory.InventoryId">
                    {{ inventory.Name }}
                  </option>
                </VSelect>

                <div class="intro-x flex gap-x-4 mt-5 xl:mt-8">
                  <VButton class="py-3 px-4 w-full xl:w-32" outline @click.prevent="showLoginForm">انصراف</VButton>
                  <VButton class="py-3 px-4 w-full xl:w-32" type="submit">ورود</VButton>
                </div>
              </form>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import VAlert from "@/components/General/VAlert";
import VSelect from "@/components/General/VSelect.vue";
import {mapGetters} from "vuex";

export default {
  name: "LoginPage",
  components: {VSelect, VAlert, VInput, VButton},
  data() {
    return {
      mobile: '',
      password: '',
      message: '',
      loginResp: {},
      showInventory: false,
      selectedInventory: ''
    }
  },
  computed: {
    ...mapGetters(['userInventories', 'isLoggedIn']),
  },
  mounted() {
    document.querySelector('#input-mobile input').focus()
  },
  methods: {
    validateMobile() {
      this.message = ''
      if (this.mobile.length < 11) {
        this.message = 'شماره موبایل باید ۱۱ رقم باشد.'
        this.messageType = 'error'
        return false
      }

      if (!this.mobile.startsWith('09')) {
        this.message = 'شماره موباید با ۰۹ باید شروع شود.'
        this.messageType = 'error'
        return false
      }

      return true
    },
    login() {
      if (!this.validateMobile())
        return

      this.message = ''

      let user = {
        'mobile': this.mobile,
        'password': this.password
      }
      this.$store.dispatch('login', user)
          .then(async resp => {
            this.loginResp = resp
            if (resp.user.Inventories.length > 1)
              this.showInventory = true
            else {
              this.selectedInventory = resp.user.Inventories[0].InventoryId
              this.redirect()
            }
          })
          .catch(err => {
            this.message = err.data.message
            this.password = ''
          })
    },
    isNumber(event) {
      if (event.key === 'Enter')
        return

      if (isNaN(parseInt(event.key)) || event.target.value.length === 11)
        event.preventDefault()
    },
    async redirect() {
      await this.$store.dispatch('setSelectedInventory', this.selectedInventory)
      this.$store.dispatch('setToken', this.loginResp)
      this.$router.push('/')
    },
    showLoginForm() {
      this.showInventory = false
      this.mobile = ''
      this.password = ''
    }
  }
}
</script>

<style scoped>
.login:after {
  content: "";
  margin-top: -20%;
  margin-bottom: -12%;
  margin-left: -13%;
  width: 57%;
  border-radius: 100%;
  @apply absolute bg-blue-900 inset-0 hidden xl:block
}

.login:before {
  content: "";
  margin-top: -28%;
  margin-bottom: -15%;
  margin-left: -13%;
  width: 57%;
  border-radius: 100%;
  @apply absolute bg-blue-900/20 inset-0 hidden xl:block
}

ul li {
  @apply cursor-pointer hover:bg-gray-100 rounded p-1
}
</style>
<template>
  <nav class="hidden md:w-[105px] md:block lg:w-[250px] px-4 py-6 text-center overflow-y-auto overflow-x-hidden no-scrollbar">
    <SideMenuItems/>
  </nav>
</template>

<script>
import SideMenuItems from "@/components/Layout/SideMenuItems";

export default {
  name: "SideMenu",
  components: {SideMenuItems},
}
</script>

<style scoped>
</style>
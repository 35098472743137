export default {
    install(app) {
        app.config.globalProperties.$helper = this
    },
    priceFormatter(price, toman = true) {
        try {
            price = parseInt(price)
            let formattedPrice =
                price
                    .toFixed(0)
                    .replace(new RegExp('\\d(?=(\\d{3})+$)', 'g'), '$&,');

            if (toman) {
                return formattedPrice + " تومان "
            }

            return formattedPrice
        } catch (e) {
            return 0 + " تومان"
        }
    },
    validationError(errorResponse, errorArray) {
        let response = errorResponse.response.data
        errorArray.push(response.message)

        for (let key in response.errors) {
            errorArray.push(key + ': ' + response.errors[key])
        }
    },

    cloneObject(object, allowed = []) {
        if (allowed.length > 0)
            object = this.filterObject(object, allowed)
        return JSON.parse(JSON.stringify(object))
    },

    filterObject(item, allowed) {
        return Object.keys(item)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = item[key];
                return obj;
            }, {});
    },

    filterObjectArray(items, allowed) {
        return items.map(item => this.filterObject(item, allowed))
    },

    renderInvoiceState(stateLId) {
        let unNormalStates = [1000, 3003, 3012, 3013, 3022, 3023, 3042, 3043, 3052]
        let cancelledStates = [1010, 1011, 1012, 1013, 5000, 5001, 5002, 5003, 5004]

        if (unNormalStates.includes(stateLId))
            return 'text-yellow-600 border-yellow-500'
        else if (cancelledStates.includes(stateLId))
            return 'text-red-500 border-red-500'
        else
            return 'text-green-500 border-green-500'
    },

    imageURL(url) {
        return process.env.VUE_APP_SERVER_URL + url
    },

    invoiceState: {
        INVENTORY_CARRIER_CONFIRM: 3041,
        DISTRIBUTOR_CONFIRM: 4002,
        SUPPORTER_ACCEPT: 3000,
    },

    DistributorAssignmentState: {
        InAssingment: 1,
        CompleteAssingment: 2,
        CompleteReceive: 3,
    },

    invoiceReturnState: {
        SUPPORTER_CONFIRM: 2001,
        ASSIGN_TO_DISTRIBUTOR: 2010,
        DISTRIBUTOR_SUBMIT: 2020,
        DELIVERED_TO_DISTRIBUTOR: 2021,
        INVENTORY_STAFF_SUBMIT: 2031,
        SUPPORTER_REJECT: 5000,
    }
}
import {getMessaging, getToken, onMessage, isSupported} from "firebase/messaging";
import firebaseApp from "@/utils/firebase";

let messageCallback = () => {};

let fcmToken = () => {}
let fcmMessage = callback => messageCallback = callback

isSupported().then(result => {
    if (result) {
        const messaging = getMessaging(firebaseApp);

        fcmToken = callback => getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_SERVER_TOKEN}).then(token => callback(token))

        onMessage(messaging, payload => messageCallback(payload))
    }
})


export {fcmToken, fcmMessage}

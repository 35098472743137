<template>
  <ModalView title="تخصیص موزع" @opened="getDistributorVehicles">
    <form @submit.prevent="submit">

      <VSelect class="mt-4" v-model="selectedDistributorVehicle" required>
        <option value="" disabled selected>انتخاب موزع</option>
        <option
            v-for="distributorVehicle in distributorVehicles"
            :key="distributorVehicle.DistributorVehicleId"
            :value="distributorVehicle.DistributorVehicleId">
          {{ distributorVehicle.DistributorName }} (
          {{ distributorVehicle.DistributorVehicleName }}
          -
          {{ distributorVehicle.BasketCount }}
          سبد)
        </option>
      </VSelect>

      <div class="flex justify-between mt-4">
        <label for="done">اتمام تخصیص</label>
        <input type="checkbox" id="done" v-model="assignmentDone">
      </div>

      <VButton class="mt-4" type="submit" :loading="$loading.value">ارجاع</VButton>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import VSelect from "@/components/General/VSelect.vue";
import {assignInvoiceToDistributor, getAssignableDistributorVehicle} from "@/API/API.js";
import VButton from "@/components/General/VButton.vue";

export default {
  name: "AssignDistributorModal",
  emits: ['submitted'],
  components: {VButton, VSelect, ModalView},
  props: ['invoiceIds', 'invoiceReturnIds', 'deliveryDateTimeId'],
  data() {
    return {
      distributorVehicles: [],
      selectedDistributorVehicle: '',
      assignmentDone: '',
    }
  },
  methods: {
    async getDistributorVehicles() {
      this.distributorVehicles = (await getAssignableDistributorVehicle(this.deliveryDateTimeId)).data.data
    },
    submit() {
      let assignment = {
        distributorVehicleId: this.selectedDistributorVehicle,
        deliveryDatetimeId: this.deliveryDateTimeId,
        invoiceIds: this.invoiceIds,
        invoiceReturnIds: this.invoiceReturnIds,
        done: this.assignmentDone
      }

      assignInvoiceToDistributor(assignment)
          .then(resp => {
            this.$emit('submitted')
            this.getDistributorVehicles()
            this.$infoModal.value = resp.data.message
          })
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg">بازه های زمانی تولید شده (امروز و فردا)</h1>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="deliveryDateTimePage.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <EditDeliveryDateTimeModal
      v-model="deactivateModal"
      :delivery-datetime="selectedDeliveryDatetime"
      @submit="submitted"
  />

</template>

<script>
import VCard from "@/components/General/VCard.vue";
import {getDeliveryDateTimes} from "@/API/API.js";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import EditDeliveryDateTimeModal from "@/components/Modal/InventoryManager/editDeliveryDateTimeModal.vue";
import VPagination from "@hennge/vue3-pagination";

export default {
  name: "DeliveryDatetimeList",
  components: {
    EditDeliveryDateTimeModal,
    VCard,
    VPagination
  },
  data() {
    return {
      deliveryDateTimePage : {},
      deliveryDateTimes: [],
      selectedDeliveryDatetime: {},
      deactivateModal: false,

      filter: {
        page: 1,
      }
    }
  },
  created() {
    this.getDeliveryDateTimes()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => element.onclick = () => {
        this.selectedDeliveryDatetime = this.deliveryDateTimes.find(deliveryDatetime => deliveryDatetime.DeliveryDateTimeId == element.dataset.id)
        this.deactivateModal = true
      })
    });
  },
  methods: {
    async getDeliveryDateTimes() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.deliveryDateTimePage = (await getDeliveryDateTimes(this.filter)).data.data
      this.deliveryDateTimes = this.deliveryDateTimePage.DeliveryDataTimes
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.deliveryDateTimes)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'تاریخ',
            field: 'DeliveryDate',
          },
          {
            title: 'بازه',
            field: 'Description',
          },
          {
            title: 'وضعیت',
            field: 'IsActive',
            formatter: cell => {
              let props = {
                bgColor: cell.getValue() ? 'bg-green-500' : 'bg-red-500',
                text: cell.getValue() ? 'فعال' : 'غیرفعال'
              }
              return `<span class="text-xs rounded px-2 py-0.5 text-white ${props.bgColor}">
                            ${props.text}
                      </span>`
            }
          },
          {
            title: 'تعداد سفارش ها',
            field: 'InvoicesCount',
          },
          {
            title: 'تعداد مرجوعی ها',
            field: 'InvoiceReturnsCount',
          },
          {
            title: 'ظرفیت باقیمانده',
            field: 'BasketCount',
            formatter: cell => cell.getValue() + ' سبد'
          },
          {
            title: 'عملیات',
            field: 'DeliveryDateTimeId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
                return `<div class="flex">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
                            </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    },
    submitted(deliveryDateTime) {
      this.selectedDeliveryDatetime = ''
      this.deactivateModal = false

      let index = this.deliveryDateTimes.findIndex(item => item.DeliveryDateTimeId === deliveryDateTime.DeliveryDateTimeId)
      this.deliveryDateTimes[index] = deliveryDateTime

      this.refreshTable()
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getDeliveryDateTimes()
      }
    }
  }

}
</script>
<template>
  <div class="intro-y flex items-center justify-between" @click="showFilter = !showFilter">
    <h1 class="text-lg cursor-pointer">
      لیست مرجوعی ها
    </h1>

    <VButton class="!w-auto intro-y order-1 lg:order-2" :disabled="selectedInvoiceReturns.length === 0"
             @click="suspendModal = true">
      تعلیق مرجوعی ها
    </VButton>
  </div>

  <InvoiceReturnListTable
      :key="refreshKey"
      :show-filter="showFilter"
      type="inventory-manager"
      @selectedInvoiceReturns="this.selectedInvoiceReturns = $event"/>

  <SuspendInvoiceReturnModal
      v-model="suspendModal"
      :invoiceReturnIds="this.selectedInvoiceReturns"
      @submit="suspendModal = false; refreshKey++"/>

</template>

<script>
import InvoiceReturnListTable from "@/components/InvoiceReturn/InvoiceReturnListTable";
import VButton from "@/components/General/VButton.vue";
import SuspendInvoiceReturnModal from "@/components/Modal/InventoryManager/SuspendInvoiceReturnModal.vue";

export default {
  name: "InvoiceReturnList",
  components: {SuspendInvoiceReturnModal, VButton, InvoiceReturnListTable},
  data() {
    return {
      showFilter: true,
      selectedInvoiceReturns: [],
      suspendModal: false,
      refreshKey: 1,
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <ModalView :title="editMode ? 'ویرایش دسته بندی' : 'ایجاد دسته بندی'"
             @opened="initDropzone">

    <VAlert class="mt-4" type="error" v-if="errors.length > 0">
      <div v-for="(error, index) in errors"
           :key="error" :class="{'font-bold' : index === 0}">
        {{ index !== 0 ? '- ' + error : error }}
      </div>
    </VAlert>

    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VInput class="mt-4" type="text" placeholder="نام دسته بندی" v-model="category.Name" required/>

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="category.ParentId"
          :options="parentCategories"
          :searchable="true"
          placeholder="دسته بندی اصلی"/>

      <VInput type="number" placeholder="ترتیب نمایش" v-model="category.ShowOrder" class="mt-4" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VAlert from "@/components/General/VAlert";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createCategory, updateCategory} from "@/API/AdminAPI";
import {Dropzone} from "dropzone";
import Multiselect from "@vueform/multiselect";

const categoryModel = {
  Name: '',
  ShowOrder: '',
  ParentId: null,
  Image: null,
}

export default {
  name: "CreateCategoryModal",
  emits: ['created', 'updated'],
  props: ['editCategory', 'parentCategories'],
  components: {VButton, VInput, VAlert, ModalView, Multiselect},
  data() {
    return {
      editMode: false,

      category: {...categoryModel},
      errors: []
    }
  },
  methods: {
    create() {
      createCategory(this.category)
          .then(resp => {
            this.$emit('created', resp.data.data)
            this.category = {...categoryModel}
            this.errors = []
          })
    },
    update() {
      updateCategory(this.editCategory.CategoryId, this.category)
          .then(resp => this.$emit('updated', resp.data.data))
    },
    initDropzone() {
      setTimeout(() => {
        let dropzone = new Dropzone(this.$refs.dropzone, {
          url: process.env.VUE_APP_STAFF_URL + 'image',
          headers: {'Authorization': this.$store.getters.token},
          maxFilesize: 1,
          dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
          dictDefaultMessage: 'انتخاب تصاویر',
          dictCancelUpload: 'لغو',
          dictRemoveFile: 'حذف',
          dictMaxFilesExceeded: 'حداکثر یک تصویر میتوانید آپلود کنید.',
          acceptedFiles: 'image/jpeg, image/png, image/webp',
          addRemoveLinks: true,
          maxFiles: 1,
          sending(file, xhr, formData) {
            formData.append('type', 'image')
          },
          success: (file, response) => {
            file.JCoFileId = response.JCoFileId;
            this.category.Image = {
              JCoFileId: response.JCoFileId,
              Path: response.Path
            }
          },
        });

        // Load Previous Images
        if (this.category.Image?.JCoFileId) {
          let mockFile = {name: 'image', size: 0, JCoFileId: this.category.Image.JCoFileId};
          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(this.category.Image.Path));
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          dropzone.options.maxFiles -= 1;
        }

        // Delete Image
        dropzone.on("removedfile", file => {
          if (file.JCoFileId) {
            this.category.Image = null
            dropzone.options.maxFiles += 1
          }
        })
      }, 50)
    }
  },
  watch: {
    editCategory(editCategory) {
      if (editCategory.CategoryId) {
        this.editMode = true
        this.category = this.$helper.cloneObject(this.editCategory, Object.keys(categoryModel))
      } else {
        this.editMode = false

        this.category = {...categoryModel}
        this.errors = []
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست تخصیص موزعین</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-2/5" v-if="showFilter">
    <VInput placeholder="جستجو نام"
            v-model.lazy="this.filter.q"
            @input="this.filter.page = 1"
            shadow/>

    <Multiselect
        dir="rtl"
        class="border-none shadow mr-4"
        v-model="filter.deliveryDateTimeId"
        :options="deliveryTimes"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="بازه تحویل"
        :can-clear="false"
        :canDeselect="false"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="distributorVehicles.last_page"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <CancelAssignmentModal
      v-model="cancelAssignmentModal"
      type="all"
      :cancelable-id="selectedDistributorVehicle"
      :delivery-datetime-id="filter.deliveryDateTimeId"
      @submit="cancelAssignmentModal = false; getDistributors()"
  />

</template>

<script>
import VCard from "@/components/General/VCard";
import VInput from "@/components/General/VInput";
import {getDistributorVehicles} from "@/API/API";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import CancelAssignmentModal from "@/components/Modal/DistributorManager/CancelAssignmentModal.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "DistributorAssignmentList",
  components: {CancelAssignmentModal, VInput, VCard, VPagination, Multiselect},
  data() {
    return {
      distributorAssignmentPage: {},
      distributorVehicles: [],
      deliveryTimes: [],

      cancelAssignmentModal: false,
      selectedDistributorVehicle: '',
      showFilter: true,

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        deliveryDateTimeId: '',
      },
    }
  },
  created() {
    this.getDistributors()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let showButton = document.querySelectorAll("div[data-btn-type='show']")
      showButton.forEach(element => element.onclick = () => this.$router.push({
            name: 'DistributorAssignmentDetail',
            params: {id: element.dataset.id}
          })
      )
      // Cancel Buttons
      let cancelButtons = document.querySelectorAll("div[data-btn-type='cancel']")
      cancelButtons.forEach(element => element.onclick = () => {
        this.cancelAssignmentModal = true
        this.selectedDistributorVehicle = element.dataset.id
      })
    });
  },
  methods: {
    async getDistributors() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')

      this.distributorAssignmentPage = (await getDistributorVehicles(this.filter, this.firstCall)).data.data
      this.distributorVehicles = this.distributorAssignmentPage.DistributorVehicles

      if (this.distributorAssignmentPage.DeliveryDataTimes.length > 0)
        this.deliveryTimes = this.distributorAssignmentPage.DeliveryDataTimes.map(time => ({
          label: time.Description,
          value: time.DeliveryDateTimeId,
          disabled: !time.CanChoose
        }))

      if (this.firstCall)
        this.filter.deliveryDateTimeId = this.deliveryTimes.find(time => !time.disabled).value

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.distributorVehicles)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'DistributorId',
          },
          {
            title: 'نام',
            field: 'DistributorName'
          },
          {
            title: 'سفارشات',
            field: 'Assignment.InvoicesCount',
          },
          {
            title: 'مرجوعی ها',
            field: 'Assignment.InvoiceReturnsCount',
          },
          {
            title: 'ظرفیت',
            field: 'Assignment.BasketCount',
            formatter: cell => cell.getData().Assignment.BasketCount + ' سبد'
          },
          {
            title: 'وضعیت',
            field: 'assignment.state.Name',
            formatter: cell => cell.getData().Assignment?.State?.Name ?? '-'
          },
          {
            title: 'عملیات',
            field: 'Assignment.DistributorAssignmentId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            width: 200,
            formatter: (cell) => {
              if (cell.getValue() && (cell.getData().Assignment.InvoicesCount > 0 || cell.getData().Assignment.InvoiceReturnsCount > 0))
              return `<div class="flex">
                        <div class="flex items-center text-blue-600 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-700">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span class="mr-1 text-xs">نمایش</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-2 cursor-pointer" data-btn-type="cancel" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                             <span class="mr-1 text-xs">لغو تخصیص</span>
                        </div>
                      </div>`
              else
                return '-'
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.firstCall === 0)
          this.getDistributors()

        this.firstCall = 0
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست سند ها</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="documents.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>
</template>

<script>
import VCard from "@/components/General/VCard";
import {getDocuments} from "@/API/API";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "DocumentPage",
  components: {
    VInput,
    VCard, VPagination
  },
  data() {
    return {
      documents: [],
      showFilter: true,

      filter: {
        q: '',
        page: 1,
      },
    }
  },
  created() {
    this.getDocuments()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let showButton = document.querySelectorAll("div[data-btn-type='show']")
      showButton.forEach(element =>
          element.onclick = () => {
            this.$router.push({name: 'InventoryManagerDocumentDetail', params: {id: element.dataset.id}})
          })
    });
  },
  methods: {
    async getDocuments() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.documents = (await getDocuments(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.documents.DocumentData)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'ردیف',
            field: 'Index',
            formatter: cell => cell.getRow().getPosition()
          },
          {
            title: 'شناسه',
            field: 'VoucherHdrId',
            vertAlign: 'middle',
          },
          {
            title: 'تاریخ',
            field: 'CreateDate',
            vertAlign: 'middle',
          },
          {
            title: 'عملیات',
            field: 'DocumentId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span class="mr-1 text-xs">نمایش</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getDocuments()
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="رسیدگی پشتیبانی" @opened="modalOpened" @closed="modalClosed">

    <form @submit.prevent="submit">

      <VInput class="mt-4" placeholder="نتیجه" v-model="editComplaint.result" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {updateComplaint} from "@/API/API";

export default {
  name: "EditComplaintModal",
  props: ['complaint'],
  emits: ['submitted'],
  components: {VButton, VInput, ModalView},
  data() {
    return {
      states: [],
      editComplaint: {
        stateLId: '',
        result: ''
      }
    }
  },
  methods: {
    submit() {
      updateComplaint(this.complaint.ComplaintId, this.editComplaint)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted', resp.data.data)
          })
    },
    modalOpened() {
      this.editComplaint.stateLId = this.complaint.StateLId
      this.editComplaint.result = this.complaint.Result
    },
    modalClosed() {
      this.editComplaint.stateLId = ''
      this.editComplaint.result = ''
    }
  },
}
</script>

<style scoped>

</style>
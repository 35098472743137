<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="mb-4" @click="accept" v-if="complaint.StaffId === null" :loading="$loading.value">
        قبول
      </VButton>
      <VButton class="mb-4" outline @click="back" v-else-if="canEdit" :loading="$loading.value">
        برگشت پشتیبانی به پنل اپراتور ها
      </VButton>

      <VButton class="mb-4" @click="editComplaintModal = true" v-if="canEdit">ثبت نتیجه</VButton>

      <!-- Complaint Info -->
      <KeyValueCard title="اطلاعات پشتیبانی">
        <KeyValueCardItem title="شماره سفارش" :value="complaint.InvoiceId" icon="HashtagIcon"/>
        <KeyValueCardItem title="وضعیت" :value="complaint.State?.Name" icon="ClipboardListIcon"
                          value-class="text-yellow-500"/>
        <KeyValueCardItem title="علت" :value="complaint.Reason?.Name" icon="DocumentIcon"/>
        <KeyValueCardItem title="توضیحات" :value="complaint?.Description" icon="DocumentIcon"/>
        <KeyValueCardItem title="تاریخ ثبت" :value="complaint.CreateDate" icon="CalendarIcon"/>
        <KeyValueCardItem title="نتیجه" :value="complaint.Result ?? '-'" icon="DeviceMobileIcon" last/>
      </KeyValueCard>

      <!-- Attachments -->
      <VCard class="mt-4">
        <h1 class="mb-4 text-lg">تصاویر</h1>
        <div class="grid grid-cols-3 gap-x-4" v-if="complaint.Images?.length > 0">
          <a v-for="(image, index) in complaint.Images"
             :key="index"
             :href="$helper.imageURL(image.Path)"
             target="_blank">
            <img class="w-auto rounded-md shadow" :src="$helper.imageURL(image.Path)" alt="">
          </a>
        </div>
        <div class="text-sm text-gray-400 mt-2" v-else>ندارد</div>

        <h1 class="mt-4 text-lg">صوت</h1>
        <audio class="mt-4 w-full" v-if="complaint.Audio" :src="$helper.imageURL(complaint.Audio.Path)" controls/>
        <div class="text-sm text-gray-400 mt-2" v-else>ندارد</div>
      </VCard>

      <!-- Customer Detail -->
      <KeyValueCard class="mt-4" title="اطلاعات مشتری">
        <KeyValueCardItem title="نام و نام خانوادگی" :value="complaint.User?.Name" icon="UserIcon"/>
        <KeyValueCardItem title="شماره موبایل" :value="complaint.User?.Mobile" icon="DeviceMobileIcon" last/>
      </KeyValueCard>

    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <CoordinationsTable
          class="mt-0"
          :id="complaint.ComplaintId"
          :coordinations="complaint.Coordinations"
          :canEdit="canEdit"
          type="complaint"
          @submitted="coordinationSubmitted"/>

    </div>
  </div>

  <EditComplaintModal
      v-model="editComplaintModal"
      :complaint="complaint"
      @submitted="complaintUpdated"/>
</template>

<script>
import VCard from "@/components/General/VCard";
import {acceptComplaint, backComplaint, getComplaint} from "@/API/API";
import VButton from "@/components/General/VButton";
import {mapState} from "vuex";
import EditComplaintModal from "@/components/Modal/Operator/EditComplaintModal";
import CoordinationsTable from "@/components/CoordinationsTable";
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";

export default {
  name: "ComplaintDetail",
  components: {
    KeyValueCardItem,
    KeyValueCard,
    CoordinationsTable,
    EditComplaintModal,
    VButton,
    VCard,
  },
  data() {
    return {
      complaint: {},

      editComplaintModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.complaint.StaffId === this.user.UserId
    }
  },
  created() {
    this.getComplaint()
  },
  methods: {
    async getComplaint() {
      this.complaint = (await getComplaint(this.$route.params.id)).data.data
    },
    accept() {
      acceptComplaint(this.$route.params.id)
          .then(resp => Object.assign(this.complaint, resp.data.data))
    },
    back() {
      backComplaint(this.$route.params.id)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    complaintUpdated(complaint) {
      this.editComplaintModal = false
      Object.assign(this.complaint, complaint)
    },
    coordinationSubmitted(coordination) {
      this.complaint.Coordinations.push(coordination)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <VCard noPadding>
    <h1 class="m-4 text-lg">مرجوعی ها</h1>

    <div ref="table"></div>

  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "InvoiceReturnsTable",
  props: ['invoiceReturns'],
  components: {VCard},
  mounted() {
    this.initTable()
  },
  methods: {
    refreshTable() {
      this.tabulator.setData(this.invoiceReturns)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'InvoiceReturnId',
            vertAlign: 'middle'
          },
          {
            title: 'وضعیت',
            field: 'state',
            vertAlign: 'middle',
            formatter: cell => `<span class="badge-outline border-green-500">${cell.getData().State.Name}</span>`
          },
          {
            title: 'محصولات',
            field: 'items',
            vertAlign: 'middle',
            formatter: cell => {
              let template = '<div class="flex gap-x-4 overflow-x-auto">'
              cell.getData().Items.forEach((item, index) => {
                    template += `
                <div class="flex justify-between items-center flex-wrap gap-x-6 shrink-0 ${index + 1 < cell.getData().Items.length ? 'border-b' : ''}">
                  <div class="flex items-center">
                        <img src="${this.$helper.imageURL(item.Product?.Image)}" alt="" class="w-12">
                        <div class="mr-1">
                            <div>${item.Product.Name}</div>
                            <div>تعداد: <span class="font-bold">${item.Amount} عدد</span></div>
                        </div>
                  </div>
                  <div class="mt-2 md:mt-0">
                        <div class="font-bold">${item.Reason ? item.Reason.Name : ""}</div>
                        <div>${item.Description ? item.Description : ""}</div>
                        </div>
                  </div>
`
                  }
              )
              return template + '</div>'
            }
          },
        ],
      });
    }
  },
  watch: {
    invoiceReturns(invoiceReturns) {
      if (invoiceReturns)
        this.refreshTable()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between items-center">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست انبار ها</h1>

    <VButton class="w-auto" @click="createModal = true; editInventory = {}">انبار جدید</VButton>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="inventories.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent intro-y mt-4 px-2 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateInventoryModal
      v-model="createModal"
      @created="created"
      @updated="updated"
      :edit-inventory="editInventory"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import {getInventories} from "@/API/AdminAPI";
import "@/assets/css/pagination.css";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VButton from "@/components/General/VButton";
import CreateInventoryModal from "@/components/Modal/Admin/CreateInventoryModal";
import VInput from "@/components/General/VInput.vue";
import VPagination from "@hennge/vue3-pagination";

export default {
  name: "InventoryPage",
  components: {
    VInput,
    CreateInventoryModal,
    VButton,
    VCard,
    VPagination
  },
  data() {
    return {
      inventories: [],
      tabulator: null,
      createModal: false,
      editInventory: {},
      showFilter: true,

      filter: {
        q: '',
        page: 1
      }
    }
  },
  created() {
    this.getInventories()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element => element.onclick = () => this.$router.push({
        name: 'AdminInventoryDetail',
        params: {id: element.dataset.id}
      }))

      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => element.onclick = () => {
            this.editInventory = this.inventories.Inventories.find(inventory => inventory.InventoryId == element.dataset.id)
            this.createModal = true
          }
      )
    });
  },
  methods: {
    async getInventories() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.inventories = (await getInventories(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.inventories.Inventories)
      window.scrollTo(0, 0)
    },
    created(inventories) {
      this.createModal = false
      this.inventories.Inventories.unshift(inventories[0])
      this.refreshTable()
    },
    updated(inventories) {
      this.createModal = false
      let index = this.inventories.Inventories.findIndex(item => item.InventoryId === inventories[0].InventoryId)
      this.inventories.Inventories[index] = inventories[0]
      this.refreshTable()
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'کد انبار',
            field: 'InventoryId',
          },
          {
            title: 'نام',
            field: 'Name',
            formatter: cell => `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`
          },
          {
            title: 'کد ضایعات',
            field: 'DefectInventoryId',
          },
          {
            title: 'شهر',
            field: 'CityName',
          },
          {
            title: 'عملیات',
            field: 'InventoryId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                          <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}" ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span class="mr-1 text-xs">نمایش</span>
                          </div>
                          <div class="flex items-center text-amber-500 cursor-pointer mr-4" data-btn-type="edit" data-id="${cell.getValue()}">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                               </svg>
                               <span class="mr-1 text-xs">ویرایش</span>
                          </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getInventories()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
    <div class="stl">
        <h2>404</h2>
        <h3>صفحه وجود ندارد</h3>
    </div>
</template>
<style scoped>
.stl{
    margin: 150px;
    text-align: center;    
}
h2{
    font-size: 5rem;
    padding: 100px;
    color: rgb(230, 42, 42);
}
h3{
    font-size: 3rem;
    color: rgb(230, 42, 42);
}
</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست کمپین ها</h1>
    <VButton class="!w-auto" @click="campaignModal = true; editCampaign = {}">کمپین جدید</VButton>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو نام" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="campaignPage.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
      @update:modelValue="getCampaigns"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateCampaignModal
      v-model="campaignModal"
      :editCampaign="editCampaign"
      @created="created"
      @updated="updated"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف کمپین"
      text="آیا از حذف این کمپین مطمئن هستید ؟"
      @confirm="deleteCampaign"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import {deleteCampaign, getCampaigns} from "@/API/API";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import CreateCampaignModal from "@/components/Modal/InventoryManager/CreateCampaignModal";

export default {
  name: "CategoryPage",
  components: {
    CreateCampaignModal,
    ConfirmModal,
    VInput, VButton, VCard, VPagination
  },
  data() {
    return {
      campaignPage: {},
      campaigns: [],

      campaignModal: false,
      editCampaign: {},
      showFilter: true,

      filter: {
        q: '',
        page: 1,
      },

      confirmDeleteModal: false,
      deleteIndex: '',
    }
  },
  created() {
    this.getCampaigns()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element =>
          element.onclick = () => {
            this.editCampaign = this.campaigns.find(campaign => campaign.CampaignId == element.dataset.id)
            this.campaignModal = true
          })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element =>
          element.onclick = () => {
            this.deleteIndex = this.campaigns.findIndex(campaign => campaign.CampaignId == element.dataset.id)
            this.confirmDeleteModal = true
          })
      // Product Buttons
      let productButton = document.querySelectorAll("div[data-btn-type='product']")
      productButton.forEach(element =>
          element.onclick = () => this.$router.push({
            name: 'InventoryManagerCampaignDetail',
            params: {id: element.dataset.id}
          }))
    });
  },
  methods: {
    async getCampaigns() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.campaignPage = (await getCampaigns(this.filter)).data.data
      this.campaigns = this.campaignPage.Campaigns
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    created(campaign) {
      this.campaignModal = false
      this.campaigns.unshift(campaign)
      this.refreshTable()
    },
    updated(editCategory) {
      this.campaignModal = false
      this.editCampaign = {}
      let index = this.campaigns.findIndex(campaign => campaign.CampaignId === editCategory.CampaignId)
      this.campaigns[index] = editCategory
      console.log(editCategory)
      console.log(index)
      this.refreshTable()
    },
    deleteCampaign() {
      this.confirmDeleteModal = false
      deleteCampaign(this.campaigns[this.deleteIndex].CampaignId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.campaigns.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
    },
    refreshTable() {
      this.tabulator.setData(this.campaigns)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'نام', field: 'Name',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Image?.Path)}" alt="" class="${cell.getData().Image ? 'w-14 h-14' : ''}">
                        <span class="${cell.getData().Image ? 'mr-2' : ''}">${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'تاریخ شروع',
            field: 'StartDateFa',
            vertAlign: 'middle',
          },
          {
            title: 'تاریخ پایان',
            field: 'EndDateFa',
            vertAlign: 'middle',
          },
          {
            title: 'تعداد محصولات',
            field: 'ProductsCount',
            vertAlign: 'middle',
          },
          {
            title: 'عملیات',
            field: 'CampaignId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="product" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>
                            <span class="mr-1 text-xs">محصولات</span>
                        </div>
                        <div class="flex items-center text-amber-500 mr-4 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}" ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getCampaigns()
        // this.$helper.debounce(() => this.getCategories())
      }
    }
  }
}
</script>

<style scoped>

</style>
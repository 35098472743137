<template>
  <ModalView>
    <img :src="$imageModal.value" alt="">
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";

export default {
  name: "ImageModal",
  components: {ModalView}
}
</script>

<style scoped>

</style>
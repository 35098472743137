<template>
  <ModalView title="تعلیق مرجوعی ها" @closed="resetData" @opened="getData">

    <form @submit.prevent="suspend">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          :options="states"
          v-model="suspendData.stateId"
          :searchable="true"
          placeholder="وضعیت"
          required/>

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          :options="rejectReasons"
          v-model="suspendData.rejectReasonId"
          :searchable="true"
          placeholder="دلیل رد"
          required/>

      <VInput class="mt-4" placeholder="توضیحات" v-model="suspendData.rejectDescription" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import {getLookupCombos, suspendInvoiceReturns} from "@/API/API.js";
import Multiselect from "@vueform/multiselect";
import VButton from "@/components/General/VButton.vue";
import VInput from "@/components/General/VInput.vue";

let suspendModel = {
  stateId: '',
  rejectReasonId: '',
  rejectDescription: '',
  invoiceReturnIds: []
}

export default {
  name: "SuspendInvoiceReturnModal",
  emits: ['submit'],
  props: ['invoiceReturnIds'],
  components: {ModalView, Multiselect, VButton, VInput},
  data() {
    return {
      rejectReasons: [],
      states: [],

      suspendData: this.$helper.cloneObject(suspendModel)
    }
  },
  methods: {
    async getData() {
      let data = (await getLookupCombos(103020251)).data.data
      this.states = data.Lookups.InvoiceReturnStates.map(state => ({
        label: state.Name,
        value: state.LookupId
      }))

      this.rejectReasons = data.Lookups.DenyReasonStates.map(reason => ({
        label: reason.Name,
        value: reason.LookupId
      }))
    },
    suspend() {
      this.suspendData.invoiceReturnIds = this.invoiceReturnIds

        suspendInvoiceReturns(this.suspendData)
            .then((resp) => {
              this.$infoModal.value = resp.data.message
              this.$emit('submit')
              this.resetData()
            })
    },
    resetData() {
      this.suspendData = this.$helper.cloneObject(suspendModel)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4 gap-4">

    <div class="col-span-4 xl:col-span-1">
      <div class="mb-4">
        <VButton @click="accept" v-if="invoiceReturn.Acceptable">قبول</VButton>
        <VButton outline @click="back" :loading="$loading.value" v-else-if="canEdit">
          برگشت مرجوعی به پنل بازرسین کیفی
        </VButton>
      </div>

      <!-- InvoiceReturn Info-->
      <InvoiceReturnDetailCard :invoiceReturn="invoiceReturn"/>
    </div>


    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Products -->
      <h1 class="mb-4 text-lg">محصولات مرجوعی</h1>

      <InvoiceReturnProduct
          v-for="item in invoiceReturn.Items"
          :key="item.InvoiceReturnDetailId"
          type="QC-staff"
          :invoice-return-detail="item"
          :can-edit="canEdit"
          @edited="editInvoiceReturn"/>

      <!-- Button -->
      <VButton class="mt-4 xl:w-1/4 lg:mr-auto" @click="confirmModal = true" v-if="canEdit">ثبت</VButton>
    </div>
  </div>


  <ConfirmModal
      v-model="confirmModal"
      title="ثبت مرجوعی"
      text="از ثبت مطمئن هستید ؟"
      @cancel="confirmModal = false"
      @confirm="submit"/>

</template>

<script>
import {acceptInvoiceReturn, backInvoiceReturn, getInvoiceReturn, submitInvoiceReturn} from "@/API/API";
import VButton from "@/components/General/VButton";
import {mapState} from "vuex";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import InvoiceReturnDetailCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnDetailCard";
import InvoiceReturnProduct from "@/components/InvoiceReturn/InvoiceReturnProduct";

export default {
  name: "InvoiceReturnDetail",
  components: {
    InvoiceReturnProduct,
    InvoiceReturnDetailCard,
    ConfirmModal,
    VButton,
  },
  data() {
    return {
      invoiceReturn: {},

      editReturnDetailModal: false,
      confirmModal: false,

      selectedReturnDetail: {}
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoiceReturn.StaffId === this.user.UserId
    },
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoiceReturn(this.invoiceReturn.InvoiceReturnId, 'QC-staff')
          .then(resp => Object.assign(this.invoiceReturn, resp.data.data))
    },
    back() {
      backInvoiceReturn(this.invoiceReturn.InvoiceReturnId, 'QC-staff')
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    submit() {
      const allowed = ['InvoiceReturnDetailId', 'InventoryHealthyAmount', 'InventoryDefectAmount', 'InventoryNoNeedAmount'];
      let items = this.$helper.filterObjectArray(this.invoiceReturn.Items, allowed)

      submitInvoiceReturn(this.invoiceReturn.InvoiceReturnId, {items})
          .then(resp => {
            this.confirmModal = false
            this.$router.replace({name: 'QCStaffInvoiceReturnList'})
            this.$infoModal.value = resp.data.message
          })
    },
    editInvoiceReturn(returnDetail) {
      this.editReturnDetailModal = false
      let index = this.invoiceReturn.Items.findIndex(item => item.InvoiceReturnDetailId === returnDetail.InvoiceReturnDetailId)
      this.invoiceReturn.Items[index] = returnDetail
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="محصولات" @closed="reset">
    <div class="mt-4 flex flex-wrap gap-2">
      <VBadge
          v-for="product in selectedProducts"
          :key="product.ProductId">
        {{ product.Name }}
        <XIcon class="w-4 inline cursor-pointer" @click="removeProduct(product)"/>
      </VBadge>
    </div>
    <VInput class="mt-4" placeholder="نام محصول" v-model.lazy="q"/>

    <!-- Result -->
    <div class="mt-4 overflow-y-scroll max-h-[50vh]">
      <VCard class="relative grid grid-cols-5 items-center overflow-hidden"
             v-for="product in products"
             :key="product.ProductId">

        <img class="w-12" :src="$helper.imageURL(product.Image)" alt="">
        <span class="text-xs col-span-2">{{ product.Name }}</span>

        <div>
          <span class="font-bold">قیمت:</span>
          <span class="mr-1">{{ $helper.priceFormatter(product.JCoPrice, false) }}</span>
        </div>

        <VButton class="mt-2 text-xs !py-1"
                 :loading="$loading.value"
                 @click="addProduct(product)"
                 outline
                 v-if="!selectedProducts.some(item => item.ProductId === product.ProductId)">
          افزودن
        </VButton>

        <VButton class="mt-2 text-xs !py-1 !bg-red-500 "
                 :loading="$loading.value"
                 @click="removeProduct(product)"
                 v-else>
          حذف
        </VButton>
      </VCard>
    </div>

    <VButton :disabled="selectedProducts.length === 0"
             @click="submitProducts">ثبت
    </VButton>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {addProductToCampaign, searchProducts} from "@/API/API";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput.vue";
import VCard from "@/components/General/VCard.vue";
import VBadge from "@/components/General/VBadge.vue";
import {XIcon} from "@heroicons/vue/outline";

export default {
  name: "CampaignProductModal",
  props: ['campaign'],
  emits: ['added'],
  components: {VBadge, VCard, VInput, VButton, ModalView, XIcon},
  data() {
    return {
      q: '',
      products: [],
      selectedProducts: []
    }
  },
  methods: {
    async search() {
      if (this.q.length > 1) {
        this.products = (await searchProducts(this.q, this.campaign.InventoryId)).data.Products
      }
    },
    addProduct(product) {
      this.selectedProducts.push(product)
    },
    removeProduct(product) {
      let index = this.selectedProducts.findIndex(item => item.ProductId === product.ProductId)
      this.selectedProducts.splice(index, 1)
    },
    submitProducts() {
      addProductToCampaign(this.$route.params.id, this.selectedProducts.map(product => product.ProductId))
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('added', resp.data.data)
          })
    },
    reset() {
      this.selectedProducts = []
      this.products = []
      this.q = ''
    }
  },
  watch: {
    q() {
      this.search()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView
      title="مرجوعی محصول"
      class="text-gray-500"
      @opened="modalOpened">

    <form @submit.prevent="sendBack">

      <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VSelect class="mt-4" v-model="sentBack.ReturnReasonLId" required>
        <option value="" disabled>علت مرجوعی</option>
        <option
            v-for="reason in returnReasons"
            :key="reason.LookupId"
            :value="reason.LookupId">
          {{ reason.Name }}
        </option>
      </VSelect>

      <VInput class="mt-4" placeholder="توضیحات" v-model="sentBack.Description"/>

      <div class="grid grid-cols-2 mt-4 gap-4">

        <NumberPicker label="تعداد" v-model.number="sentBack.Amount" :min="1" :max="stockDetail.Amount - stockDetail.PreviousReturnAmount" required/>

        <NumberPicker label="بدون نیاز بازگشت" v-model.number="sentBack.NoNeedAmount" :max="sentBack.Amount - sentBack.HealthyAmount - sentBack.DefectAmount" required/>

        <NumberPicker label="تعداد سالم" v-model.number="sentBack.HealthyAmount" :max="sentBack.Amount - sentBack.NoNeedAmount - sentBack.DefectAmount" required/>

        <NumberPicker label="تعداد ضایعاتی" v-model.number="sentBack.DefectAmount" :max="sentBack.Amount - sentBack.HealthyAmount - sentBack.NoNeedAmount" required/>

      </div>

      <div class="flex mt-4">
        <VButton @click.prevent="$emit('reset', stockDetail)" outline>انصراف</VButton>
        <VButton class="mr-4" type="submit">ثبت</VButton>
      </div>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {getLookupCombos} from "@/API/API";
import Dropzone from "dropzone";
import {openDefaultEditor} from "@/assets/pintura";
import VSelect from "@/components/General/VSelect";
import NumberPicker from "@/components/General/NumberPicker";

const sentBackModel = {
  StockDetailId: '',
  ReturnReasonLId: '',
  Description: '',
  Amount: '',
  NoNeedAmount: '',
  HealthyAmount: '',
  DefectAmount: '',
  Images: []
}

export default {
  name: "StockDetailReturnModal",
  props: ['stockDetail'],
  emits: ['returned', 'reset'],
  components: {NumberPicker, VSelect, VButton, VInput, ModalView},
  data() {
    return {
      returnReasons: [],
      sentBack: this.$helper.cloneObject(sentBackModel)
    }
  },
  methods: {
    async getReturnReasons() {
      if (this.returnReasons.length === 0)
        this.returnReasons = (await getLookupCombos(103070201)).data.data.Lookups.Lookups7
    },
    sendBack() {
      let sum = this.sentBack.HealthyAmount + this.sentBack.DefectAmount + this.sentBack.NoNeedAmount

      if (sum !== this.sentBack.Amount) {
        this.$errorModal.value = 'جمع مقادیر ورودی برابر با تعداد ثبت شده نیست.'
        return
      }

      if(this.sentBack.Images.length === 0) {
        this.$errorModal.value = 'لطفا برای مرجوعی عکس ثبت کنید.'
        return
      }

      this.$emit('returned', this.sentBack)
    },
    initEditor() {
      let dropzone = new Dropzone(this.$refs.dropzone, {
        url: process.env.VUE_APP_STAFF_URL + 'user/file',
        headers: {'Authorization': this.$store.getters.token},
        maxFilesize: 1,
        dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
        dictDefaultMessage: 'انتخاب تصاویر',
        dictCancelUpload: 'لغو',
        acceptedFiles: 'image/jpeg, image/png',
        sending(file, xhr, formData) {
          formData.append('type', 'image')
        },
        transformFile: (file, done) => {
          const editor = openDefaultEditor({
            imageCropAspectRatio: 1,
            src: file,
            imageWriter: {
              quality: 0.1,
            },
            utils: ['crop', 'annotate'],
          });
          // When done, assign the resulting file back to dropzone
          editor.on('process', (imageState) => {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(imageState.dest)
            fileReader.onload = () => {
              dropzone.emit('thumbnail', file, fileReader.result);
              done(imageState.dest)
            };
          });
        },
        success: (file, response) => {
          file.JCoFileId = response.JCoFileId;
          this.sentBack.Images.push({
            JCoFileId: response.JCoFileId,
            Path: response.Path
          })
        },
      });

      // Load Previous Images
      this.sentBack.Images.forEach(image => {
        var mockFile = {name: 'image', size: 0, JCoFileId: image.JCoFileId};
        dropzone.options.addedfile.call(dropzone, mockFile);
        dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(image.Path));
        mockFile.previewElement.classList.add('dz-success');
        mockFile.previewElement.classList.add('dz-complete');
        dropzone.options.maxFiles -= 1;
      })
    },
    modalOpened() {
      if (this.stockDetail.SentBack) {
        this.sentBack = this.$helper.cloneObject(this.stockDetail.SentBack, Object.keys(sentBackModel))
      } else {
        this.sentBack = this.$helper.cloneObject(sentBackModel)
        this.sentBack.StockDetailId = this.stockDetail.StockDetailId
      }

      this.getReturnReasons()

      setTimeout(() => this.initEditor(), 50)
    }
  },
}
</script>

<style>
@import "@/assets/css/pintura.css";
@import "dropzone/dist/dropzone.css";

</style>
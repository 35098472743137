<template>
  <VCard class="text-sm text-gray-400">
    <!-- Title -->
    <h1 class="pb-4 text-lg border-b"
    :class="titleColor ? titleColor : 'text-gray-800'">{{ title }}</h1>
    <!-- Items -->
    <slot></slot>
  </VCard>
</template>

<script>
import VCard from "@/components/General/VCard";

export default {
  name: "KeyValueCard",
  props: ['title', 'titleColor'],
  components: {VCard},
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست درخواست پشتیبانی</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>

    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.state"
        :options="complaintStates"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="complaints.PageCount"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {getComplaints} from "@/API/API";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import Multiselect from "@vueform/multiselect";
import VInput from "@/components/General/VInput.vue";
import {mapState} from "vuex";

export default {
  name: "ComplaintPage",
  components: {
    VInput,
    Multiselect,
    VCard,
    VPagination,
  },
  data() {
    return {
      showFilter: true,

      complaintPage: {},
      complaints: {},
      complaintStates: [],
      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        state: '',
      },
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    this.getComplaints()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Buttons
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element => element.onclick = () => this.$router.push({
        name: 'SupporterComplaintDetail',
        params: {id: element.dataset.id}
      }))
    });
  },
  methods: {
    async getComplaints() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')

      this.complaintPage = (await getComplaints(this.filter, this.firstCall)).data.data
      this.complaints = this.complaintPage.Complaints

      if (this.complaintPage.States.length > 0)
        this.complaintStates = this.complaintPage.States.map(category => ({
          label: category.Name,
          value: category.LookupId,
        }))

      this.firstCall = 0

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.complaints)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        rowFormatter: row => {
          if (row.getData().StaffId === this.user.UserId)
            row.getElement().classList.add('!bg-green-100')
        },
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'ComplaintId'
          },
          {
            title: 'سفارش',
            field: 'InvoiceId'
          },
          {
            title: 'نام مشتری',
            field: 'User.Name'
          },
          {
            title: 'شماره مشتری',
            field: 'User.Mobile'
          },
          {
            title: 'علت',
            field: 'Reason.Name'
          },
          {
            title: 'توضیحات',
            field: 'Description'
          },
          {
            title: 'نتیجه',
            field: 'Result',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
            formatter: cell => {
              return `<span class="badge-outline border-green-500">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'تاریخ ثبت',
            field: 'CreateDate'
          },
          {
            title: 'مسئول مربوطه',
            field: 'StaffName',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'عملیات',
            field: 'ComplaintId',
            resizable: false,
            headerSort: false,
            formatter: (cell) => {
              return `<div class="flex items-center text-blue-600 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-700">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                           </svg>
                           <span class="mr-1 text-xs">نمایش</span>
                      </div>
                    `
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
          this.getComplaints()
      }
    },
  }
}
</script>

<style scoped>

</style>
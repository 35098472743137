<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست مشخصات</h1>
    <VButton class="!w-auto" @click="specModal = true; editSpec = {}">مشخصات جدید</VButton>

  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="specs.PageCount"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <!-- Create Spec Modal -->
  <CreateSpecModal
      v-model="specModal"
      :editSpec="editSpec"
      @created="created"
      @updated="updated"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف مشخصه کالا"
      text="آیا از حذف این مشخصات مطمئن هستید ؟"
      @confirm="deleteSpec"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {deleteSpec, getSpecs} from "@/API/AdminAPI";
import VButton from "@/components/General/VButton";
import CreateSpecModal from "@/components/Modal/Admin/CreateSpecModal";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "SpecPage",
  components: {
    VInput,
    ConfirmModal,
    CreateSpecModal, VButton, VCard, VPagination,
  },
  data() {
    return {
      specs: [],
      specModal: false,
      editSpec: {},
      showFilter: true,

      filter: {
        q: '',
        page: 1,
      },

      errorModal: false,
      confirmDeleteModal: false,
      deleteIndex: '',
    }
  },
  created() {
    this.getSpecs()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.editSpec = this.specs.Specs.find(spec => spec.SpecId == element.dataset.id)
          this.specModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = this.specs.Specs.findIndex(spec => spec.SpecId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
      // Show Buttons
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element => {
        element.onclick = () => {
          this.$router.push({
            name: 'AdminSpecDetail',
            params: {id: element.dataset.id}
          })
        }
      })
    });
  },
  methods: {
    async getSpecs() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.specs = (await getSpecs(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    created(spec) {
      this.specModal = false
      this.specs.Specs.unshift(spec)
      this.refreshTable()
    },
    updated(editedSpec) {
      this.specModal = false
      this.editSpec = false

      let index = this.specs.Specs.findIndex(spec => spec.SpecId === editedSpec.SpecId)
      this.specs.Specs[index] = editedSpec
      this.refreshTable()
    },
    deleteSpec() {
      this.confirmDeleteModal = false
      deleteSpec(this.specs.Specs[this.deleteIndex].SpecId)
          .then(() => {
            this.specs.Specs.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
    },
    refreshTable() {
      this.tabulator.setData(this.specs.Specs)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'SpecId'
          },
          {
            title: 'نام',
            field: 'Name'
          },
          {
            title: 'دسته بندی ها',
            field: 'Categories',
            width: 500,
            formatter: cell => {
              let template = '<div class="flex text-xs flex-wrap gap-1">'
              cell.getData().Categories.forEach(category => {
                template += `<span class="badge-outline border-green-500 m-0.5 whitespace-pre-wrap">${category.Name}</span>`
              })
              return template + `</div>`
            }
          },
          {
            title: 'مقادیر',
            field: 'Values',
            formatter: cell => {
              if (cell.getData().IsText)
                return '-'

              let template = '<div class="flex text-xs flex-wrap">'
              cell.getData().Values?.forEach(category => {
                template += `<span class="badge-outline border-blue-500 m-0.5 whitespace-pre-wrap">${category.Name}</span>`
              })
              return template + `</div>`
            }
          },
          {
            title: 'عملیات',
            field: 'SpecId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex flex-wrap gap-x-4">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>
                            <span class="mr-1 text-xs">محصولات</span>
                        </div>
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getSpecs()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between flex-wrap">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست ارسال</h1>

    <VCard class="w-full lg:w-auto flex justify-between order-2 lg:order-1 mt-4 lg:mt-0"
           v-if="selectedInvoices.length > 0">
      <div>
        <span class="text-gray-500">تعداد: </span>
        <br>
        <span class="font-bold">{{ selectedInvoices.length }} عدد</span>
      </div>
      <div class="lg:mr-8">
        <span class="text-gray-500">حجم: </span>
        <br>
        <span class="font-bold">{{ totalVolume }} سبد</span>
      </div>
    </VCard>

    <VButton class="!w-auto intro-y order-1 lg:order-2" :disabled="selectedInvoices.length === 0"
             @click="assignModal = true">
      تخصیص به موزع
    </VButton>
  </div>

  <div class="intro-y mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <Multiselect
        dir="rtl"
        class="border-none shadow md:w-2/3 lg:w-2/3 mr-0"
        v-model="filter.deliveryDateTimeId"
        :options="deliveryTimes"
        :searchable="true"
        @change="warnDateTimeChangeModal = true"
        placeholder="بازه تحویل"
        :can-clear="false"
        :canDeselect="false"/>
  </div>

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding>

    <div ref="table"></div>

  </VCard>

  <AssignDistributorModal
      v-model="assignModal"
      :invoice-ids="invoiceIds"
      :invoice-return-ids="invoiceReturnIds"
      :delivery-date-time-id="filter.deliveryDateTimeId"
      @submitted="invoicesAssigned"/>

  <ModalView
      v-model="warnDateTimeChangeModal"
      title="هشدار"
      class="text-gray-500">
    <h1 class="mt-4">هشدار، بازه زمانی فعلی عوض شد. لطفا به زمان تحویل سفارشات دقت شود.</h1>
  </ModalView>
</template>

<script>
import {readyToSendInvoices} from "@/API/API";
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import Multiselect from "@vueform/multiselect";
import AssignDistributorModal from "@/components/Modal/DistributorManager/AssignDistributorModal.vue";
import ModalView from "@/components/Modal/General/ModalView.vue";

export default {
  name: "DeliveryList",
  components: {ModalView, AssignDistributorModal, VButton, VCard, Multiselect},
  data() {
    return {
      deliveryList: [],
      invoices: [],
      deliveryTimes: [],
      selectedInvoices: [],
      showFilter: true,

      firstCall: 1,
      filter: {
        deliveryDateTimeId: '',
      },
      assignModal: false,
      warnDateTimeChangeModal: false,
    }
  },
  computed: {
    invoiceIds() {
      return this.selectedInvoices
          .filter(item => !item.InvoiceReturnId)
          .map(invoice => invoice.InvoiceId)
    },
    invoiceReturnIds() {
      return this.selectedInvoices
          .filter(item => item.InvoiceReturnId)
          .map(invoice => invoice.InvoiceReturnId)
    },
    totalVolume() {
      return this.selectedInvoices.reduce((totalVolume, invoice) => totalVolume += invoice.BasketCount ?? 0, 0)
    }
  },
  created() {
    if (this.$route.query.deliveryDateTimeId)
      this.filter.deliveryDateTimeId = this.$route.query.deliveryDateTimeId
    else
      this.getInvoices()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Button
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element =>
          element.onclick = () => {
            if (element.dataset.type === 'Invoice')
              this.$router.push({name: 'DistributorManagerInvoiceDetail', params: {id: element.dataset.id}})
            else if (element.dataset.type === 'InvoiceReturn')
              this.$router.push({name: 'DistributorManagerInvoiceReturnDetail', params: {id: element.dataset.id}})
          }
      )
    });

    this.tabulator.on("rowSelectionChanged", data => this.selectedInvoices = data)
  },
  methods: {
    async getInvoices() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')

      this.deliveryList = (await readyToSendInvoices(this.filter, this.firstCall)).data.data

      if (this.deliveryList.DeliveryDateTime.length > 0) {
        this.deliveryTimes = this.deliveryList.DeliveryDateTime.map(time => ({
          label: time.Description,
          value: time.DeliveryDateTimeId,
          disabled: !time.CanChoose
        }))

        if (!this.filter.deliveryDateTimeId)
          this.filter.deliveryDateTimeId = this.deliveryTimes.find(time => !time.disabled).value

        this.firstCall = 0
      }

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    invoicesAssigned() {
      this.selectedInvoices = []
      this.assignModal = false
      this.getInvoices()
    },
    refreshTable() {
      this.tabulator.setData(this.deliveryList.Invoices.concat(this.deliveryList.InvoiceReturns))
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: true,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        groupBy: data => data.inventory_zone?.Name ?? 'منطقه بندی نشده',
        groupHeader: (value, count) =>
            `<div class="inline mr-2">${value}
                  <span class="mr-1 !text-red-500">(${count} عدد)</span>
            </div>`,
        columns: [
          {
            formatter: "rowSelection",
            titleFormatter: "rowSelection",
            headerSort: false
          },
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'InvoiceId',
            headerSort: false,
            formatter: cell => cell.getData().InvoiceReturnId ?? cell.getData().InvoiceId
          },
          {
            title: 'نوع',
            field: 'type',
            headerSort: false,
            formatter: cell => {
              let isInvoiceReturn = !!cell.getData().InvoiceReturnId
              return `<div class="badge ${isInvoiceReturn ? 'bg-red-500' : 'bg-green-500'}">
                         <span>${isInvoiceReturn ? 'مرجوعی' : 'سفارش'}</span>
                      </div>`
            }
          },
          {
            title: 'شماره مشتری',
            field: 'user.Mobile',
            headerSort: false,
          },
          {
            title: 'حجم سفارش',
            field: 'BasketCount',
            headerSort: false,
            formatter: cell => (cell.getData().BasketCount ?? 0) + ' سبد'
          },
          {
            title: 'آدرس',
            field: 'address.FullAddress',
            headerSort: false,
            formatter: cell => `<span class="whitespace-pre-wrap">${cell.getData().Address.FullAddress}</span>`
          },
          {
            title: 'عملیات',
            field: 'InvoiceId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-600 cursor-pointer" data-type="${cell.getData().InvoiceReturnId ? 'InvoiceReturn' : 'Invoice'}"
                             data-btn-type="show" data-id="${cell.getData().InvoiceReturnId ?? cell.getData().InvoiceId}">
                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-700">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                             </svg>
                             <span class="mr-1 text-xs">نمایش</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    'filter.deliveryDateTimeId'() {
      this.getInvoices()
      this.$router.replace({
        name: this.$route.name,
        query: this.filter
      })
    },
  }
}
</script>

<style scoped>

</style>
import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import LoginPage from "@/views/LoginPage";
import homeView from "@/views/HomeView";
import NotFound from "@/views/NotFound.vue";

import IndexPage from "@/views/Layout/IndexPage";

import InventoryPackerInvoiceList from "@/views/InventoryPacker/InvoiceList";
import InventoryPackerInvoiceDetail from "@/views/InventoryPacker/InvoiceDetail";

import InventoryControllerInvoiceList from "@/views/InventoryController/InvoiceList";
import InventoryControllerInvoiceDetail from "@/views/InventoryController/InvoiceDetail";

import DistributorIndexPage from "@/views/Distributor/IndexPage";
import DistributorInvoiceListInventory from "@/views/Distributor/InvoiceListInventory";
import DistributorInvoiceListDelivery from "@/views/Distributor/InvoiceListDelivery";
import DistributorInvoiceReturnList from "@/views/Distributor/InvoiceReturnList";
import DistributorInvoiceDetail from "@/views/Distributor/InvoiceDetail";
import DistributorInvoiceReturnDetail from "@/views/Distributor/InvoiceReturnDetail";

import AdminProductPage from "@/views/Admin/ProductPage";
import AdminUserPage from "@/views/Admin/UserPage";
import AdminBrandPage from "@/views/Admin/BrandPage";
import AdminSpecPage from "@/views/Admin/SpecPage";
import AdminSpecDetail from "@/views/Admin/SpecDetail.vue";
import AdminInventoryPage from "@/views/Admin/InventoryPage";
import AdminInventoryDetail from "@/views/Admin/InventoryDetail";
import AdminCategoryPage from "@/views/Admin/CategoryPage";
import AdminFAQPage from "@/views/Admin/FAQPage.vue";
import AdminProductDetail from "@/views/Admin/ProductDetail.vue";

import SupporterComplaintPage from "@/views/Supporter/ComplaintPage";
import SupporterComplaintDetail from "@/views/Supporter/ComplaintDetail";
import SupporterInvoiceList from "@/views/Supporter/InvoiceList";
import SupporterInvoiceDetail from "@/views/Supporter/InvoiceDetail";
import SupporterInvoiceReturnList from "@/views/Supporter/InvoiceReturnList";
import SupporterInvoiceReturnDetail from "@/views/Supporter/InvoiceReturnDetail";
import SupporterInvoiceCommentList from "@/views/Supporter/CommentList";

import InventoryManagerUserList from "@/views/InventoryManager/UserList";
import InventoryManagerStockList from "@/views/InventoryManager/StockList"
import InventoryManagerInvoiceList from "@/views/InventoryManager/InvoiceList";
import InventoryManagerInvoiceDetail from "@/views/InventoryManager/InvoiceDetail";
import InventoryManagerInvoiceReturnList from "@/views/InventoryManager/InvoiceReturnList.vue";
import InventoryManagerInvoiceReturnDetail from "@/views/InventoryManager/InvoiceReturnDetail.vue";
import InventoryManagerCampaignList from "@/views/InventoryManager/CampaignPage";
import InventoryManagerCampaignDetail from "@/views/InventoryManager/CampaignDetail.vue";
import InventoryManagerDocumentList from "@/views/InventoryManager/DocumentPage";
import InventoryManagerDocumentDetail from "@/views/InventoryManager/DocumentDetail.vue";
import InventoryManagerDefectList from "@/views/InventoryManager/DefectList";
import InventoryManagerReportPage from "@/views/InventoryManager/ReportPage";
import InventoryManagerHolidayCalendar from "@/views/InventoryManager/HolidayCalendar.vue";
import InventoryManagerDeliveryDatetimeList from "@/views/DistributorManager/DeliveryDatetimeList.vue";
import InventoryManagerPriceList from "@/views/InventoryManager/PriceList.vue";
import InventoryManagerPriceDetail from "@/views/InventoryManager/PriceDetail.vue";

import DistributorManagerUserList from "@/views/DistributorManager/UserList";
import DistributorManagerDeliveryList from "@/views/DistributorManager/DeliveryList"
import DistributorAssignmentList from "@/views/DistributorManager/DistributorAssignmentList.vue"
import DistributorAssignmentDetail from "@/views/DistributorManager/DistributorAssignmentDetail.vue"
import DistributorManagerInvoiceReturnDetail from "@/views/DistributorManager/InvoiceReturnDetail"
import DistributorManagerDistributorMap from "@/views/DistributorManager/DistributorMap"
import DistributorManagerVehicleList from "@/views/DistributorManager/VehicleList";
import DistributorManagerInvoiceDetail from "@/views/DistributorManager/InvoiceDetail";
import ShiftList from "@/views/DistributorManager/ShiftList.vue";
import ShiftDetail from "@/views/DistributorManager/ShiftDetail.vue";

import InventoryStaffInvoiceReturnList from "@/views/InventoryStaff/InvoiceReturnList"
import InventoryStaffInvoiceReturnDetail from "@/views/InventoryStaff/InvoiceReturnDetail"

import InventoryCarrierInvoiceReturnList from "@/views/InventoryCarrier/InvoiceReturnList"
import InventoryCarrierInvoiceReturnDetail from "@/views/InventoryCarrier/InvoiceReturnDetail"
import InventoryCarrierInvoiceList from "@/views/InventoryCarrier/InvoiceList"
import InventoryCarrierInvoiceDetail from "@/views/InventoryCarrier/InvoiceDetail"

import QCStaffInvoiceReturnList from "@/views/QCStaff/InvoiceReturnList"
import QCStaffInvoiceReturnDetail from "@/views/QCStaff/InvoiceReturnDetail"

import QCManagerStockList from "@/views/QCManager/StockList"
import RequestPage from "@/views/RequestPage";
import MessagePage from "@/views/MessagePage";

import FinancialManagerWalletRequest from "@/views/FinancialManager/WalletRequest.vue";

const routes = [
    {
        path: '/',
        name: 'homeView',
        component: homeView
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/request',
        name: 'RequestPage',
        component: RequestPage,
        meta: {
            code: 103130200,
            title: 'درخواست ها'
        }
    },
    {
        path: '/message',
        name: 'MessagePage',
        component: MessagePage,
        meta: {
            code: 103130100,
            title: 'پیام ها'
        }
    },
    {
        path: '/admin',
        name: 'AdminIndexPage',
        component: IndexPage,
        meta: {
            role: 'admin',
            code: 103010000,
            title: 'ادمین'
        },
        children: [
            {
                path: 'user',
                name: 'AdminUserPage',
                component: AdminUserPage,
                meta: {
                    code: 103010100,
                    title: 'مدیریت کاربران'
                },
            },
            {
                path: 'inventory',
                name: 'AdminInventoryPage',
                component: AdminInventoryPage,
                meta: {
                    code: 103010200,
                    title: 'مدیریت انبار'
                },
            },
            {
                path: 'inventory/:id',
                name: 'AdminInventoryDetail',
                component: AdminInventoryDetail,
                meta: {
                    code: 103010200,
                    title: (route) => 'جزئیات انبار ' + route.params.id,
                    parent: 'AdminInventoryPage'
                },
            },
            {
                path: 'category',
                name: 'AdminCategoryPage',
                component: AdminCategoryPage,
                meta: {
                    code: 103010300,
                    title: 'مدیریت دسته بندی'
                },
            },
            {
                path: 'product',
                name: 'AdminProductPage',
                component: AdminProductPage,
                meta: {
                    code: 103010400,
                    title: 'مدیریت محصولات'
                },
            },
            {
                path: 'product/:id',
                name: 'AdminProductDetail',
                component: AdminProductDetail,
                meta: {
                    code: 103010400,
                    title: (route) => 'کالا های مکمل کالا ' + route.params.id,
                    parent: 'AdminProductPage'
                },
            },
            {
                path: 'brand',
                name: 'AdminBrandPage',
                component: AdminBrandPage,
                meta: {
                    code: 103010500,
                    title: 'مدیریت برند ها'
                },
            },
            {
                path: 'spec',
                name: 'AdminSpecPage',
                component: AdminSpecPage,
                meta: {
                    code: 103010600,
                    title: 'مدیریت مشخصات کالا'
                },
            },
            {
                path: 'spec/:id',
                name: 'AdminSpecDetail',
                component: AdminSpecDetail,
                meta: {
                    code: 103010600,
                    title: 'جزئیات مشخصات کالا'
                },
            },
            {
                path: 'FAQ',
                name: 'AdminFAQPage',
                component: AdminFAQPage,
                meta: {
                    code: 103010700,
                    title: 'مدیریت سوالات متداول'
                },
            },
        ]
    },
    {
        path: '/inventory-manager',
        name: 'InventoryManagerIndexPage',
        meta: {
            role: 'inventory-manager',
            code: 103020000,
            title: 'مدیر انبار'
        },
        component: IndexPage,
        children: [
            {
                path: 'user',
                name: 'InventoryManagerUserList',
                component: InventoryManagerUserList,
                meta: {
                    code: 103020100,
                    title: 'مدیریت کاربران'
                },
            },
            {
                path: 'invoice',
                name: 'InventoryManagerInvoiceList',
                component: InventoryManagerInvoiceList,
                meta: {
                    code: 103020200,
                    title: 'مدیریت سفارشات'
                },
            },
            {
                path: 'invoice/:id',
                name: 'InventoryManagerInvoiceDetail',
                component: InventoryManagerInvoiceDetail,
                meta: {
                    code: 103020200,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'InventoryManagerInvoiceList'
                },
            },
            {
                path: 'invoice-return',
                name: 'InventoryManagerInvoiceReturnList',
                component: InventoryManagerInvoiceReturnList,
                meta: {
                    code: 103020250,
                    title: 'مدیریت سفارشات'
                },
            },
            {
                path: 'invoice-return/:id',
                name: 'InventoryManagerInvoiceReturnDetail',
                component: InventoryManagerInvoiceReturnDetail,
                meta: {
                    code: 103020250,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'InventoryManagerInvoiceReturnList'
                },
            },
            {
                path: 'stock',
                name: 'InventoryManagerStockList',
                component: InventoryManagerStockList,
                meta: {
                    code: 103020300,
                    title: 'مدیریت پالت ها'
                },
            },
            {
                path: 'campaign',
                name: 'InventoryManagerCampaignList',
                component: InventoryManagerCampaignList,
                meta: {
                    code: 103020400,
                    title: 'مدیریت کمپین ها'
                },
            },
            {
                path: 'campaign/:id',
                name: 'InventoryManagerCampaignDetail',
                component: InventoryManagerCampaignDetail,
                meta: {
                    code: 103020400,
                    title: (route) => 'کالا های کمپین ' + route.params.id,
                    parent: 'InventoryManagerCampaignList'
                },
            },
            {
                path: 'document',
                name: 'InventoryManagerDocumentList',
                component: InventoryManagerDocumentList,
                meta: {
                    code: 103020500,
                    title: 'حواله ها'
                },
            },
            {
                path: 'document/:id',
                name: 'InventoryManagerDocumentDetail',
                component: InventoryManagerDocumentDetail,
                meta: {
                    code: 103020500,
                    title: (route) => 'جزئیات حواله ' + route.params.id,
                    parent: 'InventoryManagerDocumentList'
                },
            },
            {
                path: 'stock-defect',
                name: 'InventoryManagerDefectList',
                component: InventoryManagerDefectList,
                meta: {
                    code: 103020600,
                    title: 'انبار ضایعات'
                },
            },
            {
                path: 'report',
                name: 'InventoryManagerReportPage',
                component: InventoryManagerReportPage,
                meta: {
                    code: 103020700,
                    title: 'گزارش مرور'
                },
            },
            {
                path: 'holiday-calendar',
                name: 'InventoryManagerHolidayCalendar',
                component: InventoryManagerHolidayCalendar,
                meta: {
                    code: 103020800,
                    title: 'تقویم روزهای تعطیل'
                },
            },
            {
                path: 'price-list',
                name: 'InventoryManagerPriceList',
                component: InventoryManagerPriceList,
                meta: {
                    code: 103020900,
                    title: 'اعلامیه قیمت'
                },
            },
            {
                path: 'price-list/:id',
                name: 'InventoryManagerPriceDetail',
                component: InventoryManagerPriceDetail,
                meta: {
                    code: 103020900,
                    title: 'اعلامیه قیمت'
                },
            },
        ]
    },
    {
        path: '/QC-manager',
        name: 'QCManagerIndexPage',
        component: IndexPage,
        meta: {
            role: 'QC-manager',
            code: 103030000,
            title: 'مدیر کیفی'
        },
        children: [
            {
                path: 'stock',
                name: 'QCManagerStockList',
                component: QCManagerStockList,
                meta: {
                    code: 103030100,
                    title: 'مدیریت پالت ها'
                },
            },
        ]
    },
    {
        path: '/distributor-manager',
        name: 'DistributorManagerDashboard',
        component: IndexPage,
        meta: {
            role: 'distributor-manager',
            code: 103040000,
            title: 'مدیر توزیع'
        },
        children: [
            {
                path: 'vehicle',
                name: 'DistributorManagerVehicleList',
                component: DistributorManagerVehicleList,
                meta: {
                    code: 103040100,
                    title: 'مدیریت ماشین ها'
                },
            },
            {
                path: 'user',
                name: 'DistributorManagerUserList',
                component: DistributorManagerUserList,
                meta: {
                    code: 103040200,
                    title: 'مدیریت موزعین'
                },
            },
            {
                path: 'shift',
                name: 'ShiftList',
                component: ShiftList,
                meta: {
                    code: 103040300,
                    title: 'مدیریت شیفت ها'
                },
            },
            {
                path: 'shift/:id',
                name: 'ShiftDetail',
                component: ShiftDetail,
                meta: {
                    code: 103040300,
                    title: (route) => 'جزئیات شیفت ' + route.params.id,
                    parent: 'ShiftList'
                }
            },
            {
                path: 'delivery',
                name: 'DistributorManagerDeliveryList',
                component: DistributorManagerDeliveryList,
                meta: {
                    code: 103040400,
                    title: 'تخصیص به موزعین'
                },
            },
            {
                path: 'distributor-vehicle',
                name: 'DistributorAssignmentList',
                component: DistributorAssignmentList,
                meta: {
                    code: 103040500,
                    title: 'مدیریت تخصیص ها'
                },
            },
            {
                path: 'distributor-vehicle/:id',
                name: 'DistributorAssignmentDetail',
                component: DistributorAssignmentDetail,
                meta: {
                    code: 103040500,
                    title: (route) => 'جزئیات تخصیص ' + route.params.id,
                    parent: 'DistributorAssignmentList'
                },
            },
            {
                path: 'distributor/map',
                name: 'DistributorManagerDistributorMap',
                component: DistributorManagerDistributorMap,
                meta: {
                    code: 103040600,
                    title: 'نقشه موزعین'
                },
            },
            {
                path: 'invoice/:id',
                name: 'DistributorManagerInvoiceDetail',
                component: DistributorManagerInvoiceDetail,
                meta: {
                    code: 103040400,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'DistributorManagerDeliveryList'
                },
            },
            {
                path: 'invoice-return/:id',
                name: 'DistributorManagerInvoiceReturnDetail',
                component: DistributorManagerInvoiceReturnDetail,
                meta: {
                    code: 103040400,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'DistributorManagerDeliveryList'
                },
            },
            {
                path: 'delivery-datetime',
                name: 'InventoryManagerDeliveryDatetimeList',
                component: InventoryManagerDeliveryDatetimeList,
                meta: {
                    code: 103040700,
                    title: 'مدیریت بازه های زمانی'
                },
            },
        ]
    },
    {
        path: '/inventory-packer',
        name: 'InventoryPackerIndexPage',
        component: IndexPage,
        meta: {
            role: 'inventory-packer',
            code: 103050000,
            title: 'سفارش ساز'
        },
        children: [
            {
                path: 'invoice',
                name: 'InventoryPackerInvoiceList',
                component: InventoryPackerInvoiceList,
                meta: {
                    code: 103050100,
                    title: 'لیست سفارشات'
                },
            },
            {
                path: 'invoice/:id',
                name: 'InventoryPackerInvoiceDetail',
                component: InventoryPackerInvoiceDetail,
                meta: {
                    code: 103050100,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'InventoryPackerInvoiceList'
                },
            },
        ]
    },
    {
        path: '/inventory-controller',
        name: 'InventoryControllerIndexPage',
        component: IndexPage,
        meta: {
            role: 'inventory-controller',
            code: 103060000,
            title: 'کنترلر'
        },
        children: [
            {
                path: 'invoice',
                name: 'InventoryControllerInvoiceList',
                component: InventoryControllerInvoiceList,
                meta: {
                    code: 103060100,
                    title: 'لیست سفارشات'
                },
            },
            {
                path: 'invoice/:id',
                name: 'InventoryControllerInvoiceDetail',
                component: InventoryControllerInvoiceDetail,
                meta: {
                    code: 103060100,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'InventoryControllerInvoiceList'
                },
            },
        ]
    },
    {
        path: '/distributor',
        name: 'DistributorIndexPage',
        component: DistributorIndexPage,
        meta: {
            role: 'distributor',
            code: 103070000,
            title: 'موزع'
        },
        children: [
            {
                path: '/distributor/invoice/inventory',
                name: 'DistributorInvoiceListInventory',
                component: DistributorInvoiceListInventory,
                meta: {
                    code: 103070100,
                    title: 'دریافت از انبار'
                },
            },
            {
                path: '/distributor/invoice/delivery',
                name: 'DistributorInvoiceListDelivery',
                component: DistributorInvoiceListDelivery,
                meta: {
                    code: 103070200,
                    title: 'تحویل به مشتری'
                },
            },
            {
                path: '/distributor/invoice-return',
                name: 'DistributorInvoiceReturnList',
                component: DistributorInvoiceReturnList,
                meta: {
                    code: 103070300,
                    title: 'مرجوعی ها'
                },
            },
            {
                path: '/distributor/invoice/:id',
                name: 'DistributorInvoiceDetail',
                component: DistributorInvoiceDetail,
                meta: {
                    code: 103070100,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'DistributorInvoiceListDelivery'
                },
            },
            {
                path: '/distributor/invoice-return/:id',
                name: 'DistributorInvoiceReturnDetail',
                component: DistributorInvoiceReturnDetail,
                meta: {
                    code: 103070300,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'DistributorInvoiceReturnDetail'
                },
            },
        ]
    },
    {
        path: '/supporter',
        name: 'SupporterIndexPage',
        component: IndexPage,
        meta: {
            role: 'supporter',
            code: 103080000,
            title: 'پشتیبان'
        },
        children: [
            {
                path: 'invoice/all',
                name: 'SupporterInvoiceListAll',
                component: SupporterInvoiceList,
                meta: {
                    code: 103080100,
                    title: 'لیست سفارشات'
                },
            },
            {
                path: 'invoice/pending',
                name: 'SupporterInvoiceListPending',
                component: SupporterInvoiceList,
                meta: {
                    code: 103080200,
                    title: 'لیست سفارشات در انتظار'
                },
            },
            {
                path: 'invoice/:id',
                name: 'SupporterInvoiceDetail',
                component: SupporterInvoiceDetail,
                meta: {
                    code: 103080100,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'SupporterInvoiceListAll'
                },
            },
            {
                path: 'complaint',
                name: 'SupporterComplaintPage',
                component: SupporterComplaintPage,
                meta: {
                    code: 103080300,
                    title: 'لیست پشتیبانی ها'
                },
            },
            {
                path: 'complaint/:id',
                name: 'SupporterComplaintDetail',
                component: SupporterComplaintDetail,
                title: (route) => 'جزئیات پشتیبانی ' + route.params.id,
                parent: 'SupporterComplaintPage'
            },
            {
                path: 'invoice-return/all',
                name: 'SupporterInvoiceReturnListAll',
                component: SupporterInvoiceReturnList,
                meta: {
                    code: 103080400,
                    title: 'لیست مرجوعی ها'
                },
            },
            {
                path: 'invoice-return/pending',
                name: 'SupporterInvoiceReturnListPending',
                component: SupporterInvoiceReturnList,
                meta: {
                    code: 103080500,
                    title: 'لیست مرجوعی های در انتظار'
                },
            },
            {
                path: 'invoice-return/:id',
                name: 'SupporterInvoiceReturnDetail',
                component: SupporterInvoiceReturnDetail,
                meta: {
                    code: 103080400,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'SupporterInvoiceReturnListAll'
                },
            },
            {
                path: 'comment',
                name: 'SupporterInvoiceCommentList',
                component: SupporterInvoiceCommentList,
                meta: {
                    code: 103080600,
                    title: 'لیست نظرات'
                },
            },
        ]
    },
    {
        path: '/inventory-staff',
        name: 'InventoryStaffIndexPage',
        component: IndexPage,
        meta: {
            role: 'inventory-staff',
            code: 103090000,
            title: 'انباردار'
        },
        children: [
            {
                path: 'invoice-return',
                name: 'InventoryStaffInvoiceReturnList',
                component: InventoryStaffInvoiceReturnList,
                meta: {
                    code: 103090100,
                    title: 'لیست مرجوعی ها'
                },
            },
            {
                path: 'invoice-return/:id',
                name: 'InventoryStaffInvoiceReturnDetail',
                component: InventoryStaffInvoiceReturnDetail,
                meta: {
                    code: 103090100,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'InventoryStaffInvoiceReturnList'
                },
            },
        ]
    },
    {
        path: '/inventory-carrier',
        name: 'InventoryCarrierIndexPage',
        component: IndexPage,
        meta: {
            role: 'inventory-carrier',
            code: 103100000,
            title: 'مسئول ترابری'
        },
        children: [
            {
                path: 'invoice-return',
                name: 'InventoryCarrierInvoiceReturnList',
                component: InventoryCarrierInvoiceReturnList,
                meta: {
                    code: 103100100,
                    title: 'لیست مرجوعی ها'
                },
            },
            {
                path: 'invoice-return/:id',
                name: 'InventoryCarrierInvoiceReturnDetail',
                component: InventoryCarrierInvoiceReturnDetail,
                meta: {
                    code: 103100100,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'InventoryCarrierInvoiceReturnList'
                },
            },
            {
                path: 'invoice',
                name: 'InventoryCarrierInvoiceList',
                component: InventoryCarrierInvoiceList,
                meta: {
                    code: 103100200,
                    title: 'لیست سفارشات'
                },
            },
            {
                path: 'invoice/:id',
                name: 'InventoryCarrierInvoiceDetail',
                component: InventoryCarrierInvoiceDetail,
                meta: {
                    code: 103100200,
                    title: (route) => 'جزئیات سفارش ' + route.params.id,
                    parent: 'InventoryCarrierInvoiceDetail'
                },
            },
        ]
    },
    {
        path: '/QC-staff',
        name: 'QCStaffIndexPage',
        component: IndexPage,
        meta: {
            role: 'QC-staff',
            code: 103110000,
            title: 'بازرس کیفی'
        },
        children: [
            {
                path: '/QC-staff/invoice-return',
                name: 'QCStaffInvoiceReturnList',
                component: QCStaffInvoiceReturnList,
                meta: {
                    code: 103110100,
                    title: 'لیست مرجوعی ها'
                },
            },
            {
                path: '/QC-staff/invoice-return/:id',
                name: 'QCStaffInvoiceReturnDetail',
                component: QCStaffInvoiceReturnDetail,
                meta: {
                    code: 103110100,
                    title: (route) => 'جزئیات مرجوعی ' + route.params.id,
                    parent: 'QCStaffInvoiceReturnList'
                },
            },
        ]
    },
    {
        path: '/financial-manager',
        name: 'FinancialManagerIndexPage',
        component: IndexPage,
        meta: {
            role: 'financial-manager',
            code: 103120000,
            title: 'مدیر مالی'
        },
        children: [
            {
                path: 'wallet-request',
                name: 'FinancialManagerWalletRequest',
                component: FinancialManagerWalletRequest,
                meta: {
                    code: 103120100,
                    title: 'درخواست تسویه کیف پول'
                },
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.getElementById('main')?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })

    if (to.name === 'LoginPage') {
        if (store.getters.isLoggedIn && store.getters.selectedInventory)
            next('/')
        else
            next()
    } else if (!store.getters.isLoggedIn || !store.getters.selectedInventory) {
        next('/login')
    } else {
        next()
    }
})

export default router

<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">تقویم روز های تعطیل</h1>

    <div class="flex gap-x-2">
      <VButton class="!w-auto shrink-0 intro-y order-1 lg:order-2"
               @click="createHolidayModal = true; selectedHoliday = {}">
        ایجاد روز
      </VButton>
    </div>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="holidayCalendar.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateHolidayCalendarModal
      v-model="createHolidayModal"
      @submitted="created"/>

  <EditDeliveryDateTimeModal
      v-model="deactivateModal"
      title="غیرفعال روز"
      :delivery-datetime-id="selectedHoliday.HolidayCalendarId"
      @submit="deactivated"
  />

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف روز تعطیل"
      text="آیا از حذف این روز تعطیل مطمئن هستید ؟"
      @confirm="deleteHolidayCalendar"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VButton from "@/components/General/VButton.vue";
import VCard from "@/components/General/VCard.vue";
import VPagination from "@hennge/vue3-pagination";
import {deleteHolidayCalendar, getHolidayCalendar} from "@/API/API.js";
import {mapGetters} from "vuex";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import CreateHolidayCalendarModal from "@/components/Modal/InventoryManager/CreateHolidayCalendarModal.vue";
import EditDeliveryDateTimeModal from "@/components/Modal/InventoryManager/editDeliveryDateTimeModal.vue";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "HolidayCalendar",
  components: {
    VInput,
    ConfirmModal,
    EditDeliveryDateTimeModal,
    CreateHolidayCalendarModal,
    VCard,
    VButton,
    VPagination,
  },
  data() {
    return {
      holidayCalendar: [],
      createHolidayModal: false,
      confirmDeleteModal: false,
      deactivateModal: false,
      selectedHoliday: {},
      showFilter: true,

      newHoliday: {
        date: '',
        type: '',
        inventory: '',
      },

      filter: {
        page: 1,
        q: '',
        selectedInventory: '',
      }
    }
  },
  computed: {
    ...mapGetters(['selectedInventory']),
  },
  async created() {
    this.filter.selectedInventory = this.selectedInventory
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Delete Buttons
      let deleteButton = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButton.forEach(element => {
        element.onclick = () => {
          this.selectedHoliday = this.holidayCalendar.HolidayCalendars.find(holidayCalendar => holidayCalendar.HolidayCalendarId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
    });
  },
  methods: {
    async getCalendar() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.holidayCalendar = (await getHolidayCalendar(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    deactivated() {
      this.selectedHoliday = ''
      this.deactivateModal = false
    },
    refreshTable() {
      this.tabulator.setData(this.holidayCalendar.HolidayCalendars)
      window.scrollTo(0, 0)
    },
    created(calendar) {
      this.createHolidayModal = false;
      this.holidayCalendar.HolidayCalendars.unshift(calendar)
      this.refreshTable()
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'تاریخ',
            field: 'HolidayDateFa',
          },
          {
            title: 'بازه های زمانی باز',
            field: 'OpenTimeSlice',
            formatter: cell => {
              if (!cell.getValue()?.length)
                return '-'

              let template = '<div class="flex flex-wrap gap-2">'

              cell.getValue().forEach(timeSLice => template += `<span class="border rounded px-2">${timeSLice.Description}</span>`)

              return template + '</div>'
            }
          },
          {
            title: 'عملیات',
            field: 'HolidayCalendarId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              if (new Date(cell.getData().HolidayDate) < new Date(Date.now() + 24 * 60 * 60 * 1000)) // 1 day
                return '<span class="text-xs text-gray-500">غیرقابل حذف</span>'

              return `<div class="flex">
                        <div class="flex items-center text-red-500 cursor-pointer mr-2" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    },
    deleteHolidayCalendar() {
      deleteHolidayCalendar(this.selectedHoliday.HolidayCalendarId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.confirmDeleteModal = false

            let index = this.holidayCalendar.HolidayCalendars.findIndex(item => item.HolidayCalendarId === this.selectedHoliday.HolidayCalendarId)
            this.holidayCalendar.HolidayCalendars.splice(index, 1)
            this.refreshTable()
          })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getCalendar()
      }
    },
    selectedInventory() {
      this.filter.selectedInventory = this.selectedInventory
    }
  }

}
</script>
<template>
  <ModalView
      :title="editMode ? 'ویرایش سوال' : 'ایجاد سوال'" @opened="getFAQCategories">

    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="FAQ.CategoryLId"
          :options="categories"
          :searchable="true"
          placeholder="دسته بندی اصلی"
          required/>

      <VInput type="text" placeholder="سوال" v-model="FAQ.Question" class="mt-4" required/>
      <VInput type="text" placeholder="جواب" v-model="FAQ.Answer" class="mt-4" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createFAQ, updateFAQ} from "@/API/AdminAPI";
import Multiselect from "@vueform/multiselect";
import {getLookupCombos} from "@/API/API.js";

const FAQModel = {
  Question: '',
  Answer: '',
  CategoryLId: '',
}

export default {
  name: "CreateFAQModal",
  emits: ['created', 'updated'],
  props: ['editFAQ'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      editMode: false,
      categories: [],
      FAQ: {...FAQModel}
    }
  },
  methods: {
    async getFAQCategories() {
      if (this.categories.length === 0)
        this.categories = (await getLookupCombos(103010700)).data.data.Lookups.Lookups11.map(category => ({
          label: category.Name,
          value: category.LookupId
        }))
    },
    create() {
      createFAQ(this.FAQ)
          .then(resp => {
            this.$infoModal.value = resp.data.messsage
            this.$emit('created', resp.data.data)
            this.FAQ = {...FAQModel}
          })
    },
    update() {
      updateFAQ(this.editFAQ.FAQId, this.FAQ)
          .then(resp => {
            this.$infoModal.value = resp.data.messsage
            this.$emit('updated', resp.data.data)
          })
    }
  },
  watch: {
    editFAQ(editFAQ) {
      if (editFAQ.FAQId) {
        this.editMode = true
        this.FAQ = this.$helper.cloneObject(editFAQ, Object.keys(FAQModel))
        this.FAQ.CategoryLId = editFAQ.Category.LookupId
      } else {
        this.editMode = false
        this.FAQ = {...FAQModel}
      }
    },
  }
}
</script>

<style scoped>

</style>
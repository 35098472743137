<template>
  <select class="w-full border border-primary rounded-lg p-2 focus:outline-primary"
          @input="onInput"
          @invalid="onInvalid"
          :value="modelValue">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: "VSelect",
  props: ['modelValue'],
  methods: {
    onInput(e) {
      e.target.setCustomValidity('')

      this.$emit('update:modelValue', e.target.value);
    },
    onInvalid(e) {
      let input = e.target
      console.log(input)
      if (input.validity.valueMissing)
        input.setCustomValidity('لطفا این فیلد را کامل کنید.');
      else
        input.setCustomValidity('')

      return true;
    }
  }
}
</script>

<style scoped>

</style>
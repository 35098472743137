<template>
  <div class="intro-y flex items-center">
    <img :src="$helper.imageURL(campaign.Campaign?.ImagePath)" alt="" class="w-14 h-14">
    <h1 class="mr-2">{{ campaign.Campaign?.Name }}</h1>

    <VButton class="!w-auto mr-auto" @click="addProductModal = true">افزودن کالا</VButton>
  </div>

  <hr>
  <h1 class="mt-4 text-lg">لیست کالا ها</h1>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="campaign.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding>
    <div ref="table"></div>
  </VCard>

  <ConfirmModal
      v-model="confirmRemoveProductModal"
      title="حذف کالا از کمپین"
      text="از حذف کالا مطمئن هستید ؟"
      @confirm="removeProduct"/>

  <CampaignProductModal
      v-model="addProductModal"
      :campaign="campaign.Campaign"
      @added="productAdded"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import "@/assets/css/pagination.css";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VButton from "@/components/General/VButton.vue";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";
import VPagination from "@hennge/vue3-pagination";
import {getCampaign, removeProductFromCampaign} from "@/API/API.js";
import CampaignProductModal from "@/components/Modal/InventoryManager/CampaignProductModal.vue";
import VInput from "@/components/General/VInput.vue";


export default {
  name: "CampaignDetail",
  components: {
    VInput,
    CampaignProductModal,
    ConfirmModal,
    VButton,
    VCard,
    VPagination
  },
  data() {
    return {
      campaign: {},

      addProductModal: false,
      confirmRemoveProductModal: false,
      deleteProductId: '',

      filter: {
        page: 1,
        q: ''
      }
    }
  },
  created() {
    this.getProducts()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => element.onclick = () => {
            this.deleteProductId = element.dataset.id
            this.confirmRemoveProductModal = true
          }
      )
    });
  },
  methods: {
    async getProducts() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.campaign = (await getCampaign(this.$route.params.id, this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    removeProduct() {
      removeProductFromCampaign(this.$route.params.id, this.deleteProductId)
          .then(resp => {
            this.confirmRemoveProductModal = false
            this.$infoModal.value = resp.data.message
            let index = this.campaign.Products.findIndex(item => item.ProductId === this.deleteProductId)
            this.campaign.Products.splice(index, 1)
            this.refreshTable()
          })
    },
    productAdded(campaign) {
      this.addProductModal = false
      this.campaign = campaign
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.campaign.Products)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,

        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'ProductId'
          },
          {
            title: 'نام', field: 'Name', formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Image)}" alt="" class="${cell.getData().Image ? 'w-14 h-14' : ''}">
                        <span class="whitespace-pre-wrap normal-digits ${cell.getData().Image ? 'mr-2' : ''}">${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'عملیات',
            field: 'ProductId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-red-500 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getProducts()
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView :title="editMode ? 'ویرایش انبار' : 'ایجاد انبار'" @opened="initMap">

    <form @submit.prevent="submit" class="mt-4">

      <div id="map" class="z-0 h-[200px] rounded-md"></div>

      <div class="flex mt-4">
        <VInput type="text" placeholder="نام" v-model="inventory.Name" required/>

        <Multiselect
            dir="rtl"
            class="mr-4 border border-primary"
            v-model="inventory.CityId"
            :options="cities"
            :searchable="true"
            placeholder="انتخاب شهر"
            v-if="!editMode"
            :required="!editMode"/>

      </div>

      <div class="flex mt-4">
        <VInput type="number" placeholder="کد انبار" v-model="inventory.InventoryId" required v-if="!editMode"/>
        <VInput type="number" placeholder="کد ضایعات" v-model="inventory.DefectCode" class="mr-4" required
                v-if="!editMode"/>
      </div>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createInventory, updateInventory} from "@/API/AdminAPI";
import L from "leaflet";
import Multiselect from "@vueform/multiselect";
import {getLookupCombos} from "@/API/API.js";

const inventoryModel = {
  Name: '',
  InventoryId: '',
  DefectCode: '',
  CityId: '',
  Lat: '',
  Long: '',
}

export default {
  name: "CreateInventoryModal",
  emits: ['created', 'updated'],
  props: ['editInventory'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      editMode: false,
      cities: [],
      inventory: {...inventoryModel},
    }
  },
  methods: {
    async getCities() {
      this.cities = (await getLookupCombos(103010200)).data.data.Cities.map(city => ({
        label: city.Name,
        value: city.CityId
      }))
    },
    submit() {
      if (this.editMode)
        updateInventory(this.editInventory.InventoryId, this.inventory)
            .then(resp => {
              this.$emit('updated', resp.data.data)
              this.inventory = {...inventoryModel}
            })
      else {
        if (!this.inventory.Lat) {
          this.$errorModal.value = 'لطفا موقعیت انبار را از روی نقشه انتخاب کنید.'
          return
        }
        createInventory(this.inventory)
            .then(resp => {
              this.$emit('created', resp.data.data)
              this.inventory = {...inventoryModel}
            })
      }
    },
    initMap() {
      this.getCities()
      setTimeout(() => {
        this.map = L.map('map', {
          center: [36.310699, 59.599457],
          zoom: 12,
          zoomControl: false,
          fullscreenControl: true,
        })
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
        // Create Marker Group to add markers to it
        this.markerGroup = L.layerGroup().addTo(this.map);

        if (this.editInventory.InventoryId)
          this.addMapMarker({lat: this.inventory.Lat, lng: this.inventory.Long}, this.markerGroup)

        this.map.on('click', async e => this.addMapMarker(e.latlng, this.markerGroup))
      }, 100)
    },
    addMapMarker(latlng, markerGroup) {
      // Get Location And store it in our Data state
      this.inventory.Lat = latlng.lat
      this.inventory.Long = latlng.lng

      // Delete All previous Markers
      markerGroup.clearLayers()

      let icon = L.icon({
        iconUrl: require('/src/assets/img/map_marker_building.png'),
        iconSize: [30, 30], // size of the icon
      });

      // Add Clicked Location Marker
      let marker = L.marker(latlng, {icon, draggable: true})
          .addTo(markerGroup)

      marker.on("drag", (e) => {
        this.inventory.Lat = e.latlng.lat
        this.inventory.Long = e.latlng.lng
      });

    },
  },
  watch: {
    editInventory(editInventory) {
      if (editInventory.InventoryId) {
        this.editMode = true
        console.log(this.editInventory)
        this.inventory = this.$helper.cloneObject(editInventory, Object.keys(inventoryModel))
        console.log(this.inventory)
      } else {
        this.editMode = false
        this.inventory = {...inventoryModel}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView
      :title="editMode ? 'ویرایش مشخصات' : 'ایجاد مشخصات'">

    <form @submit.prevent="editMode ? update() : create()">
      <VInput type="text" placeholder="نام مشخصات" class="mt-4" v-model="spec.Name" required/>

      <div class="flex items-center mt-4">
        <span class="ml-10">فعال</span>
        <VSwitch v-model="spec.IsActive" :value="spec.IsActive"/>
      </div>

      <div class="mt-4 flex justify-between">
        <!-- IsFilterable -->
        <div class="flex items-center">
          <span class="ml-4">قابل فیلتر</span>
          <VSwitch v-model="spec.IsFilterable" :value="spec.IsFilterable"/>
        </div>

        <!-- IsChoosable -->
        <div class="flex items-center">
          <span class="ml-4">قابل انتخاب</span>
          <VSwitch v-model="spec.IsChoosable" :value="spec.IsChoosable"/>
        </div>
      </div>

      <!-- Categories -->
      <div class="flex justify-between items-center my-4 pb-2 border-b">
        <h1>دسته بندی ها</h1>
        <VButton type="button" class="w-auto text-sm !py-1" @click="chooseCategoryModal = true" outline>افزودن</VButton>
      </div>
      <div class="flex flex-wrap gap-2">
        <VBadge v-for="(category, index) in spec.Categories"
                :key="category.CategoryId">
          {{ category.Name }}
          <XIcon class="w-4 inline cursor-pointer" @click="removeCategory(index)"/>
        </VBadge>
      </div>

      <!-- IsText -->
      <div class="mt-4 flex justify-between items-center">
        <span>نوع متنی</span>
        <VSwitch v-model="spec.IsText" :value="spec.IsText" @click.stop/>
      </div>

      <!-- Value -->
      <div v-if="!spec.IsText">
        <div class="flex justify-between items-center my-4 pb-2 border-b">
          <h1>مقادیر</h1>
          <VButton type="button" class="w-auto text-sm !py-1" @click="addValue" outline>افزودن</VButton>
        </div>
        <div class="flex items-center mt-4"
             v-for="(value, index) in spec.Values"
             :key="value">
          <VInput placeholder="مقدار" v-model="value.Name" required/>
          <button
              type="button"
              class="flex justify-center py-2 px-4 bg-red-500 text-white rounded-lg mr-2 text-sm"
              @click="removeValue(index)">
            حذف
          </button>
        </div>
      </div>

      <VButton type="submit" class="mt-4">ثبت</VButton>
    </form>

    <ChooseCategoryModal
        v-model="chooseCategoryModal"
        @selected="addCategoryToSpec"/>

  </ModalView>
</template>
<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VSwitch from "@/components/General/VSwitch";
import {createSpec, updateSpec} from "@/API/AdminAPI";
import VButton from "@/components/General/VButton";
import VBadge from "@/components/General/VBadge";
import {XIcon} from "@heroicons/vue/outline";
import ChooseCategoryModal from "@/components/Modal/Admin/ChooseCategoryModal";

export default {
  name: "CreateSpecModal",
  emits: ['created', 'updated'],
  props: ['editSpec'],
  components: {
    ChooseCategoryModal,
    VBadge,
    VButton,
    VSwitch,
    VInput,
    ModalView,
    XIcon
  },
  data() {
    return {
      editMode: false,
      spec: {
        IsText: 0,
        IsFilterable: 0,
        IsChoosable: 0,
        IsActive: 1,
        Categories: [],
        Values: [{
          Name: ''
        }],
      },

      errorModal: false,
      chooseCategoryModal: false,
    }
  },
  methods: {
    create() {
      this.spec.Categories = this.spec.Categories.map(category => category.CategoryId)
      createSpec(this.spec)
          .then(resp => {
            this.$emit('created', resp.data.data)
            this.spec = {
              IsText: 0,
              IsFilterable: 0,
              IsChoosable: 0,
              IsActive: 1,
              Categories: [],
              Values: [{
                Name: ''
              }],
            }
          })
    },
    update() {
      this.spec.Categories = this.spec.Categories.map(category => category.CategoryId)
      updateSpec(this.editSpec.SpecId, this.spec)
          .then(resp => this.$emit('updated', resp.data.data[0]))
    },
    addValue() {
      this.spec.Values.push({
        Name: "",
      });
    },
    addCategoryToSpec(selectedCategories) {
      this.chooseCategoryModal = false
      // Convert from multiselect to Category Object Format
      let Categories = selectedCategories
          .map(category => ({
            'Name': category.label,
            'CategoryId': category.value,
          }))
          .filter(category => !this.spec.Categories.some(item => item.CategoryId === category.CategoryId))

      this.spec.Categories.push(...Categories)
    },
    removeValue(index) {
      this.spec.Values.splice(index, 1);

    },
    removeCategory(index) {
      this.spec.Categories.splice(index, 1)
    }
  },
  watch: {
    editSpec: {
      deep: true,
      handler() {
        if (this.editSpec.SpecId) {
          this.editMode = true
          this.spec = JSON.parse(JSON.stringify(this.editSpec))
        } else {
          this.editMode = false
          this.errors = []
          this.spec = {
            IsText: 0,
            IsFilterable: 0,
            IsChoosable: 0,
            IsActive: 1,
            Categories: [],
            Values: [{
              Name: ''
            }],
          }
        }

      }
    },
  }
}
</script>

<style scoped>

</style>
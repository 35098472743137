<template>
  <ModalView title="درخواست جدید" @opened="getRequestTypes">

    <form @submit.prevent="submit">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="request.typeId"
          :options="multiselectRequestTypes"
          :searchable="true"
          @change="gerReceivers"
          placeholder="نوع درخواست"
          required/>

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="request.roleId"
          :options="multiselectReceivers"
          :searchable="true"
          @change="getRoleUsers"
          placeholder="نقش دریافت کننده"
          required/>

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="request.receiverId"
          :options="multiselectUsers"
          :searchable="true"
          placeholder="شخص دریافت کننده (اختیاری)"/>

      <VInput class="mt-4" placeholder="توضیحات" v-model="request.description" required/>

      <VButton class="mt-4" :loading="$loading.value">ثبت</VButton>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import {createRequest, getRequestTypes} from "@/API/API";
import Multiselect from "@vueform/multiselect";

export default {
  name: "CreateRequestModal",
  emits: ['submitted'],
  props: ['description'],
  components: {VInput, VButton, ModalView, Multiselect},
  data() {
    return {
      requestModal: false,
      requestTypes: [],

      multiselectRequestTypes: [],
      multiselectReceivers: [],
      multiselectUsers: [],

      request: {
        typeId: '',
        roleId: '',
        receiverId: '',
        description: '',
      },
    }
  },
  methods: {
    async getRequestTypes() {
      await this.$nextTick()
      this.requestTypes = (await getRequestTypes()).data.data
      this.multiselectRequestTypes = this.requestTypes.RequestTypes.map(type => ({
        label: type.Name,
        value: type.RequestTypeId
      }))
    },
    async gerReceivers() {
      await this.$nextTick()
      this.requestTypes = (await getRequestTypes({typeId: this.request.typeId})).data.data
      this.multiselectReceivers = this.requestTypes.Roles.map(receiver => ({
        label: receiver.Name,
        value: receiver.RoleId
      }))
    },
    async getRoleUsers() {
      await this.$nextTick()
      this.requestTypes = (await getRequestTypes({roleId: this.request.roleId})).data.data
      this.multiselectUsers = this.requestTypes.Users.map(receiver => ({
        label: receiver.Name,
        value: receiver.UserId
      }))
    },
    submit() {
      createRequest(this.request)
          .then(resp => {
            this.$emit('submitted')
            this.$infoModal.value = resp.data.message
          })
    }
  },
  watch: {
    description() {
      this.request.description = this.description;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <transition name="fade">
    <div class="overscroll-contain overflow-y-hidden fixed top-0 right-0 w-screen h-screen bg-black/80 z-20"
         @click="$sideMenu.value = false" v-if="$sideMenu.value">

      <transition name="slide" appear>
        <SideMenuItems @click.stop class="overflow-y-scroll overflow-x-hidden h-full p-2 w-[250px] bg-primary rounded-l-lg" mobile/>
      </transition>

    </div>
  </transition>
</template>

<script>
import SideMenuItems from "@/components/Layout/SideMenuItems";

export default {
  name: "MobileSideMenu",
  components: {SideMenuItems},
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg">لیست کالاها</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="products.PageCount"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {getSpecProducts} from "@/API/AdminAPI";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VInput from "@/components/General/VInput.vue";

export default {
  name: "SpecPage",
  components: {
    VInput,
    VCard,
    VPagination,
  },
  data() {
    return {
      products: [],

      filter: {
        q: '',
        page: 1,
      },
    }
  },
  created() {
    this.getProducts()
  },
  mounted() {
    this.initTable()
  },
  methods: {
    async getProducts() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.products = (await getSpecProducts(this.$route.params.id, this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.products.Products)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'نام',
            field: 'Name',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Images?.[0]?.Path)}" alt="" class="${cell.getData().Images?.[0]?.Path ? 'w-14 h-14' : ''}">
                        <span class="whitespace-pre-wrap normal-digits ${cell.getData().Images?.[0]?.Path ? 'mr-2' : ''}">${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'مقادیر',
            field: 'SpecValues',
            formatter: cell => {
              let template = '<div class="flex text-xs flex-wrap">'
              cell.getValue()?.forEach(value => {
                template += `<span class="badge-outline border-blue-500 m-0.5">
                              ${value.Name}: ${value.Value}
                             </span>`
              })
              return template + `</div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getProducts()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <div>
      <h1 class="text-lg">شیفت ها</h1>
    </div>

    <VButton class="!w-auto" @click="shiftModal = true; editShift = {}">شیفت جدید</VButton>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="shifts.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateShiftModal
      v-model="shiftModal"
      :editShift="editShift"
      @created="createdOrUpdated"
      @updated="createdOrUpdated"/>

  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف شیفت"
      text="از حذف شیفت مطمئن هستید ؟"
      @confirm="deleteShift"
      @cancel="confirmDeleteModal = false"
  />

</template>

<script>
import {deleteShift, getShifts} from "@/API/API";
import VCard from "@/components/General/VCard";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {mapGetters} from "vuex";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VButton from "@/components/General/VButton";
import CreateShiftModal from "@/components/Modal/DistributorManager/CreateShiftModal.vue";

export default {
  name: "ShiftList",
  components: {CreateShiftModal, VButton, ConfirmModal, VCard, VPagination},
  data() {
    return {
      shifts: [],

      shiftModal: false,
      editShift: {},

      confirmDeleteModal: false,
      deleteIndex: '',

      filter: {
        page: 1,
        selectedInventory: ''
      },
    }
  },
  computed: {
    ...mapGetters(['selectedInventory']),
  },
  created() {
    // Select the first Inventory as Selected (this triggers getInvoices because of watcher)
    this.filter.selectedInventory = this.selectedInventory
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Buttons
      let showButton = document.querySelectorAll("div[data-btn-type='show']")
      showButton.forEach(element => {
        element.onclick = () => this.$router.push({
          name: 'ShiftDetail',
          params: {id: element.dataset.id}
        })
      })
      // Edit Buttons
      let editButton = document.querySelectorAll("div[data-btn-type='edit']")
      editButton.forEach(element => {
        element.onclick = () => {
           this.editShift = this.shifts.find(group => group.ShiftId == element.dataset.id)
          this.shiftModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = this.shifts.findIndex(group => group.ShiftId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
    });
  },
  methods: {
    async getShifts() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.shifts = (await getShifts(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    deleteShift() {
      this.confirmDeleteModal = false
      deleteShift(this.shifts[this.deleteIndex].ShiftId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.shifts.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
    },
    createdOrUpdated(shifts) {
      this.shiftModal = false
      this.shifts = shifts
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.shifts)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'ردیف',
            field: 'Index',
            headerSort: false,
            formatter: cell => cell.getRow().getPosition()
          },
          {
            title: 'نام',
            field: 'Name',
            headerSort: false,
          },
          {
            title: 'تعداد موزعین',
            field: 'DistributorsCount',
            headerSort: false,
          },
          {
            title: 'بازه های زمانی',
            field: 'TimeSlices',
            headerSort: false,
            formatter: cell => {
              let template = '<div class="flex flex-wrap gap-2">'
              let timeSlices = cell.getValue()
              timeSlices.forEach(timeSLice => {
                template += `<span class="border rounded px-2">${timeSLice.Description}</span>`
              })

              return template + '</div>'
            }
          },
          {
            title: 'عملیات',
            field: 'ShiftId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-600 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-700">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span class="mr-1 text-xs">نمایش</span>
                        </div>
                         <div class="flex items-center text-amber-500 mr-4 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                             <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                             </svg>
                             <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getShifts()
      }
    },
    selectedInventory() {
      this.filter.selectedInventory = this.selectedInventory
    }
  }
}
</script>

<style scoped>

</style>
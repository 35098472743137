<template>
  <teleport to="body">
    <transition name="fade">
      <div
          class="overscroll-contain overflow-y-hidden fixed top-0 right-0 flex items-center justify-center h-screen w-screen bg-black/50 z-40"
          :class="this.class" @mousedown="this.close" v-if="modelValue"
          @keyup.esc="close" tabindex="0" ref="modal">
        <div class="bg-white w-5/6 lg:w-1/2 xl:w-1/3 rounded-lg p-5" @mousedown.stop>
          <!-- Header -->
          <div class="flex justify-between border-b pb-2">
            <h1 class="text-center">{{ title }}</h1>
            <XIcon class="w-4 cursor-pointer" @click="this.close"/>
          </div>

          <slot></slot>

        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import {XIcon} from "@heroicons/vue/outline";

export default {
  name: "ModalView",
  emits: ['update:modelValue', 'opened', 'closed'],
  props: ['modelValue', 'title', 'class'],
  components: {XIcon},
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    },
  },
  created() {
    const previousUrl = this.$router.options.history.state.back

    this.$router.beforeResolve((to, _, next) => {
      if (this.modelValue && to.fullPath === previousUrl) {
        this.$emit('update:modelValue', false);
        next(false);
      } else {
        next();
      }
    })
  },
  watch: {
    modelValue() {
      if (this.modelValue) {
        this.$emit('opened')
        setTimeout(() => this.$refs.modal.focus(), 100)
      } else {
        this.$emit('closed')
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView :title="editMode ? 'ویرایش ماشین' : 'ایجاد ماشین'"
             @closed="checkedExistence = false" @opened="getData">

    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <div class="flex mt-4">
        <VInput class="" type="text" placeholder="پلاک ماشین" v-model="vehicle.Plaque"
                :disabled="editMode" @keypress="isEnter" required/>

        <VButton type="button" class="w-auto mr-2 px-3 py-2" @click="checkExist" v-if="!editMode">
          <SearchIcon class="w-5"/>
        </VButton>
      </div>

      <div v-if="editMode || (!editMode && checkedExistence)">
        <Multiselect
            dir="rtl"
            class="mt-4 border-primary disabled:bg-gray-300"
            v-model="vehicle.TypeLId"
            :options="vehicleTypes"
            :searchable="true"
            placeholder="نوع ماشین"
            required
            :disabled="editMode || isExist"/>

        <VInput class="mt-4" type="number" placeholder="ظرفیت بر حسب سبد" v-model="vehicle.BasketCount" min="0" required/>

        <div class="mt-4 flex justify-between items-center">
          <span>فعال</span>
          <VSwitch v-model="vehicle.IsActive" :value="vehicle.IsActive"/>
        </div>

        <VButton class="mt-4" type="submit">ثبت</VButton>
      </div>

    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VSwitch from "@/components/General/VSwitch";
import VButton from "@/components/General/VButton";
import {checkVehicleExist, createVehicle, getLookupCombos, updateVehicle} from "@/API/API";
import Multiselect from "@vueform/multiselect";
import {SearchIcon} from "@heroicons/vue/outline";

const vehicleModel = {
  BasketCount: '',
  TypeLId: '',
  Plaque: '',
  IsActive: 1,
}

export default {
  name: "CreateVehicleModal",
  emits: ['created', 'updated'],
  props: ['editVehicle'],
  components: {VButton, VSwitch, VInput, ModalView, Multiselect, SearchIcon},
  data() {
    return {
      editMode: false,
      checkedExistence: false,
      isExist: false,
      vehicle: {...vehicleModel},

      vehicleTypes: [],
    }
  },
  methods: {
    async getData() {
      this.vehicleTypes = (await getLookupCombos(103040100)).data.data.Lookups.VehicleTypes.map(type => ({
          label: type.Name,
          value: type.LookupId,
      }))
    },
    create() {
      createVehicle(this.vehicle)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.vehicle = {...vehicleModel}
            this.$emit('created', resp.data.data.Vehicles)
          })
    },
    update() {
      updateVehicle(this.editVehicle.VehicleInventoryId, this.vehicle)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('updated', resp.data.data.Vehicles)
          })
    },
    checkExist() {
      if(!this.vehicle.Plaque) {
        this.$errorModal.value = 'لطفا پلاک ماشین را وارد کنید'
        return
      }
      checkVehicleExist(this.vehicle.Plaque)
          .then(resp => {
            let vehicle = resp.data.data
            this.isExist = true
            this.vehicle.BasketCount = vehicle.BasketCount
            this.vehicle.IsActive = vehicle.IsActive
            this.vehicle.TypeLId = vehicle.vehicle.TypeLId
            this.vehicle.Plaque = vehicle.vehicle.Plaque
          })
          .catch(() => {
            this.isExist = false
            this.vehicle.BasketCount = vehicleModel.BasketCount
            this.vehicle.IsActive = vehicleModel.IsActive
            this.vehicle.TypeLId = vehicleModel.TypeLId
          })
          .finally(() => this.checkedExistence = true)
    },
    isEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.checkExist()
      }
    },
  },
  watch: {
    editVehicle(editVehicle) {
      if (editVehicle.VehicleInventoryId) {
        this.editMode = true
        this.vehicle.BasketCount = this.editVehicle.BasketCount
        this.vehicle.IsActive = this.editVehicle.IsActive
        this.vehicle.TypeLId = this.editVehicle.Vehicle.Type.LookupId
        this.vehicle.Plaque = this.editVehicle.Vehicle.Plaque

      } else {
        this.editMode = false
        this.vehicle = {...vehicleModel}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="پرداخت سفارش" @opened="getTransactions" @closed="transactions = []">

    <LoadingSpinner class="w-10 h-10 mx-auto mt-4" v-if="$loading.value"/>

    <form ref="form" class="mt-4 space-y-4">
      <div class="grid grid-cols-2 items-center gap-x-4"
           v-for="transaction in transactions"
           :key="transaction.TransactionId">

        <VInput placeholder="مبلغ"
                type="number"
                v-model="transaction.Amount"
                :disabled="transaction.StateLId !== 1"/>

        <VButton type="button" class="w-auto shrink-0 "
                 :class="{'!bg-green-500' : transaction.StateLId !== 1, 'disabled:filter-none' : !$loading.value}"
                 :disabled="transaction.StateLId !== 1"
                 @click="payment(transaction.TransactionId, transaction.Amount)">
          {{ transaction.StateLId === 1 ? 'پرداخت' : 'پرداخت شده' }}
        </VButton>
      </div>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput.vue";
import {getInvoiceTransactions, storeInvoicePOSData, updateInvoiceTransaction} from "@/API/API.js";
import {registerPlugin} from "@capacitor/core";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {
  name: "POSPaymentModal",
  props: ['invoiceId'],
  components: {LoadingSpinner, VInput, VButton, ModalView},
  data() {
    return {
      transactions: [],
    }
  },
  methods: {
    async getTransactions() {
      this.transactions = (await getInvoiceTransactions(this.invoiceId)).data.data
    },
    async payment(transactionId, amount) {
      let transaction = (await updateInvoiceTransaction(this.invoiceId, transactionId, amount)).data.data
      const payment = registerPlugin('Payment');
      const {PaymentResult} = await payment.pay(transaction);

      let resp = (await storeInvoicePOSData(this.invoiceId, JSON.parse(PaymentResult))).data.data
      await this.getTransactions()
      console.log("Supplementary resp", resp)
    }
  },
}
</script>

<style scoped>

</style>
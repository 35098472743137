<template>
  <div class="intro-y flex items-center justify-between" @click="showFilter = !showFilter">
    <h1 class="text-lg cursor-pointer">
      {{ $route.name === 'SupporterInvoiceReturnListAll' ? 'همه مرجوعی ها' : 'مرجوعی ها در انتظار' }}
    </h1>
  </div>

  <InvoiceReturnListTable
      :key="$route.name"
      :show-filter="showFilter"
      :type="$route.name === 'SupporterInvoiceReturnListAll' ? 'supporter-all' : 'supporter-pending'"/>
</template>

<script>
import InvoiceReturnListTable from "@/components/InvoiceReturn/InvoiceReturnListTable";

export default {
  name: "InvoiceReturnList",
  components: {InvoiceReturnListTable},
  data() {
    return {
      showFilter: true,
    }
  },
}
</script>

<style scoped>

</style>
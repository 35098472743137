<template>
  <div id="invoice-print" class="absolute top-0 bg-white px-2 py-4 text-sm hidden print:block print:visible">
    <VueQrcode :value="String(invoiceId)" :options="{ width: 200 }"/>
    <div class="font-bold text-lg text-center">{{ invoiceId }}</div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "InvoiceQRPrint",
  props: ['invoiceId'],
  components: {VueQrcode},

}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست پالت ها</h1>

    <div class="flex intro-y">
      <VButton class="!w-auto" @click="QRModal = true">اسکن پالت</VButton>
      <VButton class="!w-auto mr-2" :disabled="selectedStocks.length === 0"
               @click="moveStockModal = true">
        انتقال
      </VButton>
    </div>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو شناسه، نام محصول" class="normal-digits" v-model.lazy="this.filter.q"
            @input="this.filter.page = 1"
            shadow/>

    <!-- State Filter -->
    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.state"
        :options="states"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="stockPage.PageCount"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <!-- QR Modal -->
  <ModalView
      class="print:invisible"
      v-model="selectedStockQR"
      title="QR Code">
    <div class="flex flex-col items-center print:visible" id="qr">
      <VueQrcode :value="selectedStockQR" tag="svg" :options="{ width: 200, margin: 2 }"/>
      <span class="text-lg">پالت: {{ selectedStockQR }}</span>
    </div>
    <VButton class="mt-4" @click="printQR">چاپ</VButton>

  </ModalView>

  <!-- Edit Modal -->
  <EditStockModal
      v-model="editStockModal"
      :edit-stock="selectedStock"
      :type="this.type"
      @updated="updated"/>

  <!-- QR Scanner -->
  <QRScannerModal
      v-model="QRModal"
      @QR="QRScanned"/>

  <!-- Transfer Stock Modal -->
  <TransferStockModal
      v-model="moveStockModal"
      :stocks="selectedStocks"
      @submitted="moveStockModal = false; getStocks()"
      :type="this.type"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import EditStockModal from "@/components/Modal/InventoryManager/EditStockModal";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import {getStocks} from "@/API/API";
import VInput from "@/components/General/VInput";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Multiselect from "@vueform/multiselect";
import QRScannerModal from "@/components/Modal/General/QRScannerModal";
import TransferStockModal from "@/components/Modal/InventoryManager/TransferStockModal";

export default {
  name: "StockListTable",
  components: {
    TransferStockModal,
    QRScannerModal,
    VInput,
    EditStockModal,
    VButton,
    ModalView,
    VCard,
    VPagination,
    VueQrcode,
    Multiselect
  },
  props: ['type'],
  data() {
    return {
      stockPage: {},
      stocks: [],
      states: [],
      selectedStockQR: '',
      editStockModal: false,
      selectedStock: false,
      QRModal: false,
      showFilter: true,

      moveStockModal: false,
      selectedStocks: [],

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        state: '',
      }
    }
  },
  created() {
    this.getStocks()
  },
  mounted() {
    this.initTable()
    this.tabulator.on("rowSelectionChanged", data => this.selectedStocks = data)
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = event => {
          event.stopPropagation()
          this.selectedStock = this.stocks.find(stock => stock.StockId == element.dataset.id)
          this.editStockModal = true
        }
      })
      // QR Buttons
      let QRButtons = document.querySelectorAll("div[data-btn-type='QR']")
      QRButtons.forEach(element => {
        element.onclick = event => {
          event.stopPropagation()
          this.selectedStockQR = element.dataset.id
        }
      })
    });
  },
  methods: {
    async getStocks() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.stockPage = (await getStocks(this.filter, this.firstCall)).data.data
      this.stocks = this.stockPage.Stocks

      if (this.stockPage.States.length > 0)
        this.states = this.stockPage.States.map(state => ({
          label: state.Name,
          value: state.LookupId
        }))

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    updated(editedStock) {
      let index = this.stocks.findIndex(stock => stock.StockId === editedStock.StockId)
      this.stocks[index] = editedStock
      this.editStockModal = false
      this.refreshTable()
    },
    printQR() {
      window.print()
    },
    refreshTable() {
      this.tabulator.setData(this.stocks)
      window.scrollTo(0, 0)
    },
    QRScanned(e) {
      this.filter.q = e
      this.QRModal = false
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: true,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه و عامل ردیابی',
            field: 'StockId',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div>
                        <div class="font-bold">${cell.getData().StockId}</div>
                        <div>${cell.getData().TrackingId}</div>
                      </div>`
            }
          },
          {
            title: 'محصول',
            field: 'product.Name',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Product.ImagePath)}" alt="" class="${cell.getData().Product.ImagePath ? 'w-10 h-10' : ''}">
                        <span class="whitespace-pre-wrap normal-digits ${cell.getData().Product.ImagePath ? 'mr-2' : ''}">${cell.getData().Product.Name}</span>
                      </div>`
            }
          },
          {
            title: 'اولویت',
            field: 'Priority',
            vertAlign: 'middle',
          },
          {
            title: 'قیمت',
            field: 'JCoPrice',
            vertAlign: 'middle',
            formatter: cell => `<span class="text-red-500 font-bold">${this.$helper.priceFormatter(cell.getData().JcoPrice)}</span>`
          },
          {
            title: 'تاریخ انقضا',
            field: 'ExpireDate',
            vertAlign: 'middle',
          },
          {
            title: 'موجودی',
            field: 'StockAmount',
            vertAlign: 'middle',
          },
          {
            title: 'سقف سفارش',
            field: 'MaxOrder',
            vertAlign: 'middle',
            visible: this.type === 'inventory-manager'
          },
          {
            title: 'وضعیت',
            field: 'StateLId',
            vertAlign: 'middle',
            formatter: cell => {
              let stateLId = cell.getData().State?.LookupId
              let bgColor = 'bg-green-500'

              switch (stateLId) {
                case 0:
                  bgColor = 'bg-blue-500'
                  break
                case 2:
                  bgColor = 'bg-orange-500'
                  break
                case 3:
                  bgColor = 'bg-red-500'
                  break
                case 4:
                  bgColor = 'bg-yellow-500'
                  break
              }

              return `<span class="badge ${bgColor}">
                        ${cell.getData().State?.Name}
                      </span>`
            }
          },
          {
            title: 'محل فیزیکی',
            field: 'PhysicalPlace',
            vertAlign: 'middle',
          },
          {
            title: 'عملیات',
            field: 'StockId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-purple-500 mr-4 cursor-pointer" data-btn-type="QR" data-id="${cell.getValue()}" ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                            </svg>
                            <span class="mr-1 text-xs">QR</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.firstCall = 0
        this.getStocks()
      }
    },
  }
}
</script>

<style scoped>

</style>
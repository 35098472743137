<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">اعلامیه های قیمت</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو..." v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="priceList.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmActivateModal"
      title="فعال سازی قیمت"
      text="آیا از فعال سازی قیمت مطمئن هستید ؟"
      @confirm="activatePrice"
      @cancel="confirmActivateModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard.vue";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";
import VInput from "@/components/General/VInput.vue";
import {activatePrice, getPriceList} from "@/API/API.js";

export default {
  name: "PriceList",
  components: {
    VInput,
    ConfirmModal,
    VCard,
    VPagination,
  },
  data() {
    return {
      priceList: [],
      deactivateModal: false,
      confirmActivateModal: false,
      selectedPriceId: {},
      showFilter: true,

      filter: {
        page: 1,
        q: '',
      }
    }
  },
  created() {
    this.getPriceList()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Buttons
      let showButton = document.querySelectorAll("div[data-btn-type='show']")
      showButton.forEach(element => {
        element.onclick = () => {
          this.$router.push({name: 'InventoryManagerPriceDetail', params: {id: element.dataset.id}})
        }
      })
      // Activate Button
      let activateButton = document.querySelectorAll("div[data-btn-type='activate']")
      activateButton.forEach(element => {
        element.onclick = () => {
          this.selectedPriceId = element.dataset.id
          this.confirmActivateModal = true
        }
      })
    });
  },
  methods: {
    async getPriceList() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.priceList = (await getPriceList(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    activatePrice() {
      activatePrice(this.selectedPriceId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.confirmActivateModal = false
            this.getPriceList()
          })
    },
    refreshTable() {
      this.tabulator.setData(this.priceList.PriceList)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'PriceListId',
          },
          {
            title: 'نام',
            field: 'Name',
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
          },
          {
            title: 'تاریخ فعال سازی',
            field: 'ActiveDateTime',
          },
          {
            title: 'عملیات',
            field: 'PriceListId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              let template = `<div class="flex">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}" ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span class="mr-1 text-xs">نمایش</span>
                          </div>`
              if (cell.getData().State.LookupId !== 2)
                template += `<div class="flex items-center text-purple-500 cursor-pointer mr-4" data-btn-type="activate" data-id="${cell.getValue()}" ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
                                </svg>
                                <span class="mr-1 text-xs">فعال سازی</span>
                          </div>`


              return template + `</div>`
            }
          },
        ],
      });
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getPriceList()
      }
    }
  }

}
</script>
<template>
  <div
      class="h-[45px] md:h-[50px] z-10 fixed md:relative top-0 w-full md:w-auto bg-primary/90 md:bg-transparent border-b border-white/[0.08] -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 md:mb-4 print:hidden">
    <div class="h-full flex items-center">
      <MenuAlt3Icon class="w-8 text-white md:hidden" @click="$sideMenu.value = true"/>

      <!-- BEGIN: Logo -->
      <router-link to="/"
                   class="intro-x flex items-center h-full ml-auto border-r border-white/[0.08] mr-2 pr-2 md:border-none md:mr-0 md:pr-0">
        <img class="w-6 rounded" src="../../assets/logo.png">
        <span class="text-white text-lg mr-3"> شهربابانا </span>
      </router-link>
      <!-- END: Logo -->

      <!-- BEGIN: Notifications -->
      <div class="-intro-x text-white">
        <div class="relative">
          <BellIcon class="cursor-pointer w-6 ml-4 text-gray-300" @click.stop="dropdown = !dropdown"></BellIcon>
          <span class="absolute -top-2 -right-2 bg-red-500 px-1 py-0.5 text-xs rounded-full" v-if="lastNotification?.data?.MessageCount > 0">
            {{ lastNotification?.data?.MessageCount }}
          </span>
        </div>
        <transition name="fade">
          <div class="absolute left-0 mt-2 bg-primary/80 rounded overflow-hidden before:absolute before:bg-black
                      before:inset-0 before:z-[-1] before:rounded-md" v-if="dropdown">
            <ul class="whitespace-nowrap p-2">

              <li class="flex items-center rounded hover:bg-primary/50 p-2">
                <InboxIcon class="w-4"/>
                <router-link :to="{name: 'MessagePage'}" class="block mr-2" href="">پیام ها</router-link>
              </li>

              <li class="border border-white/10 rounded-full my-2"></li>

              <li class="flex items-center rounded hover:bg-primary/50 p-2">
                <DocumentTextIcon class="w-4"/>
                <router-link :to="{name: 'RequestPage'}" class="block mr-2">درخواست ها</router-link>
              </li>

            </ul>
          </div>
        </transition>
      </div>
      <!-- END: Notifications -->

      <!-- BEGIN: Account Menu -->
      <div class="text-white ml-4 -intro-x hidden xs:block">{{ user.Name }}</div>
      <div class="-intro-x cursor-pointer" @click.stop="accountMenu = !accountMenu">
        <img class="w-8 h-8 shadow-lg" :src="user.Avatar?.Path ? $helper.imageURL(user.Avatar?.Path) : require('../../assets/img/avatar_placeholder.png')">

        <transition name="fade">
          <div class="absolute left-0 mt-2 bg-primary/80 rounded overflow-hidden text-white before:absolute before:bg-black
                      before:inset-0 before:z-[-1] before:rounded-md" v-if="accountMenu">
            <ul class="whitespace-nowrap p-2">

              <li class="flex items-center rounded hover:bg-primary/50 p-2" @click="editProfileModal = true">
                <UserIcon class="w-4"/>
                <span class="block mr-2">ویرایش پروفایل</span>
              </li>

              <li class="border border-white/10 rounded-full my-2"></li>

              <li class="flex items-center rounded hover:bg-primary/50 p-2" @click="$store.dispatch('logout')">
                <LogoutIcon class="w-4"/>
                <span class="block mr-2">خروج</span>
              </li>

            </ul>
          </div>
        </transition>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>

  <EditProfileModal
      v-model="editProfileModal"
      @updated="editProfileModal = false"/>

</template>

<script>
import {BellIcon, DocumentTextIcon, InboxIcon, LogoutIcon, MenuAlt3Icon, UserIcon} from "@heroicons/vue/outline";
import EditProfileModal from "@/components/Modal/EditProfileModal";
import {mapGetters, mapState} from "vuex";

export default {
  name: "TopBar",
  components: {
    EditProfileModal,
    BellIcon,
    MenuAlt3Icon,
    UserIcon,
    LogoutIcon,
    InboxIcon,
    DocumentTextIcon,
  },
  data() {
    return {
      editProfileModal: false,
      dropdown: false,
      accountMenu: false,
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['lastNotification'])
  },
  mounted() {
    document.addEventListener('click', () => {
      this.accountMenu = false
      this.dropdown = false
    })
  }
}
</script>

<style scoped>

</style>`
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست پیام ها</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>

    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.seen"
        :options="seenOptions"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="فیلتر"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="messages.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="bg-transparent mt-4 text-slate-600" no-padding>
    <div ref="table"></div>
  </VCard>

</template>

<script>
import {getMessages, seenMessages} from "@/API/API";
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VInput from "@/components/General/VInput.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "MessagePage",
  components: {VInput, VCard, VPagination, Multiselect},
  data() {
    return {
      showFilter: true,

      messages: [],
      seenOptions: [
        {
          label: 'دیده نشده',
          value: 0,
        },
        {
          label: 'دیده شده',
          value: 1,
        },
      ],
      filter: {
        page: 1,
        seen: null
      },
    }
  },
  created() {
    this.getMessages()
  },
  mounted() {
    this.initTable()

    this.tabulator.on("renderComplete", () => {
      let showButtons = document.querySelectorAll("div[data-btn-type='seen']")
      showButtons.forEach(element => element.onclick = async () => {
        await seenMessages(element.dataset.id)
        this.getMessages()
      })
    });
  },
  methods: {
    async getMessages() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.messages = (await getMessages(this.filter)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.messages.MessageData)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'MessageId'
          },
          {
            title: 'تاریخ مشاهده',
            field: 'SeenDate',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'متن پیام',
            field: 'MessageText',
            formatter: cell => `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`
          },
          {
            title: 'عملیات',
            field: 'MessageId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              if (cell.getData().SeenDate)
                return ''

              return `<div class="flex">
                          <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="seen" data-id="${cell.getValue()}" ">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span class="mr-1 text-xs">ثبت مشاهده</span>
                          </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getMessages()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4">
    <!-- Right Panel -->
    <div class="col-span-4 lg:col-span-1">
      <!-- Info -->
      <KeyValueCard title="اطلاعات انبار">
        <KeyValueCardItem title="کد انبار" :value="inventory.InventoryId" :icon="'HashtagIcon'" />
        <KeyValueCardItem title="نام انبار" :value="inventory.Name" :icon="'OfficeBuildingIcon'" last />
      </KeyValueCard>
    </div>

    <!-- Left Panel -->
    <div class="mt-8 col-span-4 lg:mr-8 lg:mt-0 lg:col-span-3">
      <!-- Map -->
      <VCard>
        <h1 class="text-lg mb-4">نقشه مناطق</h1>

        <div id="zones-map" class="z-0 h-64"></div>

        <VButton class="w-auto mt-4 float-left text-sm" @click="updateInventory">ذخیره محدوده انبار</VButton>
      </VCard>
      <!-- Zones -->
      <VCard class="mt-8">
        <div class="flex justify-between items-center mb-4">
          <h1 class="text-lg">مناطق</h1>
          <VButton class="w-auto text-sm" @click="showCreateZoneModal">منطقه جدید</VButton>
        </div>
        <div class="overflow-y-scroll max-h-96 px-4" style="direction: ltr">
          <table class="w-full mt-4" style="direction: rtl">
            <thead class="w-full text-gray-400 border-b text-sm">
              <tr>
                <td class="py-4">ردیف</td>
                <td>نام</td>
                <td class="text-center lg:text-right">عملیات</td>
              </tr>
            </thead>
            <tbody class="text-gray-500 text-sm divide-y">
              <tr class="-intro-x" v-for="(zone, index) in inventory.Zones" :key="zone.InventoryZoneId">
                <td class="py-4">{{ index + 1 }}</td>
                <td class="text-right">{{ zone.Name }}</td>
                <td>
                  <div class="flex justify-center lg:justify-start">
                    <span class="bg-yellow-100 rounded p-1 cursor-pointer mr-2"
                      @click="selectedZone = zone; createZoneModal = true">
                      <DynamicIcon class="w-4 text-yellow-500" icon="PencilIcon" />
                    </span>
                    <span class="bg-red-100 rounded p-1 cursor-pointer mr-2" @click="deleteZone(true, zone)">
                      <DynamicIcon class="w-4 text-red-500" icon="TrashIcon" />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </VCard>
    </div>
  </div>

  <CreateZoneModal v-model="createZoneModal" :inventory="inventory" @created="zoneCreated" @updated="zoneUpdated"
    :editZone="selectedZone" />

  <ConfirmModal title="حذف منطقه" text=" از حذف منطقه مطمئن هستید ؟" v-model="deleteZoneModal"
    @confirm="deleteZone(false)" @cancel="deleteZoneModal = false" />

</template>

<script>
import VCard from "@/components/General/VCard";
import { deleteInventoryZone, getInventory, updateInventory } from "@/API/AdminAPI";
import VButton from "@/components/General/VButton";
import CreateZoneModal from "@/components/Modal/Admin/CreateZoneModal";
import L from "leaflet";
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import KeyValueCardItem from "@/components/KeyValueCardItem";
import KeyValueCard from "@/components/KeyValueCard";
import DynamicIcon from "@/components/DynamicIcon";

export default {
  name: "InventoryDetail",
  components: {
    DynamicIcon,
    KeyValueCard,
    KeyValueCardItem,
    ConfirmModal, CreateZoneModal, VButton, VCard
  },
  data() {
    return {
      map: {},
      inventory: {},

      createZoneModal: false,
      deleteZoneModal: false,

      selectedZone: '',
      deleteZoneId: '',
    }
  },
  mounted() {
    this.getInventory()
  },
  methods: {
    initMap() {
      this.map = L.map('zones-map', {
        center: [this.inventory.Lat, this.inventory.Long],
        zoom: 11,
        zoomControl: false,
        fullscreenControl: true,
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
      // add Leaflet-Geoman controls with some options to the map
      this.map.pm.addControls({
        position: 'topleft',
        drawMarker: false,
        drawPolyline: false,
        drawCircleMarker: false,
        drawCircle: false,
        cutPolygon: false,
        rotateMode: false,
        drawText: false
      });
    },
    async getInventory() {
      this.inventory = (await getInventory(this.$route.params.id)).data.data[0]
      this.initMap()
      this.addGeoJSONToMap()
    },
    addGeoJSONToMap() {
      // Remove Old Polygons
      this.map.eachLayer(layer => {
        if (!(layer instanceof L.TileLayer)) {
          layer.remove()
        }
      })
      let icon = L.icon({
        iconUrl: require('/src/assets/img/map_marker_building.png'),
        iconSize: [30, 30], // size of the icon
      });

      // Add Clicked Location Marker
      L.marker([this.inventory.Lat, this.inventory.Long], { icon, draggable: true })
        .addTo(this.map)

      // Add Inventory Polygon
      L.geoJSON(this.inventory.GeoJSON).addTo(this.map).bindPopup('محدوده انبار', { closeButton: false })
      // Add InventoryZones Polygon
      let zonesGeoJSON = L.geoJSON(null, {
        style: { color: "red" },
        onEachFeature: (feature, layer) => {
          layer.bindPopup(feature.properties.name, { closeButton: false })
        }
      }).addTo(this.map)
      zonesGeoJSON.options.pmIgnore = true

      this.inventory?.Zones?.forEach(zone => zonesGeoJSON.addData(zone.GeoJSON))
    },
    updateInventory() {
      let group = L.featureGroup();
      this.map.pm.getGeomanLayers().forEach((layer) => {
        if (layer?._parts?.length > 0) {
          group.addLayer(layer);
        }
      });
      let geoJSON = group.toGeoJSON();

      updateInventory(this.inventory.InventoryId, { geoJSON, Name: this.inventory.Name, Lat: this.inventory.Lat, Long: this.inventory.Long })
        .then(resp => {
          this.inventory = resp.data.data[0]
          this.$infoModal.value = 'محدوده انبار با موفقیت ثبت شد.'
        })
    },
    showCreateZoneModal() {
      if (!this.inventory.GeoJSON) {
        this.$errorModal.value = 'ابتدا محدوده انبار را از روی نقشه بالا انتخاب کنید.'
        return
      }

      this.selectedZone = ''
      this.createZoneModal = true
    },
    zoneCreated(zone) {
      this.createZoneModal = false
      this.inventory.Zones.push(zone)
      this.addGeoJSONToMap()
    },
    zoneUpdated(editedZone) {
      this.createZoneModal = false
      this.selectedZone = ''
      let index = this.inventory.Zones.findIndex(zone => zone.InventoryZoneId === editedZone.InventoryZoneId)
      this.inventory.Zones[index] = editedZone
      this.addGeoJSONToMap()
    },
    deleteZone(confirm, zone) {
      if (confirm) {
        this.deleteZoneModal = true
        this.deleteZoneId = zone.InventoryZoneId
        return
      }

      deleteInventoryZone(this.deleteZoneId)
        .then(() => {
          this.deleteZoneModal = false
          let index = this.inventory.Zones.findIndex(zone => zone.InventoryZoneId === this.deleteZoneId)
          this.inventory.Zones.splice(index, 1)
          this.addGeoJSONToMap()
        })
    }
  }
}
</script>

<style scoped></style>
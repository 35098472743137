<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست درخواست تسویه کیف پول</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
    <!-- Category Filter -->
    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.stateId"
        :options="states"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="requests.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <AnswerRequestModal
      v-model="answerRequestModal"
      :requestId="selectedRequest"
      :answerType="answerType"
      @submitted="answerSubmitted"/>
</template>

<script>
import {getWalletRequests} from "@/API/API";
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import AnswerRequestModal from "@/components/Modal/AnswerRequestModal";
import VInput from "@/components/General/VInput.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "WalletRequest",
  components: {VInput, AnswerRequestModal, VCard, VPagination, Multiselect},
  data() {
    return {
      requests: [],

      requestModal: false,
      answerRequestModal: false,
      answerType: '',
      selectedRequest: '',
      copyDescription: '',
      states: [],
      showFilter: true,

      firstCall: 1,
      filter: {
        page: 1,
        stateId: '',
      },
    }
  },
  created() {
    this.getRequests()
  },
  mounted() {
    this.initTable()
    this.tabulator.on("renderComplete", () => {
      // Accept Buttons
      let acceptsButton = document.querySelectorAll("div[data-btn-type='accept']")
      acceptsButton.forEach(element =>
          element.onclick = () => {
            this.selectedRequest = element.dataset.id
            this.answerRequestModal = true
            this.answerType = 'accept'
          })
      // Confirm Buttons
      let confirmButtons = document.querySelectorAll("div[data-btn-type='confirm']")
      confirmButtons.forEach(element =>
          element.onclick = () => {
            this.selectedRequest = element.dataset.id
            this.answerRequestModal = true
            this.answerType = 'confirm'
          })
      // Reject Buttons
      let rejectButtons = document.querySelectorAll("div[data-btn-type='reject']")
      rejectButtons.forEach(element =>
          element.onclick = () => {
            this.selectedRequest = element.dataset.id
            this.answerRequestModal = true
            this.answerType = 'reject'
          })
    });
  },
  methods: {
    async getRequests() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.requests = (await getWalletRequests(this.filter, this.firstCall)).data.data

      if (this.requests.States.length > 0)
        this.states = this.requests.States.map(category => ({
          label: category.Name,
          value: category.LookupId,
        }))

      this.firstCall = 0

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    answerSubmitted() {
      this.answerRequestModal = false;
      this.getRequests()
    },
    refreshTable() {
      this.tabulator.setData(this.requests.Requests)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'RequestId',
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
            formatter: cell => {
              let stateLId = cell.getData().State.LookupId
              let color = 'bg-yellow-500'

              switch (stateLId) {
                case 2:
                  color = 'bg-blue-500'
                  break
                case 3:
                  color = 'bg-green-500'
                  break
                case 4:
                  color = 'bg-red-500'
                  break
              }

              return `<span class="badge ${color}">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'فرستنده',
            field: 'Sender.Name'
          },
          {
            title: 'شرح',
            field: 'Description'
          },
          {
            title: 'نتیجه',
            field: 'Result',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'عملیات',
            field: 'RequestId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              if (cell.getData().State.LookupId === 1 && this.filter.type === 'receiving')
                return `<div class="flex">
                          <div class="flex items-center text-yellow-500 cursor-pointer" data-btn-type="accept" data-id="${cell.getValue()}">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span class="mr-1 text-xs">قبول</span>
                          </div>
                      </div>`
              else if (cell.getData().State.LookupId === 2 && this.filter.type === 'receiving')
                return `<div class="flex">
                          <div class="flex items-center text-green-500 cursor-pointer" data-btn-type="confirm" data-id="${cell.getValue()}">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span class="mr-1 text-xs">تایید</span>
                          </div>
                          <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="reject" data-id="${cell.getValue()}">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span class="mr-1 text-xs">رد</span>
                          </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter:{
      deep: true,
      handler() {
        this.getRequests()
      }
    }
  }
}
</script>

<style scoped>

</style>
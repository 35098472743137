<template>
  <ModalView title="انتخاب دسته بندی" @closed="selectedCategories = []">

    <Multiselect
        dir="rtl"
        class="mt-4"
        mode="tags"
        v-model="selectedCategories"
        :options="categories"
        :searchable="true"
        :object="true"
        placeholder="انتخاب دسته بندی"/>

    <VButton class="mt-4" @click="$emit('selected', selectedCategories)">ثبت</VButton>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import VButton from "@/components/General/VButton";
import {getLookupCombos} from "@/API/API.js";

export default {
  name: "ChooseCategoryModal",
  emits: ['selected'],

  components: {VButton, ModalView, Multiselect},
  data() {
    return {
      categories: [],
      selectedCategories: []
    }
  },
  async created() {
    this.categories = (await getLookupCombos(103010600)).data.data.Categories

    // Prepare Data for MultiSelect component
    this.categories.forEach((category, index) => {
      this.categories[index] = {
        label: category.Name,
        value: category.CategoryId,
      }
    })
  }
}
</script>

<style scoped>

</style>
<template>

  <UserListTable type="admin"/>

</template>

<script>
import UserListTable from "@/components/UserListTable";

export default {
  name: "UserPage",
  components: {UserListTable},
  data() {
    return {
      users: [],
      userModal: false,
      editUser: {},
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y grid grid-cols-2 lg:grid-cols-4 gap-4 mt-4 xl:w-3/5" v-if="showFilter">
    <VInput placeholder="جستجو شناسه" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.state"
        :options="states"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"
        v-if="type !== 'inventory-carrier'"/>

    <Multiselect
        dir="rtl"
        class="border-none shadow col-span-2 lg:col-span-1"
        v-model="filter.deliveryDatetime"
        :options="timeSlices"
        :searchable="!['distributor-delivery', 'distributor-inventory'].includes(type)"
        v-if="seeDatetimeFilter"
        @change="this.filter.page = 1"
        placeholder="بازه زمانی"
        :can-clear="false"
        :canDeselect="false"/>

    <div class="flex items-center" v-if="type === 'supporter-all' || type === 'supporter-pending'">
      <span>کارهای من</span>
      <VSwitch class="mr-2" v-model="filter.myJobs" :value="filter.myJobs"/>
    </div>

    <VButton class="w-auto" @click="download" v-if="type === 'supporter-all'">خروجی اکسل</VButton>
  </div>


  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="invoices.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import VInput from "@/components/General/VInput";
import VPagination from "@hennge/vue3-pagination";
import {mapState} from "vuex";
import {getDistributorInvoices, getInvoices} from "@/API/API";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import Multiselect from "@vueform/multiselect";
import VSwitch from "@/components/General/VSwitch";
import * as XLSX from 'xlsx';
import VButton from "@/components/General/VButton.vue";

export default {
  name: "InvoiceListTable",
  props: {
    type: String,
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  emits: ['selectedInvoices', 'distributorAssignment', 'assignmentState'],
  components: {VButton, VSwitch, VInput, VCard, VPagination, Multiselect},
  data() {
    return {
      states: [],
      timeSlices: [],
      invoices: [],
      selectedInvoices: [],

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        state: '',
        myJobs: 0,
        deliveryDatetime: '',
      }
    }
  },
  computed: {
    ...mapState(['user']),
    seeDatetimeFilter() {
      return this.type === 'inventory-manager' || this.type === 'distributor-inventory' || this.type === 'distributor-delivery'
    }
  },
  created() {
    window.XLSX = XLSX
    if (this.$route.query.page)
      this.filter = {
        q: this.$route.query.q,
        page: parseInt(this.$route.query.page),
        state: this.$route.query.state,
        myJobs: parseInt(this.$route.query.myJobs),
        deliveryDatetime: this.$route.query.deliveryDatetime,
      }
    this.getInvoices()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Buttons
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element => {
        element.onclick = () => this.goToInvoiceDetail(element.dataset.id)
      })
    });

    this.tabulator.on("rowSelectionChanged", data => this.selectedInvoices = data)
  },
  methods: {
    getInvoices() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      if (this.type === 'distributor-inventory' || this.type === 'distributor-delivery')
        getDistributorInvoices(this.filter, this.firstCall)
            .then(resp => {
              this.invoices = resp.data.data

              if (this.invoices.States.length > 0)
                this.states = this.invoices.States.map(state => ({
                  label: state.Name,
                  value: state.LookupId
                }))

              if (this.invoices.DeliveryDateTimes.length > 0)
                this.timeSlices = this.invoices.DeliveryDateTimes.map(time => ({
                  label: time.Description,
                  value: time.DeliveryDateTimeId,
                  disabled: !time.CanChoose
                }))

              if (this.firstCall && !this.filter.deliveryDatetime)
                this.filter.deliveryDatetime = this.timeSlices.find(timeSlice => !timeSlice.disabled).value

              this.$emit('assignmentState', this.invoices.AssignmentState)
              this.$emit('distributorAssignment', this.invoices.DistributorAssignments)
            })
            .catch((e) => {
              this.invoices = []
              console.log(e)
            })
            .finally(() => {
              this.tabulator.alertManager.clear()
              this.refreshTable()
            })
      else
        getInvoices(this.filter, this.firstCall)
            .then(resp => {
              this.invoices = resp.data.data

              if (this.invoices.States.length > 0)
                this.states = this.invoices.States.map(state => ({
                  label: state.Name,
                  value: state.LookupId
                }))

              if (this.invoices.DeliveryDateTimes.length > 0)
                this.timeSlices = this.invoices.DeliveryDateTimes.map(time => ({
                  label: time.Description,
                  value: time.DeliveryDateTimeId,
                  disabled: !time.CanChoose
                }))

              if (this.firstCall)
                this.filter.deliveryDatetime = this.timeSlices.find(timeSlice => !timeSlice.disabled)?.value
            })
            .catch(() => this.invoices = [])
            .finally(() => {
              this.tabulator.alertManager.clear()
              this.refreshTable()
            })
    },
    goToInvoiceDetail(invoiceId) {
      let name = ''

      switch (this.type) {
        case 'inventory-manager':
          name = 'InventoryManagerInvoiceDetail'
          break
        case 'inventory-packer':
          name = 'InventoryPackerInvoiceDetail'
          break
        case 'inventory-controller':
          name = 'InventoryControllerInvoiceDetail'
          break
        case 'distributor-inventory':
        case 'distributor-delivery':
          name = 'DistributorInvoiceDetail'
          break
        case 'supporter-all':
        case 'supporter-pending':
          name = 'SupporterInvoiceDetail'
          break
        case 'inventory-carrier':
          name = 'InventoryCarrierInvoiceDetail'
          break
      }

      this.$router.push({
        name: name,
        params: {id: invoiceId}
      })
    },
    refreshTable() {
      this.tabulator.setData(this.invoices.InvoiceData)
      window.scrollTo(0, 0)
    },
    download() {
      this.tabulator.download("xlsx", "data.xlsx", {})
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: this.type === 'inventory-manager',
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        rowFormatter: row => {
          if (row.getData().StaffId === this.user.UserId)
            row.getElement().classList.add('!bg-green-100')
        },
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شماره سفارش',
            field: 'InvoiceId',
            headerSort: false
          },
          {
            title: 'ترتیب تحویل',
            field: 'DeliveryOrder',
            visible: ['inventory-carrier', 'distributor-delivery', 'distributor-inventory'].includes(this.type),
            headerSort: false
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
            headerSort: false,
            formatter: cell => {
              return `<span class="badge-outline ${this.$helper.renderInvoiceState(cell.getData().State.LookupId)}">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'موزع',
            field: 'DistributorName',
            headerSort: false,
            visible: this.type === 'inventory-carrier'
          },
          {
            title: 'تاریخ ثبت',
            field: 'CreateDate',
            headerSort: false
          },
          {
            title: 'تاریخ تحویل',
            field: 'DeliveryDateTime.DeliveryDate',
            headerSort: false,
            visible: !this.seeDatetimeFilter
          },
          {
            title: 'ساعت تحویل',
            field: 'DeliveryDateTime.Description',
            headerSort: false,
            visible: !this.seeDatetimeFilter
          },
          {
            title: 'مسئول مربوطه',
            field: 'StaffName',
            visible: this.type.includes('supporter'),
            headerSort: false,
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'عملیات',
            field: 'InvoiceId',
            resizable: false,
            headerSort: false,
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                            <span class="mr-1 text-xs">نمایش</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.firstCall === 0) {
          this.getInvoices()
          this.$router.replace({
            name: this.$route.name,
            query: this.filter
          })
        }

        this.firstCall = 0
      }
    },
    selectedInvoices(invoices) {
      this.$emit('selectedInvoices', invoices.map(invoice => invoice.InvoiceId))
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <InvoiceInfoCard :invoice="invoice"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Invoice Products -->
      <InvoiceProductsTable :invoice="invoice"/>

      <!-- Coordination -->
      <CoordinationsTable
          :coordinations="invoice.coordinations"
          :canEdit="false"/>

      <!-- Invoice Return-->
      <InvoiceReturnsTable
          class="mt-4"
          :invoiceReturns="invoice.returns" />
    </div>

  </div>

</template>

<script>
import {acceptInvoice, backInvoice, getInvoice} from "@/API/API";
import {mapState} from "vuex";
import InvoiceInfoCard from "@/components/Invoice/InfoCard/InvoiceInfoCard";
import InvoiceReturnsTable from "@/components/Invoice/InvoiceReturnsTable";
import InvoiceProductsTable from "@/components/Invoice/InvoiceProductsTable";
import CoordinationsTable from "@/components/CoordinationsTable";

export default {
  name: "InvoiceDetail",
  components: {
    CoordinationsTable,
    InvoiceProductsTable,
    InvoiceReturnsTable,
    InvoiceInfoCard,
  },
  data() {
    return {
      invoice: {},

      confirmSubmitInvoiceModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoice(this.invoice.InvoiceId, 'supporter')
          .then(() => this.getInvoice())
    },
    back() {
      backInvoice(this.invoice.InvoiceId, 'supporter')
          .then(resp => {
            this.getInvoice()
            this.$infoModal.value = resp.data.message
          })
    },
    invoiceSubmitted() {
      this.confirmSubmitInvoiceModal = false
      this.getInvoice()
    }
  }
}
</script>


<template>
  <div class="grid grid-cols-4 gap-4">


    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="intro-y mt-4" @click="accept" v-if="invoice.Acceptable">قبول سفارش</VButton>
      <VButton class="intro-y mt-4" @click="back" outline v-else-if="canEdit">بازگشت به پنل کنترلر ها</VButton>

      <InvoiceDetailCard class="mt-4" :invoice="invoice"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Products -->
      <h1 class="intro-y my-4 text-lg">محصولات</h1>
      <InvoiceProductCard
          v-for="item in invoice.Items"
          :key="item.InvoiceDetailId"
          :invoiceDetail="item"
          :canEdit="canEdit"
          @replaced="getInvoice"
          @rejected="rejected"
          @resetReject="resetReject"
          type="inventory-controller"/>

      <div class="intro-y flex mt-4 gap-x-4 mr-auto lg:w-1/2 xl:w-1/3" v-if="canEdit">
        <VButton outline @click="rejectModal = true">رد</VButton>
        <VButton @click="proceed(true)" :disabled="hasRejectedStock">ثبت</VButton>
      </div>

    </div>

  </div>

  <InvoiceRejectModal
      v-model="rejectModal"
      :invoice="invoice"
      @rejected="invoiceRejected"
      type="inventory-controller"/>

  <ConfirmModal
      v-model="confirmProceedModal"
      text="از تایید سفارش مطمئن هستید ؟"
      @confirm="proceed(false)"
      @cancel="confirmProceedModal = false"/>

</template>

<script>
import VButton from "@/components/General/VButton";
import InvoiceProductCard from "@/components/Invoice/InvoiceProductCard";
import {acceptInvoice, backInvoice, getInvoice, proceedInvoice} from "@/API/API";
import InvoiceRejectModal from "@/components/Modal/InvoiceRejectModal";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {mapState} from "vuex";
import InvoiceDetailCard from "@/components/Invoice/InfoCard/InvoiceDetailCard";

export default {
  name: "InvoiceDetail",
  components: {
    InvoiceDetailCard,
    ConfirmModal,
    InvoiceRejectModal,
    InvoiceProductCard,
    VButton,
  },
  data() {
    return {
      invoice: {},
      rejectModal: false,
      confirmProceedModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoice.StaffId === this.user.UserId
    },
    hasRejectedStock() {
      return this.invoice.Items.some(item => item.StockDetails.some(stockDetail => stockDetail.RejectAmount > 0))
    }
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoice(this.invoice.InvoiceId)
          .then(resp => Object.assign(this.invoice, resp.data.data))
    },
    back() {
      backInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    rejected(stockDetailReject) {
      // Add Log to StockDetail, so we can show it to user in StockDetailRejectModal
      this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailReject.StockDetailId) {
          stockDetail.RejectAmount = stockDetailReject.RejectAmount
          stockDetail.RejectReasonLId = stockDetailReject.RejectReasonLId
        }
      }))
    },
    resetReject(stockDetailReject) {
      this.invoice.Items.forEach(item => item.StockDetails.forEach(stockDetail => {
        if (stockDetail.StockDetailId === stockDetailReject.StockDetailId) {
          stockDetail.RejectAmount = ''
          stockDetail.RejectReasonLId = ''
        }
      }))
    },
    proceed(confirm) {
      if (confirm) {
        this.confirmProceedModal = true
        return
      }

      proceedInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.confirmProceedModal = false
            this.$infoModal.value = resp.data.message
            this.$router.replace({name: 'InventoryControllerInvoiceList'})
          })
    },
    invoiceRejected() {
      this.rejectModal = false
      this.$router.replace({name: 'InventoryControllerInvoiceList'})
    },
  }
}
</script>

<style scoped>

</style>
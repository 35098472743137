<template>
  <ModalView title="تعریف کالای مکمل">

    <form>
      <VInput class="mt-4" placeholder="جستجو محصول" v-model.lazy="q"/>

      <!-- Result -->
      <VCard class="flex items-center border"
             v-for="product in products"
             :key="product.ProductId">

        <img class="w-10" :src="$helper.imageURL(product.Image)" alt="">
        <span class="text-xs mr-4">{{ product.Name }}</span>

        <VButton class="w-auto text-xs !py-1 mr-auto" :loading="$loading.value"
                 @click="addRelevancy(product.ProductId)" outline>افزودن
        </VButton>
      </VCard>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import VCard from "@/components/General/VCard.vue";
import {searchProducts} from "@/API/API.js";
import {addProductComplementary} from "@/API/AdminAPI.js";

export default {
  name: "ProductComplementaryModal",
  props: ['productId'],
  emits: ['added'],
  components: {VCard, VButton, VInput, ModalView},
  data() {
    return {
      q: '',
      products: []
    }
  },
  methods: {
    async search() {
      if (this.q.length > 1) {
        this.products = (await searchProducts(this.q, 1)).data.Products
      }
    },
    async addRelevancy(relevantId) {
      addProductComplementary(this.productId, relevantId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('added', resp.data.data)
          })
    }
  },
  watch: {
    q() {
      this.search()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست درخواست ها</h1>

    <VButton @click="requestModal = true" class="w-auto">درخواست جدید</VButton>
  </div>

  <div class="intro-y grid grid-cols-2 lg:grid-cols-4 gap-4 mt-4 xl:w-2/3" v-if="showFilter">
    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.type"
        :options="requestTypes"
        :searchable="true"
        @change="this.filter.page = 1"
        :can-clear="false"
        placeholder="نوع"/>

    <VInput placeholder="نوع، فرستنده، گیرنده، شرح" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.state"
        :options="states"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"/>

    <div class="flex items-center" v-if="filter.type === 1">
      <span>درخواست های من</span>
      <VSwitch class="mr-2" v-model="filter.myJobs" :value="0"/>
    </div>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="requests.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="bg-transparent mt-4 text-slate-600" no-padding>
    <div ref="table"></div>
  </VCard>

  <CreateRequestModal
      v-model="requestModal"
      :description="copyDescription"
      @submitted="requestModal = false"
      @closed="copyDescription = ''"/>

  <AnswerRequestModal
      v-model="answerRequestModal"
      :requestId="selectedRequest"
      :states="states"
      @submitted="answerSubmitted"/>
</template>

<script>
import VButton from "@/components/General/VButton";
import CreateRequestModal from "@/components/Modal/CreateRequestModal";
import {getRequests} from "@/API/API";
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import AnswerRequestModal from "@/components/Modal/AnswerRequestModal";
import VInput from "@/components/General/VInput.vue";
import VSwitch from "@/components/General/VSwitch.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "RequestPage",
  components: {VSwitch, VInput, AnswerRequestModal, VCard, CreateRequestModal, VButton, VPagination, Multiselect},
  data() {
    return {
      showFilter: true,

      requests: [],

      requestTypes: [
        {label: "درخواستهای دریافتی جهت اقدام", value: 1},
        {label: "رخواستهای ارسالی جهت پی گیری", value: 2},
      ],
      states: [],

      requestModal: false,
      answerRequestModal: false,
      selectedRequest: '',
      copyDescription: '',

      firstCall: 1,
      filter: {
        page: 1,
        type: 1,
        myJobs: 0,
        state: '',
      },
    }
  },
  created() {
    this.getRequests()
  },
  mounted() {
    this.initTable()
    this.tabulator.on("renderComplete", () => {
      // Accept Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element =>
          element.onclick = () => {
            this.selectedRequest = element.dataset.id
            this.answerRequestModal = true
          })
    });
  },
  methods: {
    async getRequests() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.requests = (await getRequests(this.filter, this.firstCall)).data.data

      if (this.requests.States.length > 0)
        this.states = this.requests.States.map(state => ({
          label: state.Name,
          value: state.LookupId,
        }))

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    answerSubmitted(request) {
      this.answerRequestModal = false;
      let index = this.requests.Requests.findIndex(item => item.RequestId === request.RequestId)
      this.requests.Requests[index] = request
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.requests.Requests)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'RequestId',
          },
          {
            title: 'نوع درخواست',
            field: 'Type.Name'
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
            formatter: cell => {
              let stateLId = cell.getData().State.LookupId
              let color = 'bg-yellow-500'

              switch (stateLId) {
                case 2:
                  color = 'bg-blue-500'
                  break
                case 3:
                  color = 'bg-red-500'
                  break
                case 4:
                  color = 'bg-green-500'
                  break
              }

              return `<span class="badge ${color}">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'فرستنده',
            field: 'Sender.Name'
          },
          {
            title: 'نقش گیرنده',
            field: 'Role.Name'
          },
          {
            title: 'نام گیرنده',
            field: 'Receiver.Name',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'شرح',
            field: 'Description',
            formatter: cell =>  `<div class="whitespace-pre-wrap">${cell.getValue()}</div>`
          },
          {
            title: 'نتیجه',
            field: 'Result',
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'عملیات',
            field: 'RequestId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex">
                          <div class="flex items-center text-green-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span class="mr-1 text-xs">تغییر وضعیت</span>
                          </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.firstCall = 0
        this.getRequests()
      }
    }
  }
}
</script>

<style scoped>

</style>
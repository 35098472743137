<template>
  <ModalView
      :title="title"
      class="text-gray-500"
      >

    <p class="mt-4 text-sm ">{{ text }}</p>

    <div class="flex mt-4">
      <VButton outline @click="$emit('cancel')">خیر</VButton>
      <VButton class="mr-4" @click="$emit('confirm')" :loading="$loading.value">بلی</VButton>
    </div>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";

export default {
  name: "ConfirmModal",
  emits: ['confirm', 'cancel'],
  props: ['title', 'text'],
  components: {VButton, ModalView},
}
</script>

<style scoped>

</style>
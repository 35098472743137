<template>
  <ModalView title="درخواست">
    <form @submit.prevent="submit">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          v-model="answer.state"
          :options="states"
          :searchable="true"
          placeholder="وضعیت"/>

      <VInput class="mt-4" placeholder="نتیجه" v-model="answer.result" required v-if="[3, 4].includes(answer.state)"/>

      <VButton class="mt-4">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {answerRequest} from "@/API/API";
import Multiselect from "@vueform/multiselect";

export default {
  name: "AnswerRequestModal",
  emits: ['submitted'],
  props: ['requestId', 'states'],
  components: {VButton, VInput, ModalView, Multiselect},
  data() {
    return {
      answer: {
        result: '',
        state: '',
      }
    }
  },
  methods: {
    submit() {
      if (this.answerType === 'accept')
        this.answer.state = 'accept'

      answerRequest(this.requestId, this.answer)
          .then(resp => {
            this.$emit('submitted', resp.data.data)
            this.$infoModal.value = resp.data.message
          })
    },
  }
}
</script>

<style scoped>

</style>
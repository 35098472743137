<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="mb-4"
               @click="confirmDeliverToInventoryModal = true"
               :disabled="editButton"
               v-if="invoiceReturn.State?.LookupId === $helper.invoiceReturnState.DELIVERED_TO_DISTRIBUTOR">تحویل به انبار
      </VButton>

      <InvoiceReturnInfoCard :invoice-return="invoiceReturn"/>

      <KeyValueCard class="mt-4" title="اطلاعات تحویل گیرنده">
        <KeyValueCardItem title="نام و نام خانوادگی" :value="invoiceReturn.Address?.ReceiverFullName"/>
        <KeyValueCardItem title="شماره موبایل" :value="invoiceReturn.Address?.TelNo" last/>
      </KeyValueCard>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Map -->
      <h1 class="mb-4 text-lg">آدرس</h1>
      <VCard noPadding>
        <div id="address-map" class="z-0 h-[15vh]"></div>

        <div class="flex text-sm my-2 mr-1">
          <LocationMarkerIcon class="w-5"/>
          <div class="mr-2" v-if="invoiceReturn.Address">{{ invoiceReturn.Address?.FullAddress + ' | ' + (invoiceReturn.Address?.ExtraInfo ?? '') }}</div>
        </div>
      </VCard>

      <!-- Products -->
      <div class="flex justify-between items-center my-4">
        <h1 class="text-lg">محصولات مرجوعی</h1>
        <VButton class="w-auto" @click="invoiceProductsModal = true"
                 v-if="invoiceReturn.State?.LookupId === $helper.invoiceReturnState.ASSIGN_TO_DISTRIBUTOR || invoiceReturn.State?.LookupId === $helper.invoiceReturnState.DISTRIBUTOR_SUBMIT">
          افزودن کالا
        </VButton>
      </div>
      <InvoiceReturnProduct
          v-for="item in invoiceReturn.Items"
          :key="item.InvoiceReturnDetailId"
          type="distributor"
          :invoiceReturnDetail="item"
          :can-edit="canEdit"
          @edited="edited"
          @delete-item="deleteItem"/>

      <div class="flex mt-4 xl:w-1/3 lg:mr-auto" v-if="canEdit">
        <VButton class="ml-4" @click="confirmEditModal = true" v-if="editButton">ثبت</VButton>

        <div class="flex w-full"
             v-if="invoiceReturn.State?.LookupId === $helper.invoiceReturnState.ASSIGN_TO_DISTRIBUTOR || invoiceReturn.State?.LookupId === $helper.invoiceReturnState.DISTRIBUTOR_SUBMIT">
          <VButton @click="confirmCancelModal = true" :disabled="editButton" outline>لغو</VButton>
          <VButton class="mr-4" @click="openReceiveModal" :disabled="editButton">دریافت</VButton>
        </div>
      </div>

      <VButton class="mt-4 !w-auto mr-auto" @click="returnDetailModal = true"
               v-else-if="invoiceReturn.State?.LookupId === $helper.invoiceReturnState.INVENTORY_STAFF_SUBMIT">نمایش ثبت انباردار
      </VButton>

    </div>
  </div>

  <ReceiveInvoiceReturnModal
      v-model="receiveModal"
      :invoiceReturnId="invoiceReturn.InvoiceReturnId"
      type="distributor"
      @close="receiveModal = false"
      @submitted="receiveSubmitted"/>

  <ConfirmModal
      v-model="confirmEditModal"
      title="ثبت ویرایش مرجوعی"
      text="از ثبت ویرایش مطمئن هستید ؟"
      @cancel="confirmEditModal = false"
      @confirm="editInvoiceReturn"/>

  <CancelInvoiceReturnModal
      v-model="confirmCancelModal"
      :invoice-return-id="invoiceReturn.InvoiceReturnId"
      @canceled="invoiceReturnCanceled"/>

  <InvoiceReturnDetailModal
      v-model="returnDetailModal"
      :invoiceReturn="invoiceReturn"/>

  <InvoiceProductsModal
      v-model="invoiceProductsModal"
      :invoice-return="invoiceReturn"
      @add-items="addItems"/>

  <ConfirmModal
      v-model="confirmDeliverToInventoryModal"
      title="تحویل به انبار"
      text="از تحویل به انبار مطمئن هستید ؟"
      @confirm="deliverToInventory"
      @cancel="confirmDeliverToInventoryModal = false"/>

</template>

<script>
import {deliverInvoiceReturnToInventory, getInvoiceReturn, submitInvoiceReturn} from "@/API/API";
import VCard from "@/components/General/VCard";
import {LocationMarkerIcon} from "@heroicons/vue/outline";
import VButton from "@/components/General/VButton";
import ReceiveInvoiceReturnModal from "@/components/Modal/ReceiveInvoiceReturnModal";
import {mapState} from "vuex";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import InvoiceReturnDetailModal from "@/components/Modal/Distributor/InvoiceReturnDetailModal";
import InvoiceReturnInfoCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnInfoCard";
import InvoiceReturnProduct from "@/components/InvoiceReturn/InvoiceReturnProduct";
import InvoiceProductsModal from "@/components/Modal/Distributor/InvoiceProductsModal";
import CancelInvoiceReturnModal from "@/components/Modal/Distributor/CancelInvoiceReturnModal.vue";
import KeyValueCard from "@/components/KeyValueCard.vue";
import KeyValueCardItem from "@/components/KeyValueCardItem.vue";
import L from "leaflet";
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

export default {
  name: "InvoiceReturnDetail",
  components: {
    KeyValueCardItem,
    KeyValueCard,
    CancelInvoiceReturnModal,
    InvoiceProductsModal,
    InvoiceReturnProduct,
    InvoiceReturnInfoCard,
    InvoiceReturnDetailModal,
    ConfirmModal,
    ReceiveInvoiceReturnModal,
    VButton,
    VCard,
    LocationMarkerIcon,
  },
  data() {
    return {
      invoiceReturn: {},

      receiveModal: false,
      confirmEditModal: false,
      confirmCancelModal: false,
      returnDetailModal: false,
      invoiceProductsModal: false,
      sendDeliveryCodeModal: false,
      confirmDeliverToInventoryModal: false,
      editButton: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoiceReturn.State?.LookupId < this.$helper.invoiceReturnState.DELIVERED_TO_DISTRIBUTOR
    },
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id)).data.data
      this.initMap()
    },
    initMap() {
      this.map = L.map('address-map', {
        center: [this.invoiceReturn.Address?.Lat, this.invoiceReturn.Address?.Long],
        zoom: 12,
        zoomControl: false,
        fullscreenControl: true,
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
      this.markerLayer = L.layerGroup().addTo(this.map);

      let blueIcon = L.icon({
        iconUrl: require('/src/assets/img/map-marker-blue.png'),
        iconSize: [30, 30], // size of the icon
      });

      L.marker([this.invoiceReturn.Address?.Lat, this.invoiceReturn.Address?.Long], {icon: blueIcon})
          .addTo(this.markerLayer)
    },
    edited(invoiceReturnDetail) {
      let index = this.invoiceReturn.Items.findIndex(returnDetail => returnDetail.InvoiceReturnDetailId === invoiceReturnDetail.InvoiceReturnDetailId)
      this.invoiceReturn.Items[index] = invoiceReturnDetail
      this.editButton = true
    },
    receiveSubmitted() {
      this.receiveModal = false
      if (this.$route.query.return)
        this.$router.replace({name: 'DistributorInvoiceDetail', params: {id: this.$route.query.return}})
      else
        this.$router.replace({name: 'DistributorInvoiceReturnList'})
    },
    deleteItem(InvoiceReturnDetailId) {
      let deleteIndex = this.invoiceReturn.Items.findIndex(item => item.InvoiceReturnDetailId === InvoiceReturnDetailId)
      this.invoiceReturn.Items.splice(deleteIndex, 1)
      this.editButton = true
    },
    editInvoiceReturn() {
      let items = []

      const allowed = ['InvoiceReturnDetailId', 'Amount', 'HealthyAmount', 'DefectAmount', 'NoNeedAmount', 'Images', 'Description', 'ReturnReasonLId', 'StockDetailId'];
      items = this.$helper.filterObjectArray(this.invoiceReturn.Items, allowed)
      items.forEach(item => item.Images = item.Images.map(image => image.JCoFileId))

      submitInvoiceReturn(this.invoiceReturn.InvoiceReturnId, {items})
          .then(resp => {
            this.$infoModal.value = resp.message
            this.invoiceReturn = resp.data.data
            this.confirmEditModal = false
            this.editButton = false
          })
    },
    invoiceReturnCanceled() {
      this.confirmCancelModal = false
      this.$router.replace({name: 'DistributorInvoiceReturnList'})
    },
    addItems(items) {
      this.invoiceProductsModal = false
      this.invoiceReturn.Items.push(...items)
      this.editButton = true
    },
    deliverToInventory() {
      deliverInvoiceReturnToInventory(this.invoiceReturn.InvoiceReturnId)
          .then(resp => {
            this.confirmDeliverToInventoryModal = false
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    openReceiveModal() {
      if (this.invoiceReturn.Items.length === 0 ) {
        this.$errorModal.value = 'کالایی برای دریافت وجود ندارد !'
        return
      }
      this.receiveModal = true
    }
  }
}
</script>

<style scoped>

</style>
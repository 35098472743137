<template>
  <div class="grid grid-cols-4 gap-4">


    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <div class="flex mt-2 gap-2">
        <VButton @click="invoiceRejectModal = true" outline>رد</VButton>
        <VButton @click="proceed(true)">تایید</VButton>
      </div>

      <InvoiceDetailCard class="mt-4" :invoice="invoice"/>

      <KeyValueCard title="اطلاعات موزع" class="mt-4">
        <KeyValueCardItem title="نام موزع" :value="invoice.DistributorName" icon="CarIcon"/>
      </KeyValueCard>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Products -->
      <h1 class="intro-y my-4 text-lg">محصولات</h1>
      <InvoiceProductCard
          v-for="item in invoice.Items"
          :key="item.InvoiceDetailId"
          :invoiceDetail="item"
          type="inventory-carrier"/>

    </div>

  </div>

  <InvoiceRejectModal
      v-model="invoiceRejectModal"
      type="inventory-carrier"
      :invoice="invoice"
      @rejected="invoiceRejected"
      @cancel="invoiceRejectModal = false"
  />

  <ConfirmModal
      v-model="confirmProceedModal"
      text="از تایید سفارش مطمئن هستید ؟"
      @confirm="proceed(false)"
      @cancel="confirmProceedModal = false"/>

</template>

<script>
import VButton from "@/components/General/VButton";
import InvoiceProductCard from "@/components/Invoice/InvoiceProductCard";
import {getInvoice, proceedInvoice} from "@/API/API";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {mapState} from "vuex";
import InvoiceDetailCard from "@/components/Invoice/InfoCard/InvoiceDetailCard";
import KeyValueCard from "@/components/KeyValueCard.vue";
import KeyValueCardItem from "@/components/KeyValueCardItem.vue";
import InvoiceRejectModal from "@/components/Modal/InvoiceRejectModal.vue";

export default {
  name: "InvoiceDetail",
  components: {
    InvoiceRejectModal,
    KeyValueCardItem,
    KeyValueCard,
    InvoiceDetailCard,
    ConfirmModal,
    InvoiceProductCard,
    VButton,
  },
  data() {
    return {
      invoice: {},
      confirmProceedModal: false,
      invoiceRejectModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
    },
    proceed(confirm) {
      if (confirm) {
        this.confirmProceedModal = true
        return
      }

      proceedInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.confirmProceedModal = false
            this.$infoModal.value = resp.data.message
            this.$router.replace({name: 'InventoryCarrierInvoiceList'})
          })
    },
    invoiceRejected() {
      this.invoiceRejectModal = false
      this.$router.replace({name: 'InventoryCarrierInvoiceList'})
    },
  }
}
</script>

<style scoped>

</style>
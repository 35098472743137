<template>
  <ModalView title="مشخصات محصول">

    <div v-if="specs.length > 0">

      <div v-for="spec in specs"
           :key="spec.SpecId"
           class="grid grid-cols-2 mt-4 items-center">

        <label>{{ spec.Name }}</label>
        <VInput placeholder="مقدار ..." class="!py-1" v-model="spec.Value" v-if="spec.IsText"/>

        <select class="w-full border border-blue-700 rounded-lg p-1" v-model="spec.Value" v-else>
          <option :value="undefined">ندارد</option>
          <option
              v-for="value in spec.Values"
              :key="value.SpecValueId"
              :value="value.SpecValueId">
            {{ value.Name }}
          </option>
        </select>
      </div>

    </div>

    <div v-else-if="!$loading.value" class="mt-4 text-gray-600">مشخصه ای برای این محصول تعریف نشده است.</div>

    <LoadingSpinner class="w-10 mx-auto mt-4" v-else/>

    <VButton class="mt-4" @click="updateSpec" v-if="specs.length > 0">ثبت</VButton>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {getProductSpecs, updateProductSpec} from "@/API/AdminAPI";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import LoadingSpinner from "@/components/General/LoadingSpinner";

export default {
  name: "EditProductSpecModal",
  emits: ['updated'],
  props: ['product'],
  components: {LoadingSpinner, VButton, VInput, ModalView},
  data() {
    return {
      specs: []
    }
  },
  methods: {
    updateSpec() {
      updateProductSpec(this.product.ProductId, this.$helper.filterObjectArray(this.specs, ['SpecId', 'Value']))
          .then(() => this.$emit('updated'))
          .catch(err => this.$errorModal.value = err.response.data.message)
    }
  },
  watch: {
    product: {
      deep: true,
      async handler() {
        this.specs = []
        if (this.product.ProductId) {
          this.specs = (await getProductSpecs(this.product.ProductId)).data.data
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست محصولات</h1>
  </div>

  <!-- Filters -->
  <div class="intro-y grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4 xl:w-1/2" v-if="showFilter">
    <VInput placeholder="جستجو نام" class="normal-digits" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
    <!-- Category Filter -->
    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.category"
        :options="categories"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="دسته بندی"/>

    <!-- Brand Filter -->
    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.brand"
        :options="brands"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="برند"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="parseInt(productPage.PageCount)"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <EditProductModal
      v-model="editProduct"
      :editProduct="editProduct"
      @updated="updated"/>

  <EditProductSpecModal
      v-model="editProductSpec"
      :product="editProductSpec"
      @updated="this.editProductSpec = false"/>
</template>

<script>
import VCard from "@/components/General/VCard";
import {getProducts} from "@/API/AdminAPI";
import EditProductModal from "@/components/Modal/Admin/EditProductModal";
import VInput from "@/components/General/VInput";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import EditProductSpecModal from "@/components/Modal/Admin/EditProductSpecModal";
import Multiselect from "@vueform/multiselect";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "CategoryPage",
  components: {
    EditProductSpecModal,
    VInput, EditProductModal, VCard, VPagination, Multiselect
  },
  data() {
    return {
      productPage: {},
      categories: [],
      brands: [],
      products: [],
      showFilter: true,

      editRelevancy: false,
      editProduct: false,
      editProductSpec: false,

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        category: '',
        brand: '',
      }
    }
  },
  created() {
    if (this.$route.query.page)
      this.filter = {
        q: this.$route.query.q,
        page: parseInt(this.$route.query.page),
        category: this.$route.query.category,
        brand: this.$route.query.brand,
      }
    else
      this.getProducts()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => this.editProduct = this.products.find(product => product.ProductId == element.dataset.id)
      })
      // Spec Buttons
      let specButtons = document.querySelectorAll("div[data-btn-type='spec']")
      specButtons.forEach(element => {
        element.onclick = () => this.editProductSpec = this.products.find(product => product.ProductId == element.dataset.id)
      })
      // Relevancy Buttons
      let relevancyButtons = document.querySelectorAll("div[data-btn-type='relevancy']")
      relevancyButtons.forEach(element => {
        element.onclick = () => this.$router.push({'name': 'AdminProductDetail', params: {id: element.dataset.id}})
      })
    });
  },
  methods: {
    async getProducts() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')

      this.productPage = (await getProducts(this.filter, this.firstCall)).data.data
      this.products = this.productPage.Products

      if (this.productPage.Categories.length > 0)
        this.categories = this.productPage.Categories.map(category => ({
          label: category.Name,
          value: category.CategoryId
        }))

      if (this.productPage.Brands.length > 0)
        this.brands = this.productPage.Brands.map(brand => ({
          label: brand.Name,
          value: brand.BrandId
        }))

      this.firstCall = 0

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    updated(editedProduct) {
      this.editProduct = false
      let index = this.products.findIndex(product => product.ProductId === editedProduct.ProductId)
      this.products[index] = editedProduct
      this.refreshTable()
    },
    refreshTable() {
      this.tabulator.setData(this.products)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,

        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'ProductId'
          },
          {
            title: 'نام',
            field: 'Name',
            formatter: cell => {
              return `<div class="flex flex-col lg:flex-row lg:items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Images?.[0]?.Path)}" alt="" class="${cell.getData().Images?.[0]?.Path ? 'w-14 h-14 mb-2 lg:mb-0' : ''}">
                        <span class="whitespace-pre-wrap normal-digits ${cell.getData().Images?.[0]?.Path ? 'lg:mr-2' : ''}">${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'محدودیت ها',
            field: 'MinCapacity',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div class="flex flex-col">
                         <div>سقف سفارش: ${cell.getData().MaxOrder ? cell.getData().MaxOrder + ' عدد' : 'ندارد'}</div>
                      </div>`
            }
          },
          {
            title: 'دسته بندی',
            field: 'Category.Name',
            vertAlign: 'middle',
          },
          {
            title: 'برند',
            field: 'Brand.Name',
            vertAlign: 'middle'
          },
          {
            title: 'وزن',
            field: 'Weight',
            vertAlign: 'middle'
          },
          {
            title: 'حجم',
            field: 'Volume',
            vertAlign: 'middle'
          },
          {
            title: 'عملیات',
            field: 'ProductId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex flex-wrap gap-x-4">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-blue-600 cursor-pointer" data-btn-type="spec" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                            </svg>
                            <span class="mr-1 text-xs">مشخصات</span>
                        </div>
                        <div class="flex items-center text-purple-600 cursor-pointer" data-btn-type="relevancy" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                            </svg>
                            <span class="mr-1 text-xs">کالای مکمل</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getProducts()
        this.$router.replace({
          name: 'AdminProductPage',
          query: this.filter
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>

  <LoadingProgressBar/>

  <DashboardPage v-if="$route.path !== '/login'"/>

  <router-view v-else/>

  <ErrorModal v-model="$errorModal.value"/>

  <InfoModal v-model="$infoModal.value"/>

  <ImageModal v-model="$imageModal.value"/>

</template>
<script>
import ErrorModal from "@/components/Modal/General/ErrorModal";
import InfoModal from "@/components/Modal/General/InfoModal";
import LoadingProgressBar from "@/components/General/LoadingProgressBar";
import {fcmMessage} from "@/utils/fcm";
import {useToast} from "vue-toastification";
import DashboardPage from "@/views/Layout/DashboardPage";
import ImageModal from "@/components/Modal/General/ImageModal";

export default {
  components: {ImageModal, DashboardPage, LoadingProgressBar, InfoModal, ErrorModal},
  mounted() {
      fcmMessage(payload => {
        console.log(payload)
        this.$store.dispatch('addNotification', payload)
        const toast = useToast()
        toast(payload.notification.body, {
          position: "top-left",
          timeout: payload.data.Duration * 1000,
          rtl: true,
          type: this.getNotificationType(Number(payload.data.Priority))
        })
        let audio = new Audio(require('./assets/notification.wav'));
        audio.play();
        navigator.vibrate([200, 200, 200, 200, 400])
      })
  },
  methods: {
    getNotificationType(priority) {
      switch (priority) {
        case 1:
          return 'error'
        case 2:
          return 'warning'
        case 3:
          return 'success'
        default:
          return 'info'
      }
    }
  }
}
</script>
<template>
  <VCard noPadding>
    <div class="flex justify-between items-center p-4">
      <h1 class="text-lg">سوابق گردش سفارش</h1>

      <VButton class="w-auto" @click="getLog">نمایش</VButton>
    </div>

    <div ref="table"></div>

  </VCard>
</template>

<script>
import VCard from "@/components/General/VCard.vue";
import {getInvoiceLogs} from "@/API/API.js";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VButton from "@/components/General/VButton.vue";

export default {
  name: "InvoiceLog",
  props: ['invoiceId'],
  components: {VButton, VCard},
  data() {
    return {
      logs: []
    }
  },
  mounted() {
    this.initTable()
  },
  methods: {
    refreshTable() {
      this.tabulator.setData(this.logs)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'ردیف',
            field: 'LogId',
            formatter: cell => cell.getRow().getPosition()
          },
          {
            title: 'وضعیت',
            field: 'State.Name',
          },
          {
            title: 'مسئول مربوطه',
            field: 'Staff.Name',
          },
          {
            title: 'تاریخ',
            field: 'CreateDate',
          },
        ],
      });
    },
    async getLog() {
      this.logs = (await getInvoiceLogs(this.invoiceId)).data.data
      this.refreshTable()
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="intro-y mb-4" @click="accept" v-if="invoiceReturn.Acceptable"
               :loading="$loading.value">
        قبول
      </VButton>
      <div class="intro-y mb-4" v-else-if="canEdit">
        <VButton outline @click="back" :loading="$loading.value">
          برگشت مرجوعی به پنل پشتیبان
        </VButton>
        <VButton class="mt-4" @click="this.editModal = true" :loading="$loading.value">انتخاب وضعیت</VButton>
      </div>

      <!-- InvoiceReturn Info-->
      <InvoiceReturnInfoCard :invoiceReturn="invoiceReturn"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Items -->
      <InvoiceReturnProductsTable
          :items="invoiceReturn.Items"
          type="supporter"
          :canEdit="canEdit"
          @returnDetailEdited="returnDetailEdited"
          @delete-item="deleteItem"/>

      <!-- Coordination -->
      <CoordinationsTable
          :coordinations="invoiceReturn.Coordinations"
          type="invoice-return"
          :id="invoiceReturn.InvoiceReturnId"
          :canEdit="canEdit"
          @submitted="coordinationSubmitted"/>

      <InvoiceReturnLog
          class="mt-4"
          :invoice-return-id="this.invoiceReturn.InvoiceReturnId"/>

    </div>
  </div>

  <EditInvoiceReturnModal
      v-model="editModal"
      :invoiceReturn="invoiceReturn"
      @submitted="confirmed"/>

</template>

<script>
import {acceptInvoiceReturn, backInvoiceReturn, getInvoiceReturn} from "@/API/API";
import VButton from "@/components/General/VButton";
import {mapState} from "vuex";
import InvoiceReturnInfoCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnInfoCard";
import CoordinationsTable from "@/components/CoordinationsTable";
import InvoiceReturnProductsTable from "@/components/InvoiceReturn/InvoiceReturnProductsTable";
import EditInvoiceReturnModal from "@/components/Modal/Operator/EditInvoiceReturnModal";
import InvoiceReturnLog from "@/components/InvoiceReturn/InvoiceReturnLog.vue";

export default {
  name: "InvoiceReturnDetail",
  components: {
    InvoiceReturnLog,
      EditInvoiceReturnModal,
    InvoiceReturnProductsTable,
    CoordinationsTable,
    InvoiceReturnInfoCard,
    VButton
  },
  data() {
    return {
      invoiceReturn: {},

      editModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoiceReturn.StaffId === this.user.UserId
    },
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoiceReturn(this.$route.params.id, 'supporter')
          .then(resp => Object.assign(this.invoiceReturn, resp.data.data))
    },
    back() {
      backInvoiceReturn(this.$route.params.id, 'supporter')
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    confirmed() {
      this.editModal = false
      this.$router.push({
        name: 'SupporterInvoiceReturnListAll'
      })
    },
    returnDetailEdited(returnDetail) {
      let index = this.invoiceReturn.Items.findIndex(item => item.InvoiceReturnDetailId === returnDetail.InvoiceReturnDetailId)
      this.invoiceReturn.Items[index] = returnDetail
    },
    coordinationSubmitted(coordination) {
      this.invoiceReturn.Coordinations.push(coordination)
    },
    deleteItem(InvoiceReturnDetailId) {
      let deleteIndex = this.invoiceReturn.Items.findIndex(item => item.InvoiceReturnDetailId === InvoiceReturnDetailId)
      this.invoiceReturn.Items.splice(deleteIndex, 1)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">مرجوعی ها</h1>
  </div>

  <InvoiceReturnListTable
      :show-filter="showFilter"
      type="inventory-staff-receive"/>
</template>

<script>
import InvoiceReturnListTable from "@/components/InvoiceReturn/InvoiceReturnListTable";

export default {
  name: "InvoiceReturnList",
  components: {InvoiceReturnListTable},
  data() {
    return {
      showFilter: true,
    }
  },
}
</script>

<style scoped>

</style>
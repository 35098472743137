<template>
  <ModalView @closed="transferStocks = []" title="انتقال پالت" @opened="getData">
    <form @submit.prevent="submit">
      <!-- Products -->
      <div class="border-b pb-2 last-of-type:border-0"
           v-for="(stock, index) in stocks"
           :key="stock.StockId">
        <div class="flex justify-between items-center mt-4">
          <img :src="$helper.imageURL(stock.Product.ImagePath)" alt="" class="w-10 h-10">
          <span class="ml-auto mr-2">{{ stock.Product.Name }}</span>
          <span class="mx-4">{{ stock.StockAmount }} عدد</span>
          <VInput type="number" class="w-16" placeholder="تعداد"
                  :data-index="index" ref="input" required :min="1"/>
        </div>
      </div>
      <!-- Footer -->
      <div class="flex items-center mt-4">
        <VSelect v-model="destination" required>
          <option
              v-for="destination in destinationsList"
              :key="destination.LookupId"
              :value="destination.LookupId">
            {{ destination.Name }}
          </option>
        </VSelect>
        <VButton class="mr-4 ">ثبت</VButton>
      </div>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VButton from "@/components/General/VButton";
import VInput from "@/components/General/VInput";
import VSelect from "@/components/General/VSelect";
import {getLookupCombos, transferStocks} from "@/API/API";

export default {
  name: "TransferStockModal",
  components: {VSelect, VInput, VButton, ModalView},
  props: ['stocks', 'type'],
  emits: ['submitted'],
  data() {
    return {
      transferStocks: [],
      destinationsList: '',
      destination: '',
    }
  },
  computed: {
    subSystemMenuId() {
      return this.type === 'inventory-manager' ? 103020302 : 103030102
    }
  },
  methods: {
    async getData() {
      this.destinationsList = (await getLookupCombos(103020302)).data.data.Lookups.Lookups35
    },
    submit() {
      this.transferStocks = []

      this.$refs.input.forEach(input => this.transferStocks.push({
        StockId: this.stocks[input.$el.dataset.index].StockId,
        TrackingId: this.stocks[input.$el.dataset.index].TrackingId,
        Amount: input.$el.firstChild.value
      }))

      transferStocks(this.destination, this.transferStocks, this.subSystemMenuId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted')
          })
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="اسکن QR">
    <qrcode-stream @decode="onDecode"></qrcode-stream>
  </ModalView>
</template>

<script>
import {QrcodeStream} from 'vue3-qrcode-reader'
import ModalView from "@/components/Modal/General/ModalView";

export default {
  name: "QRScannerModal",

  emits: ['QR'],
  components: {ModalView, QrcodeStream},
  methods: {
    onDecode(decodedString) {
      this.$emit('QR', decodedString)
    }
  }
}
</script>

<style scoped>

</style>
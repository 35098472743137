<template>
  <KeyValueCard :title="`جزئیات سفارش (${invoice.InvoiceId})`">
    <KeyValueCardItem title="تاریخ ثبت" :value="invoice.CreateDate" icon="CalendarIcon"/>
    <KeyValueCardItem title="تاریخ تحویل" :value="invoice.DeliveryDateTime?.DeliveryDate" icon="CalendarIcon"/>
    <KeyValueCardItem title="ساعت تحویل" :value="invoice.DeliveryDateTime?.Description" icon="ClockIcon"/>
    <KeyValueCardItem title="وضعیت" :value="invoice.State?.Name" icon="ClipboardListIcon" :valueClass="$helper.renderInvoiceState(invoice.State?.LookupId)"/>
    <KeyValueCardItem title="علت رد" :value="invoice.RejectReason?.Name" icon="XIcon" v-if="invoice.RejectReason?.Name"
                      valueClass="text-red-500"/>
    <KeyValueCardItem title="توضیحات رد" :value="invoice.RejectDescription" icon="XIcon" v-if="invoice.RejectDescription"
                      valueClass="text-red-500"/>
    <KeyValueCardItem title="توضیحات مشتری" :value="invoice.CustomerDesc ?? '-'" icon="ChatIcon" last/>
  </KeyValueCard>
</template>

<script>
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
export default {
  name: "InvoiceDetailCard",
  props: ['invoice'],
  components: {KeyValueCardItem, KeyValueCard}
}
</script>

<style scoped>

</style>
<template>
  <ModalView
      title="ایجاد منطقه"
      @opened="initMap">

    <form @submit.prevent="submit">
      <div id="map" class="h-64" ref="test"></div>

      <VInput class="mt-4" placeholder="نام منطقه" v-model="zone.name" required/>

      <VButton class="mt-4">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createInventoryZone, updateInventoryZone} from "@/API/AdminAPI";

import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

export default {
  name: "CreateZoneModal",
  props: ['inventory', 'editZone'],
  emits: ['created', 'updated'],
  components: {VButton, VInput, ModalView},
  data() {
    return {
      map: '',
      zone: {
        name: '',
        geoJSON: ''
      }
    }
  },
  methods: {
    submit() {
      let fg = L.featureGroup();
      this.map.pm.getGeomanLayers().forEach((layer) => {
        if (layer instanceof L.Path && layer.pm) {
          fg.addLayer(layer);
        }
      });

      if (!fg.toGeoJSON().features[0]) {
        this.$errorModal.value = 'لطفا منطقه را بر روی نقشه مشخص کنید'
        return
      }

      // add zone name to GeoJSON properties
      this.zone.geoJSON = fg.toGeoJSON();
      this.zone.geoJSON.features[0].properties = {
        name: this.zone.name
      }

      if (this.editZone) {
        updateInventoryZone(this.editZone.InventoryZoneId, this.zone)
            .then(resp => {
              this.$emit('updated', resp.data.data)
            })
      } else {
        createInventoryZone(this.inventory.InventoryId, this.zone)
            .then(resp => {
              this.$emit('created', resp.data.data)
            })
      }
    },
    initMap() {
      // Timeout for the DOM to render
      setTimeout(() => {
        this.map = L.map('map', {
          center: [this.inventory.Lat, this.inventory.Long],
          zoom: 11,
          zoomControl: false
        })
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

        let icon = L.icon({
          iconUrl: require('/src/assets/img/map_marker_building.png'),
          iconSize: [30, 30], // size of the icon
        });

        // Add Clicked Location Marker
        L.marker([this.inventory.Lat, this.inventory.Long], {icon, draggable: true})
            .addTo(this.map)

        this.map.pm.setGlobalOptions({
          templineStyle: {color: 'red'},
          hintlineStyle: {color: 'red'},
          pathOptions: {color: 'red'}
        })

        // add Leaflet-Geoman controls with some options to the map
        this.map.pm.addControls({
          position: 'topleft',
          drawMarker: false,
          drawPolyline: false,
          drawCircleMarker: false,
          drawCircle: false,
          cutPolygon: false,
          rotateMode: false,
          drawText: false
        });

        // Add Inventory Polygon
        L.geoJSON(this.inventory.GeoJSON, {pmIgnore: true}).addTo(this.map)

        // Add Zone Polygon
        this.inventory.Zones.forEach(zone => {
          L.geoJSON(zone.GeoJSON, {
            pmIgnore: true,
            style: {color: 'green'},
            onEachFeature: (feature, layer) => {
              layer.bindPopup(feature.properties.name, {closeButton: false})
            }
          }).addTo(this.map)
        })

        if (this.editZone) {
          L.geoJSON(this.editZone.GeoJSON, {style: {color: 'red'}}).addTo(this.map)
          this.zone.name = this.editZone.Name
        } else {
          this.zone.name = ''
        }

      }, 100)
    }
  },
}
</script>

<style scoped>

</style>
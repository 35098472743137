<template>
  <ModalView title="تعلیق سفارشات" @closed="resetData" @opened="getData">

    <form @submit.prevent="suspend">

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          :options="states"
          v-model="suspendData.stateId"
          :searchable="true"
          placeholder="وضعیت"
          required/>

      <Multiselect
          dir="rtl"
          class="mt-4 border-primary"
          :options="rejectReasons"
          v-model="suspendData.rejectReasonId"
          :searchable="true"
          placeholder="دلیل رد"
          required/>

      <VInput class="mt-4" placeholder="توضیحات" v-model="suspendData.rejectDescription" required/>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import {getLookupCombos, suspendInvoices} from "@/API/API.js";
import Multiselect from "@vueform/multiselect";
import VButton from "@/components/General/VButton.vue";
import VInput from "@/components/General/VInput.vue";

let suspendModel = {
  stateId: '',
  rejectReasonId: '',
  rejectDescription: '',
  invoiceIds: []
}

export default {
  name: "SuspendInvoiceModal",
  emits: ['submit'],
  props: ['invoiceIds'],
  components: {ModalView, Multiselect, VButton, VInput},
  data() {
    return {
      rejectReasons: [],
      states: [],

      suspendData: this.$helper.cloneObject(suspendModel)
    }
  },
  methods: {
    async getData() {
      let data = (await getLookupCombos('103020201')).data.data
      this.states = data.Lookups.InvoiceStates.map(state => ({
        label: state.Name,
        value: state.LookupId
      }))

      this.rejectReasons = data.Lookups.DenyReasonStates.map(reason => ({
        label: reason.Name,
        value: reason.LookupId
      }))
    },
    suspend() {
      this.suspendData.invoiceIds = this.invoiceIds

        suspendInvoices(this.suspendData)
            .then((resp) => {
              this.$infoModal.value = resp.data.message
              this.$emit('submit')
              this.resetData()
            })
    },
    resetData() {
      this.suspendData = this.$helper.cloneObject(suspendModel)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="ایجاد روز تعطیل" @opened="getTimeSlices">

    <form @submit.prevent="editMode ? updateHoliday() : createHoliday()">

      <DatePicker
          v-model="newHoliday.date"
          format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
          append-to="body"
          auto-submit
          clearable
          :min="new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)"
          placeholder="انتخاب تاریخ"
          :disabled="editMode"
      />

      <Multiselect
          dir="rtl"
          class="mt-4"
          v-model="newHoliday.openTimeSlices"
          :options="timeSlices"
          mode="tags"
          :searchable="true"
          placeholder="انتخاب بازه های زمانی باز"/>

      <VButton class="mt-4">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import {createHolidayCalendar, deleteHolidayCalendar, getLookupCombos} from "@/API/API.js";
import {mapGetters} from "vuex";
import VButton from "@/components/General/VButton.vue";
import DatePicker from "vue3-persian-datetime-picker";
import Multiselect from "@vueform/multiselect";

const newHolidayModel = {
  date: '',
  inventory: '',
  openTimeSlices: [],
}

export default {
  name: "CreateHolidayCalendarModal",
  emits: ['submitted'],
  props: ['editHoliday'],
  components: {VButton, ModalView, DatePicker, Multiselect},
  computed: {
    ...mapGetters(['selectedInventory']),
  },
  data() {
    return {
      timeSlices: [],
      editMode: false,
      workdayTypes: [],
      newHoliday: {...newHolidayModel},
    }
  },
  methods: {
    async getTimeSlices() {
      this.timeSlices = (await getLookupCombos(103020800)).data.data.TimeSlices.map(timeSLice => ({
        label: timeSLice.StartTime + ' تا ' + timeSLice.EndTime,
        value: timeSLice.TimeSliceId,
      }))
    },
    createHoliday() {
      this.newHoliday.inventory = this.selectedInventory
      createHolidayCalendar(this.newHoliday)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted', resp.data.data)
          })
    },
    updateHoliday() {
      deleteHolidayCalendar(this.editHoliday.HolidayCalendarId, this.newHoliday)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submitted')
          })
    }
  },
  watch: {
    editHoliday(editHoliday) {
      if (editHoliday.HolidayCalendarId) {
        this.editMode = true
        this.newHoliday.dates = editHoliday.WorkDate
        this.newHoliday.type = editHoliday.TypeLId
      } else {
        this.editMode = false
        this.newHoliday = {...newHolidayModel}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y grid grid-cols-2 lg:grid-cols-4 gap-4 mt-4 xl:w-3/5">
    <DatePicker
        v-model="filter.date"
        class="-mt-2"
        format="YYYY-MM-DD"
        display-format="jYYYY-jMM-jDD"
        append-to="body"
        auto-submit
        clearable
        @change="dateChanged"
        placeholder="انتخاب تاریخ"/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.deliveryDateTimeId"
        :options="deliveryTimes"
        :searchable="true"
        @change="distributorVehicles = []; filter.distributorVehicleId = ''"
        placeholder="بازه تحویل"/>

    <Multiselect
        dir="rtl"
        class="border-none shadow"
        v-model="filter.distributorVehicleId"
        :options="distributorVehicles"
        :searchable="true"
        placeholder="موزع"/>

    <div class="mr-4 flex items-center">
      <label>بروزرسانی</label>
      <VSwitch class="mr-2" v-model="autoRefresh" :value="0"/>
    </div>
  </div>

  <VCard class="mt-4" no-padding>
    <div id="map" class="z-0 h-[80vh]"></div>
  </VCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import L from "leaflet";
import {getLocation} from "@/API/API";
import 'overlapping-marker-spiderfier-leaflet'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import Multiselect from "@vueform/multiselect";
import VSwitch from "@/components/General/VSwitch.vue";
import DatePicker from "vue3-persian-datetime-picker";

export default {
  name: "DistributorMap",
  components: {VSwitch, VCard, Multiselect, DatePicker},
  data() {
    return {
      locations: [],

      deliveryTimes: [],
      distributorVehicles: [],

      filter: {
        date: '',
        deliveryDateTimeId: '',
        distributorVehicleId: '',
      },

      markerLayer: '',
      autoRefresh: 0,
      timerId: ''
    }
  },
  computed: {
    invoiceInvoiceReturns() {
      if (this.locations.DistributorAssignments.Invoices)
        return this.locations.DistributorAssignments.Invoices.concat(this.locations.DistributorAssignments.InvoiceReturns)
      else
        return []
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    async getLocations() {
      this.locations = (await getLocation(this.filter)).data.data

      if (this.locations.DeliveryDateTimes.length > 0)
        this.deliveryTimes = this.locations.DeliveryDateTimes.map(time => ({
          label: time.Description,
          value: time.DeliveryDateTimeId,
        }))

      if (this.locations.Distributors.length > 0)
        this.distributorVehicles = this.locations.Distributors.map(time => ({
          label: time.Name,
          value: time.DistributorVehicleId,
        }))

      this.addMarker()
    },
    initMap() {
      this.map = L.map('map', {
        center: [36.310699, 59.599457],
        zoom: 12,
        zoomControl: false,
        fullscreenControl: true,
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
      this.markerLayer = L.layerGroup().addTo(this.map);

      this.addInventoryIcon()
    },
    addMarker() {
      this.markerLayer.clearLayers()

      let redIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker.png'),
        iconSize: [30, 30], // size of the icon
      });

      let blueIcon = L.icon({
        iconUrl: require('/src/assets/img/map-marker-blue.png'),
        iconSize: [30, 30], // size of the icon
      });

      let greenIcon = L.icon({
        iconUrl: require('/src/assets/img/top-car-icon.png'),
        iconSize: [18, 30], // size of the icon
      });

      this.addInventoryIcon()

      if (this.locations.DistributorAssignments.RoutingData) {
        L.Polyline.fromEncoded(this.locations.DistributorAssignments.RoutingData, {color: 'red'}).addTo(this.markerLayer)

        let latLongs = []
        this.locations.DistributorAssignments.LatLongs.forEach(location => {
          latLongs.push([location.Lat, location.Long])
          L.marker([location.Lat, location.Long], {icon: greenIcon}).addTo(this.markerLayer)
        })
        L.polyline(latLongs, {color: 'blue'}).addTo(this.markerLayer)
      }

      this.invoiceInvoiceReturns.forEach(invoice => {
        L.marker([invoice.Lat, invoice.Long], {
          icon: invoice.InvoiceReturnId ? blueIcon : redIcon
        })
            .bindPopup(`<div class="flex flex-col items-center">
                <span>${invoice.InvoiceReturnId ? 'مرجوعی' + invoice.InvoiceReturnId : 'سفارش' + invoice.InvoiceId}</span>
                <a href="${invoice.InvoiceReturnId ? '/distributor-manager/invoice-return/' + invoice.InvoiceReturnId : '/distributor-manager/invoice/' + invoice.InvoiceId}">
                جزئیات
                </a>
            </div>`, {closeButton: false})
            .addTo(this.markerLayer)
      })
    },
    addInventoryIcon() {
      let buildingIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker_building.png'),
        iconSize: [30, 30],
      });

      // Inventory Building Marker
      let inventory = this.$store.getters.userInventories.find(inventory => inventory.InventoryId == this.$store.getters.selectedInventory);
      L.marker([inventory.Lat, inventory.Long], {icon: buildingIcon})
          .addTo(this.markerLayer)
          .bindPopup('انبار', {closeButton: false})

    },
    dateChanged() {
      this.deliveryTimes = []
      this.distributorVehicles = []
      this.filter.deliveryDateTimeId = ''
      this.filter.distributorVehicleId = ''
    }
  },
  unmounted() {
    clearInterval(this.timerId)
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getLocations()
      }
    },
    autoRefresh() {
      if (this.autoRefresh)
        this.timerId = setInterval(() => this.getLocations(), process.env.VUE_APP_DISTRIBUTOR_MAP_REFRESH_TIME)
      else
        clearInterval(this.timerId)
    }
  }
}
</script>

<style>
.leaflet-marker-pane > * {
  @apply transition-transform duration-500
}
</style>
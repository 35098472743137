<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست برند ها</h1>
    <VButton class="!w-auto" @click="brandModal = true;  editBrand = {}">برند جدید</VButton>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو نام" class="md:w-2/3 lg:w-2/3" v-model.lazy="this.filter.q" @input="this.filter.page = 1"
            shadow/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="this.filter.page"
      :pages="brands.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateBrandModal
      v-model="brandModal"
      :editBrand="editBrand"
      @created="created"
      @updated="updated"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      text="آیا از حذف این برند مطمئن هستید ؟"
      @confirm="deleteBrand"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import VButton from "@/components/General/VButton";
import CreateBrandModal from "@/components/Modal/Admin/CreateBrandModal";
import {deleteBrand, getBrands} from "@/API/AdminAPI";
import VInput from "@/components/General/VInput";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "BrandPage",
  components: {
    ConfirmModal,
    VInput,
    CreateBrandModal,
    VButton,
    VCard,
    VPagination,
  },
  data() {
    return {
      brands: [],
      brandModal: false,
      editBrand: {},
      showFilter: true,

      filter: {
        q: '',
        page: 1,
      },

      confirmDeleteModal: false,
      deleteIndex: '',
    }
  },
  created() {
    this.getBrands()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.editBrand = this.brands.Brands.find(brand => brand.BrandId == element.dataset.id)
          this.brandModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = this.brands.Brands.findIndex(brand => brand.BrandId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
    });
  },
  methods: {
    async getBrands() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.brands = (await getBrands(this.filter.q, this.filter.page)).data.data
      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    created(brand) {
      this.brandModal = false
      this.brands.Brands.unshift(brand)
      this.refreshTable()
    },
    updated(editBrand) {
      this.brandModal = false
      this.editBrand = false
      let index = this.brands.Brands.findIndex(brand => brand.BrandId === editBrand.BrandId)
      this.brands.Brands[index] = editBrand
      this.refreshTable()
    },
    deleteBrand() {
      this.confirmDeleteModal = false
      deleteBrand(this.brands.Brands[this.deleteIndex].BrandId)
          .then(() => {
            this.brands.Brands.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
          .catch(err => this.$errorModal.value = err.response.data.message)
    },
    refreshTable() {
      this.tabulator.setData(this.brands.Brands)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'BrandId',
          },
          {
            title: 'نام',
            field: 'Name'
          },
          {
            title: 'نام انگلیسی',
            field: 'NameEnglish'
          },
          {
            title: 'تعداد محصولات',
            field: 'ProductsCount'
          },
          {
            title: 'عملیات',
            field: 'BrandId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex flex-wrap gap-x-4">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                             <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getBrands()
      }
    }
  }
}
</script>

<style scoped>

</style>
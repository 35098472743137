import {httpClient} from "@/API/HttpClient.js";

// Role

// Category
const getCategories = (filter, firstCall) => httpClient.get(`category`, {params: {...filter, firstCall}});
const createCategory = (category) => httpClient.post(`category`, category);
const updateCategory = (id, category) => httpClient.put(`category/${id}`, category);
const deleteCategory = (id) => httpClient.delete(`category/${id}`);

// Product
const getProducts = (filter, firstCall) => httpClient.get(`product`, {params: {...filter, firstCall}});
const getProduct = (id, filter) => httpClient.get(`product/${id}`, {params: filter});
const updateProduct = (id, product) => httpClient.put(`product/${id}`, product);
const getProductSpecs = (id) => httpClient.get(`product/${id}/spec`);
const updateProductSpec = (id, specs) => httpClient.put(`product/${id}/spec`, {specs});
const addProductComplementary = (id, productId) => httpClient.post(`product/${id}/complementary`, {productId});
const removeProductComplementary = (id, productId) => httpClient.delete(`product/${id}/complementary`, {params: {productId}});

// Brand
const getBrands = (q = '', page = 1) => httpClient.get(`brand?q=${q}&page=${page}`);
const createBrand = (brand) => httpClient.post(`brand`, brand);
const updateBrand = (id, brand) => httpClient.put(`brand/${id}`, brand);
const deleteBrand = (id) => httpClient.delete(`brand/${id}`);

// Spec
const getSpecs = (filter) => httpClient.get(`spec`, {params: filter});
const createSpec = (spec) => httpClient.post(`spec`, spec);
const updateSpec = (id, spec) => httpClient.put(`spec/${id}`, spec);
const deleteSpec = (id) => httpClient.delete(`spec/${id}`);
const getSpecProducts = (id, filter) => httpClient.get(`spec/${id}/product`, {params: filter});

// Inventory
const getInventories = (filter) => httpClient.get(`inventory`, {params: filter});
const getInventory = (id) => httpClient.get(`inventory/${id}`);
const updateInventory = (id, inventory) => httpClient.put(`inventory/${id}`, inventory);
const createInventory = (inventory) => httpClient.post(`inventory`, inventory);

// InventoryZone
const createInventoryZone = (id, zone) => httpClient.post(`inventory/${id}/zone`, zone);
const updateInventoryZone = (zoneId, zone) => httpClient.put(`inventory/zone/${zoneId}`, zone);
const deleteInventoryZone = (id) => httpClient.delete(`inventory/zone/${id}`);

// FAQ
const getFAQs = (filter) => httpClient.get(`FAQ`, {params: filter});
const createFAQ = (FAQ) => httpClient.post(`FAQ`, FAQ);
const updateFAQ = (id, FAQ) => httpClient.put(`FAQ/${id}`, FAQ);
const deleteFAQ = (id) => httpClient.delete(`FAQ/${id}`);

export {
    getCategories,
    createCategory,
    updateCategory,
    getProducts,
    getProduct,
    updateProduct,
    getProductSpecs,
    updateProductSpec,
    addProductComplementary,
    removeProductComplementary,
    getBrands,
    createBrand,
    updateBrand,
    deleteBrand,
    getSpecs,
    createSpec,
    updateSpec,
    deleteSpec,
    deleteCategory,
    getSpecProducts,
    getInventories,
    getInventory,
    createInventoryZone,
    updateInventory,
    updateInventoryZone,
    deleteInventoryZone,
    createInventory,
    getFAQs,
    createFAQ,
    updateFAQ,
    deleteFAQ,
}
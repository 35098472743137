<template>
  <div
      class="mb-4 intro-y bg-white rounded-md border-transparent overflow-hidden shadow-lg shadow-slate-200 overflow-y-auto"
      no-padding
      :class="{'hidden': $route.name === 'DistributorInvoiceDetail' || $route.name === 'DistributorInvoiceReturnDetail'}">
    <div id="map" class="z-0 h-[200px]"></div>
  </div>

  <router-view
      @distributorAssignment="setDistributorAssignment"></router-view>
</template>

<script>
import {sendLocation} from "@/API/API";
import {registerPlugin} from "@capacitor/core";
import L from "leaflet";
import 'overlapping-marker-spiderfier-leaflet'
import 'polyline-encoded'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");

export default {
  name: "IndexPage",
  data() {
    return {
      polyLine: {},
      markerLayer: {},

      distributorAssignment: {}
    }
  },
  computed: {
    invoiceInvoiceReturns() {
      if (this.distributorAssignment.Invoices)
        return this.distributorAssignment.Invoices.concat(this.distributorAssignment.InvoiceReturns)
      else
        return []
    },
  },
  created() {
    BackgroundGeolocation.addWatcher(
        {
          backgroundMessage: "Cancel to prevent battery drain.",
          backgroundTitle: "Tracking You.",
          requestPermissions: true,
          stale: false,
        },
        (location, error) => {
          console.log("Loc123", location)
          console.log("Loc123 Error", error)
          if (error) {
            if (error.code === "NOT_AUTHORIZED") {
              if (window.confirm(
                  "This app needs your location, " +
                  "but does not have permission.\n\n" +
                  "Open settings now?"
              )) {
                BackgroundGeolocation.openSettings();
              }
            }
            return console.error(error);
          }

          sendLocation({
            lat: location.latitude,
            long: location.longitude
          })
          return console.log(location);
        }
    )
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.map = L.map('map', {
        center: [36.310699, 59.599457],
        zoom: 11,
        zoomControl: false,
        fullscreenControl: true,
      })

      this.markerLayer = L.layerGroup().addTo(this.map);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    },
    addMapMarker() {
      this.map.removeLayer(this.polyLine)
      this.markerLayer.clearLayers()

      if (this.distributorAssignment.RoutingData)
        this.polyLine = L.Polyline.fromEncoded(this.distributorAssignment.RoutingData, {color: 'red'}).addTo(this.map)

      let redIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker.png'),
        iconSize: [30, 30], // size of the icon
        // popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
      });

      let blueIcon = L.icon({
        iconUrl: require('/src/assets/img/map-marker-blue.png'),
        iconSize: [30, 30], // size of the icon
      });

      let greenIcon = L.icon({
        iconUrl: require('/src/assets/img/top-car-icon.png'),
        iconSize: [18, 30], // size of the icon
        // popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
      });

      let buildingIcon = L.icon({
        iconUrl: require('/src/assets/img/map_marker_building.png'),
        iconSize: [30, 30],
      });

      let oms = new window.OverlappingMarkerSpiderfier(this.map);

      let popup = new L.Popup({closeButton: false});
      oms.addListener('click', marker => {
        popup.setContent(marker.desc);
        popup.setLatLng(marker.getLatLng());
        this.map.openPopup(popup);
      });

      // Inventory Building Marker
      let inventory = this.$store.getters.userInventories.find(inventory => inventory.InventoryId == this.$store.getters.selectedInventory);
      let marker = L.marker([inventory.Lat, inventory.Long], {icon: buildingIcon}).addTo(this.markerLayer)
      marker.desc = 'انبار'
      oms.addMarker(marker)

      // Invoice|InvoiceReturn Markers
      this.invoiceInvoiceReturns.forEach(invoice => {
        let marker = L.marker([invoice.Lat, invoice.Long], {
          icon: invoice.InvoiceReturnId ? redIcon : blueIcon
        }).addTo(this.markerLayer)
        marker.desc =
            `<div class="flex flex-col items-center">
                <span>${invoice.InvoiceReturnId ? 'مرجوعی' + invoice.InvoiceReturnId : 'سفارش' + invoice.InvoiceId}</span>
                <a href="${invoice.InvoiceReturnId ? '/distributor/invoice-return/' + invoice.InvoiceReturnId : '/distributor/invoice/' + invoice.InvoiceId}">
                جزئیات
                </a>
            </div>`
        oms.addMarker(marker)
      })

      let latLongs = []

      this.distributorAssignment.LatLongs.forEach(location => {
        latLongs.push([location.Lat, location.Long])
        L.marker([location.Lat, location.Long], {icon: greenIcon})
            .addTo(this.markerLayer)
      })

      L.polyline(latLongs, {color: 'blue'}).addTo(this.markerLayer)
    },
    setDistributorAssignment(distributorAssignment) {
      this.distributorAssignment = distributorAssignment
      this.addMapMarker()
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex items-center justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست نظرات</h1>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
    <!-- Category Filter -->
    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.state"
        :options="states"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="وضعیت"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="comments.PageCount"
      :range-size="1"
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <ConfirmModal
      v-model="confirmModal"
      title="تایید"
      :text="confirmText"
      @confirm="updateComment(confirmModal, confirmMode)"
      @cancel="confirmModal = false"
  />
</template>

<script>
import VCard from "@/components/General/VCard";
import VPagination from "@hennge/vue3-pagination";
import {getComments, updateComment} from "@/API/API";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import VInput from "@/components/General/VInput.vue";
import Multiselect from "@vueform/multiselect";

export default {
  name: "CommentList",
  components: {
    VInput,
    ConfirmModal,
    VCard,
    VPagination,
    Multiselect
  },
  data() {
    return {
      comments: [],
      states: [],

      confirmModal: false,
      confirmText: '',
      confirmMode: '',
      showFilter: true,

      firstCall: 1,
      filter: {
        page: 1,
        q: '',
        state: '',
      },
    }
  },
  created() {
    this.getComments()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Confirm Buttons
      let confirmButton = document.querySelectorAll("div[data-btn-type='confirm']")
      confirmButton.forEach(element => element.onclick = () => {
        this.confirmModal = element.dataset.id;
        this.confirmText = 'تایید نظر کاربر ؟';
        this.confirmMode = 'confirm';
      })
      // Reject Buttons
      let rejectButton = document.querySelectorAll("div[data-btn-type='reject']")
      rejectButton.forEach(element => element.onclick = () => {
        this.confirmModal = element.dataset.id;
        this.confirmText = 'رد نظر کاربر ؟';
        this.confirmMode = 'reject';
      })
    });
  },
  methods: {
    async getComments() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.comments = (await getComments(this.filter, this.firstCall)).data.data

      if (this.comments.States?.length > 0)
        this.states = this.comments.States.map(state => ({
          label: state.Name,
          value: state.LookupId,
        }))

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    updateComment(id, type) {
      updateComment(id, type)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.getComments()
            this.confirmModal = false
          })
    },
    refreshTable() {
      this.tabulator.setData(this.comments.Comments)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'کاربر',
            field: 'user.Name'
          },
          {
            title: 'محصول',
            field: 'Product.Name'
          },
          {
            title: 'نظر',
            field: 'Description',
            formatter: cell => {
              return `
                <div class="max-w-[30vh] whitespace-normal">${cell.getValue()}</div>
              `
            }
          },
          {
            title: 'وضعیت',
            field: 'State.LookupId',
            formatter: cell => {
              let state, color
              switch (cell.getValue()) {
                case 0:
                  state = 'در انتظار'
                  color = 'border-amber-500 text-amber-500'
                  break
                case 1:
                  state = 'تایید شده'
                  color = 'border-green-500 text-green-500'
                  break
                case 2:
                  state = 'رد شده'
                  color = 'border-red-500 text-red-500'
                  break
              }
              return `<span class="badge-outline ${color}">
                        ${state}
                    </span>`
            }
          },
          {
            title: 'تاریخ ثبت',
            field: 'CreateDate'
          },
          {
            title: 'عملیات',
            field: 'CommentId',
            resizable: false,
            headerSort: false,
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-green-500 cursor-pointer" data-btn-type="confirm" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                            <span class="mr-1 text-xs">تایید</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="reject" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span class="mr-1 text-xs">رد</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.firstCall = 0
        this.getComments()
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="intro-y mb-4" @click="accept"
               v-if="invoice.Acceptable"
               :loading="$loading.value">
        قبول و قفل سفارش
      </VButton>
      <VButton class="intro-y mb-4" outline @click="back" v-else-if="canEdit" :loading="$loading.value">
        برگشت سفارش به پنل پشتیبان
      </VButton>

      <div class="flex justify-end intro-y mb-4" v-if="canEdit">
        <VButton outline @click="rejectInvoiceModal = true">لغو سفارش</VButton>
        <VButton class="mr-4" @click="confirmSubmitInvoiceModal = true">تایید و ارجاع</VButton>
      </div>

      <InvoiceInfoCard
          :invoice="invoice"
          :canCoordinate="canEdit"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Invoice Products -->
      <InvoiceProductsTable
          :invoice="invoice"
          :canEdit="canEdit"
          @productChanged="invoice = $event"/>

      <!-- Coordination -->
      <CoordinationsTable
          :coordinations="invoice.Coordinations"
          type="invoice"
          :id="invoice.InvoiceId"
          :canEdit="canEdit"
          @submitted="coordinationSubmitted"/>

      <!-- Invoice Return-->
      <InvoiceReturnsTable
          class="mt-4"
          :invoiceReturns="invoice.Returns"/>

      <!-- Transactions -->
      <VCard class="mt-4">
        <h1 class="text-lg border-b pb-4">اطلاعات پرداخت</h1>

        <div
            v-for="transcation in invoice.Transactions"
            :key="transcation.TransactionId"
            class="mt-4 grid grid-cols-2 gap-2 mt-1 text-sm border-b pb-2">

        <span class="col-span-2">
          نوع:
          {{ transcation.Type.Name }}
        </span>

          <span>
            مقدار:
            {{ $helper.priceFormatter(transcation.Amount) }}
          </span>

          <span>
          وضعیت:
            {{ transcation.State.Name }}
          </span>

          <span>
          تاریخ:
            {{ transcation.CreateDate }}
          </span>

        </div>
      </VCard>

      <InvoiceLog
          class="mt-4"
          :invoice-id="this.invoice.InvoiceId"/>
    </div>

  </div>

  <InvoiceRejectModal
      v-model="rejectInvoiceModal"
      :invoice="invoice"
      @rejected="rejectInvoiceModal = false"
      type="supporter"/>

  <ConfirmInvoiceModal
      v-model="confirmSubmitInvoiceModal"
      :invoice="invoice"
      @submitted="invoiceSubmitted"
      type="supporter"/>

</template>

<script>
import {acceptInvoice, backInvoice, getInvoice} from "@/API/API";
import VButton from "@/components/General/VButton";
import InvoiceRejectModal from "@/components/Modal/InvoiceRejectModal";
import {mapState} from "vuex";
import ConfirmInvoiceModal from "@/components/Modal/ConfirmInvoiceModal";
import InvoiceInfoCard from "@/components/Invoice/InfoCard/InvoiceInfoCard";
import InvoiceReturnsTable from "@/components/Invoice/InvoiceReturnsTable";
import CoordinationsTable from "@/components/CoordinationsTable";
import InvoiceProductsTable from "@/components/Invoice/InvoiceProductsTable";
import InvoiceLog from "@/components/Invoice/InvoiceLog.vue";
import VCard from "@/components/General/VCard.vue";

export default {
  name: "InvoiceDetail",
  components: {
    VCard,
    InvoiceLog,
    InvoiceProductsTable,
    CoordinationsTable,
    InvoiceReturnsTable,
    InvoiceInfoCard,
    ConfirmInvoiceModal,
    InvoiceRejectModal,
    VButton,
  },
  data() {
    return {
      invoice: {},

      rejectInvoiceModal: false,
      confirmSubmitInvoiceModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoice.StaffId === this.user.UserId && this.invoice.State?.LookupId === this.$helper.invoiceState.SUPPORTER_ACCEPT
    }
  },
  created() {
    this.getInvoice()
  },
  methods: {
    async getInvoice() {
      this.invoice = (await getInvoice(this.$route.params.id)).data.data
    },
    accept() {
      acceptInvoice(this.invoice.InvoiceId)
          .then(() => this.getInvoice())
    },
    back() {
      backInvoice(this.invoice.InvoiceId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    invoiceSubmitted() {
      this.confirmSubmitInvoiceModal = false
      this.$router.replace({
        name: 'SupporterInvoiceListAll'
      })
    },
    coordinationSubmitted(coordination) {
      this.invoice.Coordinations.push(coordination)
    },
  }
}
</script>

<style scoped>

</style>
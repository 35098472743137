<template>
  <component :is="icons[icon]"/>
</template>

<script>
import * as heroIcons from "@heroicons/vue/outline";

export default {
  name: "DynamicIcon",
  props: ['icon'],
  data() {
    return {
      icons: heroIcons
    }
  },
}
</script>

<style scoped>

</style>
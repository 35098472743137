<template>
  <div class="intro-y grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4 xl:w-3/5" v-if="showFilter">
    <VInput placeholder="شماره سفارش و مرجوعی" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow />

    <Multiselect 
        dir="rtl" 
        class="border-none shadow" 
        v-model="filter.state" 
        :options="invoiceReturnStates"
        :searchable="true" 
        @change="this.filter.page = 1" 
        placeholder="وضعیت"/>

    <Multiselect 
        dir="rtl" 
        class="border-none shadow" 
        v-model="filter.deliveryDatetime" 
        :options="timeSlices"
        :searchable="true" 
        v-if="type === 'distributor' || type === 'inventory-manager'"
        @change="this.filter.page = 1; $emit('deliveryDatetimeId', $invoiceReturns)" 
        placeholder="بازه زمانی"
        :can-clear="false" 
        :canDeselect="false"/>

    <div class="flex items-center" v-if="type === 'supporter-all' || type === 'supporter-pending'">
      <span>کارهای من</span>
      <VSwitch class="mr-2" v-model="filter.myJobs" :value="filter.myJobs"/>
    </div>
  </div>

  <VPagination 
      class="my-4 justify-center md:justify-start" 
      v-model="filter.page" 
      :pages="invoiceReturns.PageCount"
      :range-size="1" 
      active-color="#1d4ed8"/>

  <VCard class="!bg-transparent mt-4" no-padding scrollable>
    <div ref="table"></div>
  </VCard>

  <ConfirmModal 
      v-model="confirmDeliverToInventoryModal" 
      title="تحویل به انبار" 
      text="از تحویل به انبار مطمئن هستید ؟"
      @confirm="deliverToInventory" 
      @cancel="confirmDeliverToInventoryModal = false"/>

</template>
<script>
import VCard from "@/components/General/VCard"
import VPagination from "@hennge/vue3-pagination"
import {mapState} from "vuex";
import {deliverInvoiceReturnToInventory, getInvoiceReturns} from "@/API/API";
import VInput from "@/components/General/VInput";
import Multiselect from "@vueform/multiselect";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VSwitch from "@/components/General/VSwitch";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";

export default {
  name: 'InvoiceReturnListTable',
  components: {ConfirmModal, VSwitch, VInput, VCard, VPagination, Multiselect},
  props: {
    type: String,
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  emits: ['deliveryDatetimeId', 'selectedInvoiceReturns'],
  data() {
    return {
      invoiceReturns: [],
      invoiceReturnStates: [],
      confirmDeliverToInventoryModal: false,
      timeSlices: [],
      selectedInvoiceReturns: [],

      firstCall: 1,
      filter: {
        page: 1,
        q: '',
        state: '',
        myJobs: 0,
        deliveryDatetime: ''
      }
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    if (this.$route.query.page)
      this.filter = {
        q: this.$route.query.q,
        page: parseInt(this.$route.query.page),
        state: this.$route.query.state,
        myJobs: parseInt(this.$route.query.myJobs),
        deliveryDatetime: this.$route.query.deliveryDatetime
      }
    this.getInvoiceReturns()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Show Buttons
      let showButtons = document.querySelectorAll("div[data-btn-type='show']")
      showButtons.forEach(element => element.onclick = () => this.goToInvoiceReturnDetail(element.dataset.id))
      // Deliver Buttons
      let deliverButtons = document.querySelectorAll("div[data-btn-type='deliver']")
      deliverButtons.forEach(element => element.onclick = () => this.confirmDeliverToInventoryModal = element.dataset.id)
    });

    this.tabulator.on("rowSelectionChanged", data => this.selectedInvoiceReturns = data)
  },
  methods: {
    async getInvoiceReturns() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')
      this.invoiceReturns = (await getInvoiceReturns(this.filter, this.firstCall)).data.data;

      if (this.invoiceReturns.States.length > 0)
        this.invoiceReturnStates = this.invoiceReturns.States.map(state => ({
          label: state.Name,
          value: state.LookupId
        }))

      if (this.invoiceReturns.DeliveryDateTimes.length > 0)
        this.timeSlices = this.invoiceReturns.DeliveryDateTimes.map(time => ({
          label: time.Description,
          value: time.DeliveryDateTimeId,
          disabled: !time.CanChoose
        }))

      if (this.firstCall && !this.filter.deliveryDatetime)
        this.filter.deliveryDatetime = this.timeSlices.find(timeSlice => !timeSlice.disabled)?.value

      this.tabulator.alertManager.clear();
      this.refreshTable()
      this.$emit('distributorAssignment', this.invoiceReturns.DistributorAssignments)
    },
    deliverToInventory() {
      deliverInvoiceReturnToInventory(this.confirmDeliverToInventoryModal)
        .then(resp => {
          this.confirmDeliverToInventoryModal = false
          this.$infoModal.value = resp.data.message
          let index = this.invoiceReturns.InvoiceReturnData.findIndex(item => item.InvoiceReturnId === resp.data.data.InvoiceReturnId)
          this.invoiceReturns.InvoiceReturnData[index] = resp.data.data
          this.refreshTable()
        })
    },
    goToInvoiceReturnDetail(invoiceReturnId) {
      let name = '';
      switch (this.type) {
        case 'supporter-all':
        case 'supporter-pending':
          name = 'SupporterInvoiceReturnDetail'
          break
        case 'inventory-manager':
        case 'distributor':
          name = 'DistributorInvoiceReturnDetail'
          break
        case 'inventory-staff-receive':
          name = 'InventoryStaffInvoiceReturnDetail'
          break
        case 'inventory-carrier':
          name = 'InventoryCarrierInvoiceReturnDetail'
          break
        case 'QC-staff':
          name = 'QCStaffInvoiceReturnDetail'
          break
      }

      this.$router.push({
        name: name,
        params: {id: invoiceReturnId}
      })
    },
    refreshTable() {
      this.tabulator.setData(this.invoiceReturns.InvoiceReturnData)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: this.type === 'inventory-manager',
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        rowFormatter: row => {
          if (row.getData().StaffId === this.user.UserId)
            row.getElement().classList.add('!bg-green-100')
        },
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'InvoiceReturnId',
          },
          {
            title: 'شناسه سفارش',
            field: 'InvoiceId'
          },
          {
            title: 'شماره مشتری',
            field: 'User.Mobile',
            visible: this.type !== 'inventory-carrier'
          },
          {
            title: 'مبلغ',
            field: 'TotalReturnPrice',
            formatter: cell => this.$helper.priceFormatter(cell.getData().TotalReturnPrice),
            visible: this.type !== 'inventory-carrier'
          },
          {
            title: 'تاریخ ثبت',
            field: 'CreateDate',
            visible: this.type !== 'inventory-carrier'
          },
          {
            title: 'تاریخ دریافت',
            field: 'DeliveryDateTime.DeliveryDate',
            visible: this.type !== 'inventory-carrier'
          },
          {
            title: 'ساعت دریافت',
            field: 'DeliveryDateTime.Description',
            visible: this.type !== 'inventory-carrier'
          },
          {
            title: 'وضعیت',
            field: 'State',
            formatter: cell => {
              return `<span class="badge-outline border-green-500">
                        ${cell.getData().State.Name}
                    </span>`
            }
          },
          {
            title: 'مسئول مربوطه',
            field: 'StaffName',
            visible: this.type.includes('supporter'),
            headerSort: false,
            formatter: cell => cell.getValue() ?? '-'
          },
          {
            title: 'عملیات',
            field: 'InvoiceReturnId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              let template = `<div class="flex">
                        <div class="flex items-center text-blue-500 cursor-pointer" data-btn-type="show" data-id="${cell.getValue()}">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                             </svg>
                             <span class="mr-1 text-xs">نمایش</span>
                        </div>`

              if (this.type === 'distributor' && cell.getData().State.LookupId === 2021)
                template += `
                 <div class="flex items-center text-purple-500 cursor-pointer mr-2" data-btn-type="deliver" data-id="${cell.getValue()}">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>
                    <span class="mr-1 text-xs">تحویل به انبار</span>
                 </div>
                `
              return template + '</div>'
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.firstCall === 0) {
          this.getInvoiceReturns()
          this.$router.replace({
            name: this.$route.name,
            query: this.filter
          })
        }

        this.firstCall = 0
      },
    },
    selectedInvoiceReturns(invoiceReturns) {
      this.$emit('selectedInvoiceReturns', invoiceReturns.map(invoiceReturn => invoiceReturn.InvoiceReturnId))
    }
  }
}
</script>

import {createStore} from 'vuex'
import {changeInventory, login, postFCMToken} from "@/API/API";
import router from "@/router";
import {httpClient} from "@/API/HttpClient.js";
import {fcmToken} from "@/utils/fcm";

export default createStore({
    state: {
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
        selectedInventory: localStorage.getItem('selectedInventory'),
        menuIds: localStorage.getItem('menuIds') ? JSON.parse(localStorage.getItem('menuIds')) : [],
        notifications: []
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        REMOVE_TOKEN(state) {
            state.token = '';
            state.selectedInventory = ''
            state.user = {}
            state.menuIds = []
            localStorage.clear()
        },
        SET_USER(state, user) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        SET_MENU_IDS(state, menuIds) {
            state.menuIds = menuIds
            localStorage.setItem('menuIds', JSON.stringify(menuIds))
        },
        SET_SELECTED_INVENTORY(state, selectedInventory) {
            state.selectedInventory = selectedInventory
            localStorage.setItem('selectedInventory', selectedInventory)
        },
        ADD_NOTIFICATION(state, notification) {
            state.notifications.push(notification)
        },
        CLEAR_NOTIFICATION(state) {
            state.notifications = []
        }
    },
    actions: {
        login(store, {mobile, password}) {
            return new Promise((resolve, reject) => {
                login(mobile, password)
                    .then(async resp => {
                        httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.token
                        fcmToken(token => postFCMToken(token))
                        resolve(resp.data)
                    })
                    .catch(err => {
                        reject(err.response)
                    })
            })
        },
        setToken({commit}, resp) {
            commit('SET_TOKEN', resp.token)
            commit('SET_USER', resp.user)
            commit('SET_MENU_IDS', resp.menuIds)
        },
        logout({commit}) {
            commit('REMOVE_TOKEN')
            router.push('/login')
        },
        setUser({commit}, user) {
            commit('SET_USER', user)
        },
        async setSelectedInventory({commit}, selectedInventory) {
            await changeInventory(selectedInventory)
            commit('SET_SELECTED_INVENTORY', selectedInventory)
        },
        addNotification({commit}, notification) {
            commit('ADD_NOTIFICATION', notification)
        },
        clearNotifications({commit}) {
            commit('CLEAR_NOTIFICATION')
        }
    },
    getters: {
        isLoggedIn: state => state.token !== '',
        token: state => 'Bearer ' + state.token,
        userInventories: state => state.user.Inventories,
        selectedInventory: state => parseInt(state.selectedInventory) || '',
        hasNotification: state => state.notifications.length > 0,
        lastNotification: state => state.notifications[state.notifications.length - 1]
    },
})

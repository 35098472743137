<template>
  <div class="intro-y bg-white rounded-md border-transparent overflow-hidden shadow-lg shadow-slate-200"
       :class="{'p-4' : !noPadding, 'h-[70vh] overflow-y-auto': scrollable}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VCard",
  props: {
    noPadding: Boolean,
    scrollable: Boolean
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView
      title="هماهنگی ها"
      @opened="modalOpened">

    <form class="mt-4" @submit.prevent="submit">
      <VSelect v-model="newCoordination.reasonLId" required>
        <option value="" disabled selected>نوع هماهنگی</option>
        <option v-for="reason in reasons"
                :key="reason.LookupId"
                :value="reason.LookupId">
          {{ reason.Name }}
        </option>
      </VSelect>

      <VInput class="mt-4" placeholder="توضیحات ..." v-model="newCoordination.description" required></VInput>

      <VButton class="mt-4" type="submit" :loading="$loading.value">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {createCoordination, getLookupCombos} from "@/API/API";
import VSelect from "@/components/General/VSelect";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";

export default {
  name: "CoordinationModal",
  props: ['id', 'type'],
  emits: ['submitted'],
  components: {VButton, VInput, VSelect, ModalView},
  data() {
    return {
      reasons: [],
      newCoordination: {
        description: '',
        reasonLId: '',
      }
    }
  },
  methods: {
    submit() {
      createCoordination(this.type, this.id, this.newCoordination)
          .then(resp => this.$emit('submitted', resp.data.data));
    },
    async modalOpened() {
      let code = ''
      switch (this.type) {
        case 'invoice':
          code = 103080103
          break
        case 'invoice-return':
          code = 103080402
          break
        case 'complaint':
          code = 103080302
          break
      }
      this.reasons = (await getLookupCombos(code)).data.data.Lookups.Lookups17
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="fixed overflow-hidden w-full bg-blue-700 z-30" v-if="$loading.value">
    <div class="h-1 shim-red"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingProgressBar",
}
</script>

<style scoped>
.shim-red::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: rgba(233, 233, 233, 0.8);
  animation: shimmer 2s linear infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
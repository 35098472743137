<template>

  <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست سفارشات</h1>

  <InvoiceListTable
      type="inventory-packer"
      :show-filter="showFilter"/>

</template>

<script>

import InvoiceListTable from "@/components/Invoice/InvoiceListTable";

export default {
  name: "InvoiceList",
  components: {InvoiceListTable},
  data() {
    return {
      showFilter: true,
    }
  },
}
</script>

<style scoped>

</style>
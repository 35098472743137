<template>
  <VCard no-padding>
    <h1 class="m-4 text-lg">محصولات مرجوعی</h1>

    <div ref="table"></div>

  </VCard>

  <EditInvoiceReturnDetailModal
      v-model="editReturnDetailModal"
      :invoiceReturnDetail="selectedReturnDetail"
      :type="type"
      @edited="returnDetailEdited"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف محصول"
      text="آیا از حذف این محصول از مرجوعی مطمئن هستید ؟"
      @confirm="deleteConfirmed"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import EditInvoiceReturnDetailModal from "@/components/Modal/EditInvoiceReturnDetailModal";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import ConfirmModal from "@/components/Modal/General/ConfirmModal.vue";

export default {
  name: "InvoiceReturnProductsTable",
  props: ['items', 'canEdit', 'type'],
  emits: ['returnDetailEdited', 'deleteItem'],
  components: {ConfirmModal, EditInvoiceReturnDetailModal, VCard},
  data() {
    return {
      tabulator: null,

      editReturnDetailModal: false,
      selectedReturnDetail: {},

      deleteIndex: '',
      confirmDeleteModal: ''
    }
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.selectedReturnDetail = this.items.find(item => item.InvoiceReturnDetailId == element.dataset.id)
          this.editReturnDetailModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = element.dataset.id
          this.confirmDeleteModal = true
        }
      })
      // Images
      let images = document.querySelectorAll("img.image")
      images.forEach(element => element.onclick = () => this.$imageModal.value = element.dataset.img)
    });
  },
  methods: {
    returnDetailEdited(returnDetail) {
      this.editReturnDetailModal = false
      this.$emit('returnDetailEdited', returnDetail)
    },
    refreshTable() {
      this.tabulator.setData(this.items)
    },
    deleteConfirmed() {
      this.confirmDeleteModal = false
      this.$emit('deleteItem', this.deleteIndex)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'نام',
            field: 'product',
            vertAlign: 'center',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().StockDetail.Product.ImageUrl)}" alt="" class="${cell.getData().StockDetail.Product.ImageUrl ? 'w-12 h-12' : ''}">
                        <span class="whitespace-pre-wrap ${cell.getData().StockDetail.Product.ImageUrl ? 'mr-2' : ''}">${cell.getData().StockDetail.Product.Name}</span>
                      </div>`
            }
          },
          {
            title: 'تصاویر',
            field: 'images',
            vertAlign: 'middle',
            formatter: cell => {
              let template = '<div class="flex">'
              cell.getData().Images.forEach(image => {
                template += `<img class="image w-12 rounded" src="${this.$helper.imageURL(image.Path)}" data-img="${this.$helper.imageURL(image.Path)}">`
              })
              return template + `</div>`
            }
          },
          {
            title: 'تعداد مشتری',
            field: 'Amount',
            vertAlign: 'middle',
            formatter: cell => cell.getData().Amount + ' عدد'
          },
          {
            title: 'تعداد ثبت شده',
            field: 'NoNeedAmount',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div class="flex flex-col">
                          <div>بدون نیاز بازگشت: ${cell.getData().NoNeedAmount !== null ? cell.getData().NoNeedAmount + ' عدد' : '-'}</div>
                          <div class="mt-1">سالم: ${cell.getData().HealthyAmount !== null ? cell.getData().HealthyAmount + ' عدد' : '-'}</div>
                          <div class="mt-1">ضایعاتی: ${cell.getData().DefectAmount !== null ? cell.getData().DefectAmount + ' عدد' : '-'}</div>
                      </div>
                      `
            }
          },
          {
            title: 'تعداد دریافت شده',
            field: 'InventoryNoNeedAmount',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div class="flex flex-col">
                          <div>بدون نیاز بازگشت: ${cell.getData().InventoryNoNeedAmount !== null ? cell.getData().InventoryNoNeedAmount + ' عدد' : '-'}</div>
                          <div class="mt-1">سالم: ${cell.getData().InventoryHealthyAmount !== null ? cell.getData().InventoryHealthyAmount + ' عدد' : '-'}</div>
                          <div class="mt-1">ضایعاتی: ${cell.getData().InventoryDefectAmount !== null ? cell.getData().InventoryDefectAmount + ' عدد' : '-'}</div>
                      </div>
                     `
            }
          },
          {
            title: 'توضیحات',
            field: 'InventoryNoNeedAmount',
            vertAlign: 'middle',
            formatter: cell => {
              return `<div class="flex flex-col">
                            <div class="font-bold">${cell.getData().ReturnReason?.Name ?? '-'}</div>
                            <div class="mt-1">${cell.getData().Description ?? '-'}</div>
                      </div>
                     `
            }
          },
          {
            title: 'عملیات',
            field: 'actions',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            visible: false,
            formatter: (cell) => {
              return `<div class="flex">
                        <div class="flex items-center text-amber-500" data-btn-type="edit" data-id="${cell.getData().InvoiceReturnDetailId}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                            <span class="mr-1 text-xs">ثبت اطلاعات</span>
                        </div>
                        <div class="flex items-center text-red-500 mr-4 cursor-pointer" data-btn-type="delete" data-id="${cell.getData().InvoiceReturnDetailId}" ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    },
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.refreshTable()
      }
    },
    canEdit() {
      if ((this.type === 'inventory-staff-receive' || this.type === 'supporter') && this.canEdit)
        this.tabulator.showColumn("actions")
    }
  }
}
</script>

<style scoped>

</style>
<template>

  <UserListTable type="inventory-manager"/>

</template>

<script>
import UserListTable from "@/components/UserListTable";

export default {
  name: "UserList",
  components: {UserListTable},
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="ویرایش پروفایل" @opened="modalOpened">

    <form @submit.prevent="submit">

      <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VInput class="mt-4" placeholder="نام و نام خانوادگی" v-model="editUser.Name"/>

      <VInput class="mt-4" placeholder="موبایل" :value="user.Mobile" disabled/>

      <fieldset class="border rounded mt-4 p-4">
        <legend class="px-2">رمزعبور (در صورت عدم تغییر خالی بگذارید)</legend>
        <VInput placeholder="رمزعبور فعلی" v-model="editUser.OldPassword" type="password"/>
        <VInput class="mt-4" placeholder="رمزعبور جدید" v-model="editUser.Password" type="password"/>
        <VInput class="mt-4" placeholder="تکرار رمزعبور جدید" v-model="editUser.PasswordRepeat" type="password"/>
      </fieldset>

      <VButton class="mt-6">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import {mapState} from "vuex";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {updateProfile} from "@/API/API";
import {Dropzone} from "dropzone";

const userModel = {
  Name: '',
  OldPassword:'',
  Password:'',
  avatar: null,
  PasswordRepeat: ''
}

export default {
  name: "EditProfileModal",
  emits: ['updated'],
  components: {VButton, VInput, ModalView},
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      editUser: {...userModel}
    }
  },
  created() {
    this.editUser = this.$helper.cloneObject(this.user, Object.keys(userModel))
  },
  methods: {
    submit() {
      this.editUser.AvatarId = this.editUser.avatar?.JCoFileId
      updateProfile(this.editUser)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$store.dispatch('setUser', resp.data.data)
            this.$emit('updated')
          })
    },
    initDropzone() {
      setTimeout(() => {
        let dropzone = new Dropzone(this.$refs.dropzone, {
          url: process.env.VUE_APP_STAFF_URL + 'image',
          headers: {'Authorization': this.$store.getters.token},
          maxFilesize: 1,
          dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
          dictDefaultMessage: 'انتخاب تصاویر',
          dictCancelUpload: 'لغو',
          dictRemoveFile: 'حذف',
          dictMaxFilesExceeded: 'حداکثر یک تصویر میتوانید آپلود کنید.',
          acceptedFiles: 'image/jpeg, image/png, image/webp',
          addRemoveLinks: true,
          maxFiles: 1,
          sending(file, xhr, formData) {
            formData.append('type', 'image')
          },
          success: (file, response) => {
            file.JCoFileId = response.JCoFileId;
            this.editUser.avatar = {
              JCoFileId: response.JCoFileId,
              Path: response.Path
            }
          },
        });

        // Load Previous Images
        if (this.editUser.avatar) {
          let mockFile = {name: 'image', size: 0, JCoFileId: this.editUser.avatar.JCoFileId};
          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(this.editUser.avatar.Path));
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          dropzone.options.maxFiles -= 1;
        }

        // Delete Image
        dropzone.on("removedfile", file => {
          if (file.JCoFileId) {
            this.editUser.avatar = null
            dropzone.options.maxFiles += 1
          }
        })
      }, 50)
    },
    modalOpened() {
      this.initDropzone()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-4">

    <!-- Invoice Detail -->
    <InvoiceDetailCard :invoice="invoice"/>

    <!-- Payment Info -->
    <KeyValueCard title="مبلغ سفارش">
      <KeyValueCardItem title="جمع فاکتور" :value="$helper.priceFormatter(invoice.ItemsCost)" icon="CashIcon"/>
      <KeyValueCardItem title="هزینه حمل" :value="$helper.priceFormatter(invoice.TransportCost)" icon="CashIcon"/>
      <KeyValueCardItem title="جمع مبالغ مرجوعی" :value="$helper.priceFormatter(invoice.TotalReturnPrice)"
                        icon="ExternalLinkIcon" valueClass="text-red-500"/>
      <KeyValueCardItem title="مبلغ قابل پرداخت" :value="$helper.priceFormatter(invoice.UnpaidPrice)" icon="CreditCardIcon" valueClass="text-green-500" last/>
    </KeyValueCard>

     <!-- DistributorAssignmentInvoice Info -->
     <KeyValueCard v-if="invoice.DistributorAssignment?.StateName" title="اطلاعات تخصیص سفارش">
      <KeyValueCardItem title="وضعیت تخصیص" :value="invoice.DistributorAssignment?.StateName" icon="ClipboardListIcon"/>
      <KeyValueCardItem title="موزع" :value="invoice.DistributorAssignment?.DistributorName" icon="UserIcon"/>
      <KeyValueCardItem title="شماره همراه" :value="invoice.DistributorAssignment?.Mobile" icon="DeviceMobileIcon" last/>
    </KeyValueCard>

     <!-- DistributorAssignmentInvoiceReturn Info -->
<!--     <KeyValueCard v-if="invoice.DistributorAssignmentInvoiceReturn.StateName" title="اطلاعات تخصیص مرجوعی" title-color="text-red-500">-->
<!--      <KeyValueCardItem title="وضعیت تخصیص" :value="invoice.DistributorAssignmentInvoiceReturn.StateName" icon="ClipboardListIcon" />-->
<!--      <KeyValueCardItem title="موزع" :value="invoice.DistributorAssignmentInvoiceReturn.DistributorName" icon="UserIcon"/>-->
<!--      <KeyValueCardItem title="شماره همراه" :value="invoice.DistributorAssignmentInvoiceReturn.Mobile" icon="DeviceMobileIcon" last/>-->
<!--    </KeyValueCard>-->

    <!-- Customer Detail -->
    <InvoiceCustomerCard :invoice="invoice"/>

    <!-- Address -->
    <KeyValueCard title="اطلاعات تحویل">
      <KeyValueCardItem title="نام تحویل گیرنده" :value="invoice.Address?.ReceiverFullName" icon="UserIcon"/>
      <KeyValueCardItem title="شماره تحویل گیرنده" :value="invoice.Address?.TelNo" icon="DeviceMobileIcon"/>
      <KeyValueCardItem title="آدرس" :value="invoice.Address?.FullAddress ?? '-'" icon="LocationMarkerIcon"
                        valueClass="font-normal text-left" last/>
    </KeyValueCard>

  </div>
</template>

<script>
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
import InvoiceDetailCard from "@/components/Invoice/InfoCard/InvoiceDetailCard";
import InvoiceCustomerCard from "@/components/Invoice/InfoCard/InvoiceCustomerCard";

export default {
  name: "InvoiceInfoCard",
  emits: ['coordination'],
  props: ['invoice', 'canCoordinate'],
  components: {
    InvoiceCustomerCard,
    InvoiceDetailCard,
    KeyValueCardItem,
    KeyValueCard,
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="intro-y flex justify-between">
    <h1 class="text-lg cursor-pointer" @click="showFilter = !showFilter">لیست دسته بندی ها</h1>
    <VButton class="!w-auto" @click="categoryModal = true; editCategory = {}">دسته بندی جدید</VButton>
  </div>

  <div class="intro-y flex mt-4 lg:w-1/2 xl:w-1/3" v-if="showFilter">
    <VInput placeholder="جستجو نام" v-model.lazy="filter.q" @input="this.filter.page = 1" shadow/>
    <!-- Category Filter -->
    <Multiselect
        dir="rtl"
        class="mr-4 border-none shadow"
        v-model="filter.parent"
        :options="parentCategories"
        :searchable="true"
        @change="this.filter.page = 1"
        placeholder="دسته بندی"/>
  </div>

  <VPagination
      class="my-4 justify-center md:justify-start"
      v-model="filter.page"
      :pages="categoryPage.PageCount"
      :range-size="1"
      active-color="#1d4ed8"
  />

  <VCard class="!bg-transparent mt-4 text-slate-600" noPadding scrollable>
    <div ref="table"></div>
  </VCard>

  <CreateCategoryModal
      v-model="categoryModal"
      :editCategory="editCategory"
      @created="created"
      @updated="updated"
      :parent-categories="parentCategories"/>

  <!-- Confirm Delete Modal -->
  <ConfirmModal
      v-model="confirmDeleteModal"
      title="حذف دسته بندی"
      text="آیا از حذف این دسته بندی مطمئن هستید ؟"
      @confirm="deleteCategory"
      @cancel="confirmDeleteModal = false"/>

</template>

<script>
import VCard from "@/components/General/VCard";
import {deleteCategory, getCategories} from "@/API/AdminAPI";
import VButton from "@/components/General/VButton";
import CreateCategoryModal from "@/components/Modal/Admin/CreateCategoryModal";
import VInput from "@/components/General/VInput";
import VPagination from "@hennge/vue3-pagination";
import "@/assets/css/pagination.css";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import Multiselect from "@vueform/multiselect";
import {TabulatorFull as Tabulator} from "tabulator-tables";

export default {
  name: "CategoryPage",
  components: {
    ConfirmModal,
    VInput, CreateCategoryModal, VButton, VCard, VPagination, Multiselect
  },
  data() {
    return {
      categoryPage: {},
      categories: [],
      parentCategories: [],
      showFilter: true,

      categoryModal: false,
      editCategory: {},

      firstCall: 1,
      filter: {
        q: '',
        page: 1,
        parent: '',
      },

      confirmDeleteModal: false,
      deleteIndex: '',
    }
  },
  created() {
    this.getCategories()
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Edit Buttons
      let editButtons = document.querySelectorAll("div[data-btn-type='edit']")
      editButtons.forEach(element => {
        element.onclick = () => {
          this.editCategory = this.categories.find(category => category.CategoryId == element.dataset.id)
          this.categoryModal = true
        }
      })
      // Delete Buttons
      let deleteButtons = document.querySelectorAll("div[data-btn-type='delete']")
      deleteButtons.forEach(element => {
        element.onclick = () => {
          this.deleteIndex = this.categories.findIndex(category => category.CategoryId == element.dataset.id)
          this.confirmDeleteModal = true
        }
      })
    });
  },
  methods: {
    async getCategories() {
      this.tabulator?.alertManager.alert('در حال بارگذاری ....')

      this.categoryPage = (await getCategories(this.filter, this.firstCall)).data.data
      this.categories = this.categoryPage.Categories

      if (this.categoryPage.ParentCategories.length > 0)
        this.parentCategories = this.categoryPage.ParentCategories.map(category => ({
          label: category.Name,
          value: category.CategoryId,
        }))

      this.firstCall = 0

      this.tabulator.alertManager.clear();
      this.refreshTable()
    },
    created(category) {
      this.categoryModal = false
      this.categories.unshift(category)
      this.refreshTable()
    },
    updated(editCategory) {
      this.categoryModal = false
      this.editCategory = {}
      let index = this.categories.findIndex(category => category.CategoryId === editCategory.CategoryId)
      this.categories[index] = editCategory
      this.refreshTable()
    },
    deleteCategory() {
      this.confirmDeleteModal = false
      deleteCategory(this.categories[this.deleteIndex].CategoryId)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.categories.splice(this.deleteIndex, 1)
            this.refreshTable()
          })
    },
    refreshTable() {
      this.tabulator.setData(this.categories)
      window.scrollTo(0, 0)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'شناسه',
            field: 'CategoryId',
            headerSort: false,
          },
          {
            title: 'نام',
            field: 'Name',
            headerSort: false,
            formatter: cell => {
              return `<div class="flex flex-col lg:flex-row lg:items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Image?.Path)}" alt="" class="${cell.getData().Image?.Path ? 'w-14 h-14 mb-2 lg:mb-0' : ''}">
                        <span class=${cell.getData().Image?.Path ? 'lg:mr-2' : ''}>${cell.getData().Name}</span>
                      </div>`
            }
          },
          {
            title: 'دسته بندی اصلی',
            field: 'parent',
            vertAlign: 'middle',
            headerSort: false,
            formatter: cell => cell.getData().ParentCategory?.Name ?? '-'
          },
          {
            title: 'تعداد زیردسته',
            field: 'SubCategoriesCount',
            vertAlign: 'middle',
            headerSort: false
          },
          {
            title: 'تعداد محصولات',
            field: 'ProductsCount',
            vertAlign: 'middle',
            headerSort: false
          },
          {
            title: 'عملیات',
            field: 'CategoryId',
            resizable: false,
            headerSort: false,
            vertAlign: 'middle',
            formatter: (cell) => {
              return `<div class="flex flex-wrap gap-x-4">
                        <div class="flex items-center text-amber-500 cursor-pointer" data-btn-type="edit" data-id="${cell.getValue()}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                            <span class="mr-1 text-xs">ویرایش</span>
                        </div>
                        <div class="flex items-center text-red-500 cursor-pointer" data-btn-type="delete" data-id="${cell.getValue()}" ">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span class="mr-1 text-xs">حذف</span>
                        </div>
                      </div>`
            }
          },
        ],
      });
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getCategories()
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <VCard noPadding>
    <div class="flex items-center justify-between m-4">
      <h1 class="text-lg">محصولات</h1>
      <VButton class="!w-auto" @click="searchModal = true" v-if="canEdit">افزودن</VButton>
    </div>

    <div ref="table"></div>

    <VButton class="w-auto float-left m-4" v-if="productChanged" @click="submitProductChanges(1)">
      ثبت تغییرات
    </VButton>
  </VCard>

  <SearchProductModal v-model="searchModal" :invoice="invoice" type="supporter"
    @added="$emit('productChanged', $event)" />

  <ModalView v-model="itemsNewPriceModal" title="تایید ویرایش سفارش">
    <div class="mt-4">
      <span>مبلغ سفارش: </span>
      <span>{{ $helper.priceFormatter(itemsNewPrice.ItemsCost) }}</span>
    </div>

    <div class="mt-4">
      <span>هزینه ارسال: </span>
      <span>{{ $helper.priceFormatter(itemsNewPrice.TransportCost) }}</span>
    </div>

    <div class="flex gap-x-4 mt-4">
      <VButton outline @click="itemsNewPriceModal = false">لغو</VButton>
      <VButton @click="submitProductChanges(2)">تایید</VButton>
    </div>
  </ModalView>

</template>
<script>
import VCard from "@/components/General/VCard"
import { TabulatorFull as Tabulator } from "tabulator-tables";
import VButton from "@/components/General/VButton";
import SearchProductModal from "@/components/Modal/Operator/SearchProductModal";
import { changeInvoiceProducts, compensateStockDetail } from "@/API/API";
import ModalView from "../Modal/General/ModalView.vue";

export default {
  name: 'InvoiceProductsTable',
  props: ['invoice', 'type', 'canEdit'],
  emits: ['productChanged'],
  components: { SearchProductModal, VButton, VCard, ModalView },
  data() {
    return {
      itemsNewPriceModal: false,
      itemsNewPrice: {
        ItemsCost: 0,
        TransportCost: 0
      },
      searchModal: false,
      productChanged: false,
      changedProducts: [],
    }
  },
  mounted() {
    this.initTable()
    // after Table DOM & data Loaded
    this.tabulator.on("renderComplete", () => {
      // Sub Buttons
      let subButtons = document.querySelectorAll("div[data-btn-type='sub']")
      subButtons.forEach(element => element.onclick = () => this.subProduct(element.dataset.id))
      // Compensate Buttons
      let compensateButton = document.querySelectorAll("div[data-btn-type='compensate']")
      compensateButton.forEach(element => element.onclick = () => this.compensateProduct(element.dataset.id))
    });
  },
  methods: {
    refreshTable() {
      this.tabulator.setData(this.invoice.Items)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: false,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        columns: [
          {
            formatter: "responsiveCollapse",
            width: 40,
            minWidth: 30,
            hozAlign: "center",
            resizable: false,
            headerSort: false
          },
          {
            title: 'نام',
            field: 'product.Name',
            formatter: cell => {
              return `<div class="flex items-center">
                        <img src="${this.$helper.imageURL(cell.getData().Product?.Image)}" alt="" class="${cell.getData().Product?.Image ? 'w-12 h-12' : ''}">
                        <span class="whitespace-pre-wrap ${cell.getData().Product?.Image ? 'mr-2' : ''}">${cell.getData().Product.Name}</span>
                      </div>`
            }
          },
          {
            title: 'تعداد',
            field: 'Amount',
            vertAlign: 'middle'
          },
          {
            title: 'جزئیات',
            field: 'StockDetails',
            vertAlign: 'middle',
            formatter: cell => {
              let template = '<div>'
              cell.getData().StockDetails?.forEach((stockDetail, index) => {
                template += `<div class="flex justify-between gap-x-3 lg:gap-x-6 ${index + 1 < cell.getData().StockDetails.length ? 'border-b mb-2 pb-2' : ''}">
                                  <span> پالت ${stockDetail.StockId}</span>
                                  <span>${stockDetail.Amount} عدد</span>
                                  <span>${this.$helper.priceFormatter(stockDetail.JcoPrice)}</span>
                                  ${this.canEdit ?
                    `<div class="flex">
                                        ${stockDetail.RejectAmount > 0
                      ?
                      `<div class="bg-yellow-100 rounded p-1 cursor-pointer" data-btn-type="compensate" data-id="${stockDetail.StockDetailId}" title="اعمال تخفیف">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-yellow-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                            </svg>
                                          </div>`
                      :
                      ``
                    }
                                        ${stockDetail.Amount > 0 ?
                      `<div class="bg-red-100 rounded p-1 cursor-pointer mr-4" data-btn-type="sub" data-id="${stockDetail.StockDetailId}">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-red-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                                            </svg>
                                         </div>`
                      : ``
                    }
                                    </div>` : ''
                  }
                                </div>
                                ${stockDetail.RejectReason ?
                    `<div class="text-xs text-red-500 mt-1">
                                        ${stockDetail.RejectReason.Name} (${stockDetail.RejectAmount} عدد)
                                    </div>` : ''}`
              })

              return template + '</div>'
            }
          },
        ],
      });
    },
    subProduct(stockDetailId) {
      this.productChanged = true
      this.invoice.Items.forEach(item => {
        item.StockDetails.forEach(stockDetail => {
          if (stockDetail.StockDetailId == stockDetailId) {
            stockDetail.Amount--
            item.Amount--
            this.changedProducts.push({
              StockDetailId: stockDetail.StockDetailId,
              Amount: stockDetail.Amount
            })
          }
        })
      })

      this.refreshTable()
    },
    compensateProduct(stockDetailId) {
      compensateStockDetail(stockDetailId)
        .then(resp => {
          this.$infoModal.value = resp.data.message
          this.$emit('productChanged', resp.data.data)
        })
    },
    submitProductChanges(step) {
      changeInvoiceProducts(this.invoice.InvoiceId, this.changedProducts, step)
        .then(resp => {
          this.itemsNewPrice = resp.data.data
          this.itemsNewPriceModal = true
          if (step === 2) {
            this.$emit('productChanged', resp.data.data)
            this.itemsNewPriceModal = false
          }
        })
    }
  },
  watch: {
    invoice(invoice) {
      if (invoice.InvoiceId)
        this.refreshTable()
    }
  }
}
</script>

<template>
  <ModalView
      title="ویرایش بازه" @opened="getDeactivateReasons">

    <form @submit.prevent="deactivate" @closed="resetData">

      <div class="flex justify-between items-center mt-4">
        <span>وضعیت</span>
        <VSwitch v-model="editDeliveryDateTime.isActive" :value="editDeliveryDateTime.isActive"/>
      </div>

      <VInput class="mt-4" type="number" placeholder="ظرفیت" v-model="editDeliveryDateTime.basketCount" required
              v-if="editDeliveryDateTime.isActive"/>

      <div v-if="!editDeliveryDateTime.isActive">
        <Multiselect
            dir="rtl"
            class="mt-4 border-primary"
            :options="deactivateReasons"
            v-model="editDeliveryDateTime.deactivateReasonId"
            :searchable="true"
            placeholder="دلیل غیرفعال سازی"
            required/>

        <VInput class="mt-4" placeholder="توضیحات" v-model="editDeliveryDateTime.deactivateDescription"/>

      </div>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>

  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView.vue";
import Multiselect from "@vueform/multiselect";
import VButton from "@/components/General/VButton.vue";
import VInput from "@/components/General/VInput.vue";
import {getLookupCombos, updateDeliveryDateTime} from "@/API/API.js";
import VSwitch from "@/components/General/VSwitch.vue";

let deliveryDateTimeModel = {
  isActive: 0,
  basketCount: '',
  deactivateReasonId: '',
  deactivateDescription: ''
}

export default {
  name: "EditDeliveryDateTimeModal",
  emits: ['submit'],
  props: ['deliveryDatetime'],
  components: {VSwitch, ModalView, VButton, Multiselect, VInput},
  data() {
    return {
      deactivateReasons: [],

      editDeliveryDateTime: {...deliveryDateTimeModel}
    }
  },
  methods: {
    async getDeactivateReasons() {
      this.deactivateReasons = (await getLookupCombos(this.$route.meta.code)).data.data.Lookups.Lookups28.map(reason => ({
        label: reason.Name,
        value: reason.LookupId
      }))
    },
    deactivate() {
      updateDeliveryDateTime(this.deliveryDatetime.DeliveryDateTimeId, this.editDeliveryDateTime)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$emit('submit', resp.data.data)
            this.resetData()
          })
    },
    resetData() {
      this.editDeliveryDateTime = {...deliveryDateTimeModel}
    }
  },
  watch: {
    deliveryDatetime() {
      this.editDeliveryDateTime.deactivateReasonId = this.deliveryDatetime.DeactivateReasonLId
      this.editDeliveryDateTime.deactivateDescription = this.deliveryDatetime.DeactivateReason
      this.editDeliveryDateTime.isActive = this.deliveryDatetime.IsActive
      this.editDeliveryDateTime.basketCount = this.deliveryDatetime.BasketCount
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <KeyValueCard :title="`جزئیات مرجوعی (${invoiceReturn.InvoiceReturnId})`">
    <KeyValueCardItem title="شماره سفارش" :value="invoiceReturn.InvoiceId"/>
    <KeyValueCardItem title="وضعیت" :value="invoiceReturn.State?.Name" value-class="text-yellow-500"/>
    <KeyValueCardItem title="مبلغ" :value="$helper.priceFormatter(invoiceReturn.TotalReturnPrice)"/>
    <KeyValueCardItem title="تاریخ برگشت" :value="invoiceReturn.DeliveryDateTime?.DeliveryDate"/>
    <KeyValueCardItem title="زمان برگشت" :value="invoiceReturn.DeliveryDateTime?.Description ?? '-'"/>
    <KeyValueCardItem title="توضیحات" :value="invoiceReturn.Description ?? '-'" last/>
  </KeyValueCard>
</template>

<script>
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
export default {
  name: "InvoiceReturnDetailCard",
  props: ['invoiceReturn'],
  components: {KeyValueCardItem, KeyValueCard}
}
</script>

<style scoped>

</style>
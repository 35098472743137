<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <VButton class="intro-y mb-4" @click="accept" v-if="invoiceReturn.Acceptable"
               :loading="$loading.value">
        قبول
      </VButton>
      <div class="mb-4" v-else-if="canEdit">
        <VButton outline @click="back" :loading="$loading.value">
          برگشت مرجوعی به پنل انباردار های بررسی
        </VButton>
        <VButton class="mt-4" @click="submit(true)" :loading="$loading.value">ثبت</VButton>
      </div>

      <!-- InvoiceReturn Info-->
      <InvoiceReturnInfoCard :invoiceReturn="invoiceReturn"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Items -->
      <InvoiceReturnProductsTable
          :items="invoiceReturn.Items"
          type="inventory-staff-receive"
          :canEdit="canEdit"
          @returnDetailEdited="returnDetailEdited"/>

      <!-- Coordination -->
      <CoordinationsTable :coordinations="invoiceReturn.Coordinations"/>

    </div>
  </div>

  <ConfirmModal
      v-model="confirmModal"
      title="ثبت مرجوعی"
      text="از ثبت مرجوعی مطمئن هستید ؟"
      @cancel="confirmModal = false"
      @confirm="submit(false)"/>

  <ReceiveInvoiceReturnModal
      v-model="receiveInvoiceReturnModal"
      :invoiceReturnId="invoiceReturn.InvoiceReturnId"
      type="inventory-staff-receive"
      @close="receiveInvoiceReturnModal = false"
      @submitted="receiveSubmitted"/>
</template>

<script>
import {acceptInvoiceReturn, backInvoiceReturn, getInvoiceReturn, submitInvoiceReturn} from "@/API/API";
import InvoiceReturnInfoCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnInfoCard";
import CoordinationsTable from "@/components/CoordinationsTable";
import VButton from "@/components/General/VButton";
import InvoiceReturnProductsTable from "@/components/InvoiceReturn/InvoiceReturnProductsTable";
import {mapState} from "vuex";
import ConfirmModal from "@/components/Modal/General/ConfirmModal";
import ReceiveInvoiceReturnModal from "@/components/Modal/ReceiveInvoiceReturnModal";

export default {
  name: "InvoiceReturnDetail",
  components: {
    ReceiveInvoiceReturnModal,
    ConfirmModal,
    InvoiceReturnProductsTable,
    VButton,
    CoordinationsTable,
    InvoiceReturnInfoCard,
  },
  data() {
    return {
      invoiceReturn: {},
      confirmModal: false,
      receiveInvoiceReturnModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
    canEdit() {
      return this.invoiceReturn.StaffId === this.user.UserId
    },
    canSubmit() {
      return this.invoiceReturn.Items.reduce((errorsCount, item) => {
        if (item.InventoryHealthyAmount === null || item.InventoryDefectAmount === null || item.InventoryNoNeedAmount === null) {
          return ++errorsCount
        }
        return errorsCount
      }, 0) === 0
    }
  },
  created() {
    this.getInvoiceReturn()
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id, 'inventory-staff-receive')).data.data
    },
    accept() {
      acceptInvoiceReturn(this.$route.params.id)
          .then(resp => Object.assign(this.invoiceReturn, resp.data.data))
    },
    back() {
      backInvoiceReturn(this.$route.params.id)
          .then(resp => {
            this.$infoModal.value = resp.data.message
            this.$router.back()
          })
    },
    submit(confirm) {
      if (confirm) {
        if (!this.canSubmit) {
          this.$errorModal.value = 'لطفا مقادیر را کامل وارد کنید.'
          return
        }
        this.confirmModal = true
        return
      }
      this.confirmModal = false

      const allowed = ['InvoiceReturnDetailId', 'InventoryHealthyAmount', 'InventoryDefectAmount', 'InventoryNoNeedAmount'];
      let items = this.$helper.filterObjectArray(this.invoiceReturn.Items, allowed)

      submitInvoiceReturn(this.invoiceReturn.InvoiceReturnId, {items})
          .then(resp => {
            this.invoiceReturn = resp.data.data
            this.receiveInvoiceReturnModal = true
          })
    },
    returnDetailEdited(returnDetail) {
      let index = this.invoiceReturn.Items.findIndex(item => item.InvoiceReturnDetailId === returnDetail.InvoiceReturnDetailId)
      this.invoiceReturn.Items[index] = returnDetail
    },
    receiveSubmitted() {
      this.receiveInvoiceReturnModal = false
      this.$router.replace({name: 'InventoryStaffInvoiceReturnList'})
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="grid grid-cols-4 gap-4">

    <!-- Right Panel -->
    <div class="col-span-4 xl:col-span-1">
      <!-- InvoiceReturn Info-->
      <InvoiceReturnInfoCard :invoiceReturn="invoiceReturn"/>
    </div>

    <!-- Left Panel -->
    <div class="col-span-4 col-span-4 xl:col-span-3">
      <!-- Items -->
      <InvoiceReturnProductsTable :items="invoiceReturn.Items"/>

      <!-- Coordination -->
      <CoordinationsTable :coordinations="invoiceReturn.coordinations"/>

    </div>
  </div>

</template>

<script>
import {getInvoiceReturn} from "@/API/API";
import InvoiceReturnProductsTable from "@/components/InvoiceReturn/InvoiceReturnProductsTable";
import CoordinationsTable from "@/components/CoordinationsTable";
import InvoiceReturnInfoCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnInfoCard";

export default {
  name: "InvoiceReturnDetail",
  components: {
    InvoiceReturnProductsTable,
    CoordinationsTable,
    InvoiceReturnInfoCard,
  },
  data() {
    return {
      invoiceReturn: {},

      assignModal: false,
    }
  },
  created() {
    this.getInvoiceReturn()
  },
  computed: {
    canEdit() {
      return this.invoiceReturn.StaffId === null
    }
  },
  methods: {
    async getInvoiceReturn() {
      this.invoiceReturn = (await getInvoiceReturn(this.$route.params.id, 'distributor-manager')).data.data
    },
    assigned() {
      this.assignModal = false
      this.getInvoiceReturn()
    }
  }
}
</script>

<style scoped>

</style>
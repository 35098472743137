<template>
  <div class="p-4">

    <!-- Top Filter -->
    <div class="flex gap-4 mr-32">
      <button
          class="border rounded-md px-2 py-1 cursor-pointer disabled:cursor-not-allowed disabled:text-gray-400"
          :class="type === type1 ? 'border-primary text-primary bg-primary/10' : 'border-gray-400'"
          v-for="type in types"
          :key="type.id"
          @click="type1 = type; type2 = ''">
        {{ type.name }}
      </button>
    </div>

    <div class="flex">
      <!-- Right Filter -->
      <div class="flex flex-col items-end gap-4 mt-6 w-fit shrink-0">
        <button
            class="w-fit border rounded-md px-2 py-1 cursor-pointer disabled:cursor-not-allowed disabled:text-gray-400"
            :class="type === type2 ? 'border-primary text-primary bg-primary/10' : 'border-gray-400'"
            v-for="type in types"
            :key="type.id"
            @click="type2 = type"
            :disabled="type2Disabled(type)">
          {{ type.name }}
        </button>
      </div>

      <!-- Table -->
      <VCard class="mt-4 mr-4 bg-transparent mt-4 text-slate-600 w-full" no-padding>
        <div ref="table"></div>
      </VCard>
    </div>

  </div>
</template>

<script>
import {getReport} from "@/API/API";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import VCard from "@/components/General/VCard";

export default {
  name: "ReportPage",
  components: {VCard},
  data() {
    return {
      types: [
        {id: 1, name: 'کالا'},
        {id: 2, name: 'کد ردیابی'},
        {id: 3, name: 'سفارش'},
        {id: 4, name: 'مشتری'},
        {id: 5, name: 'برند'},
        {id: 6, name: 'منطقه'},
        {id: 7, name: 'مرجوعی'},
      ],
      type1: '',
      type2: '',

      report: '',
      selectedRow: '',
    }
  },
  mounted() {
    this.initTable()
    this.tabulator.on("rowSelectionChanged", data => this.selectedRow = data[0])
  },
  computed: {},
  methods: {
    async gerReport() {
      this.report = (await getReport(this.type1.id, this.type2.id, this.selectedRow)).data
      this.tabulator.setData(this.report)
    },
    initTable() {
      this.tabulator = new Tabulator(this.$refs.table, {
        selectable: 1,
        layout: "fitDataFill",
        responsiveLayout: "collapse",
        autoColumns: true,
        autoColumnsDefinitions: definitions => {
          definitions.forEach(column => column.headerSort = false)
          return definitions;
        },
      });
    },
    type2Disabled(type2) {
      if (!this.type1 || !this.selectedRow)
        return true

      let allow = {
        1: [2, 6, 7],
        2: [3, 7],
        3: [2, 6, 7],
        4: [3, 7],
        5: [6, 7],
        6: [1, 3, 5, 7],
        7: [1, 2, 3, 5, 6]
      }

      return !allow[this.type1.id].includes(type2.id)
    }
  },
  watch: {
    type1() {
      this.gerReport()
    },
    type2() {
      this.gerReport()
    }
  }
}
</script>

<style scoped>

</style>
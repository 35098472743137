<template>
  <button class="flex justify-center w-full py-2 px-4 border rounded-lg shadow-md transition disabled:grayscale"
          :class="outline ? 'border-primary text-primary hover:bg-primary/10' : 'bg-primary text-white hover:bg-primary/90'"
          :disabled="$loading.value || loading || disabled"
          @touchstart="toggleAnimation($event)" @touchend="toggleAnimation($event)"
          @mousedown="toggleAnimation($event)" @mouseup="toggleAnimation($event)">
    <slot></slot>
  </button>

</template>

<script>

export default {
  name: "VButton",
  props: {
    'loading': Boolean,
    'outline': Boolean,
    'disabled': Boolean
  },
  methods: {
    toggleAnimation(e) {
      if (!this.disabled)
        e.currentTarget.classList.toggle('scale-90')
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <ModalView :title="editMode ? 'ویرایش کمپین' : 'ایجاد کمپین'"
             @opened="initDropzone">

    <form @submit.prevent="editMode ? update() : create()" class="mt-4">

      <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"></div>

      <VInput class="mt-4" type="text" placeholder="نام کمپین" v-model="campaign.Name"/>

      <div class="mt-4">
        <DatePicker auto-submit v-model="campaign.StartDate" placeholder="تاریخ شروع"
                    format="YYYY/MM/DD" displayFormat="jYYYY/jMM/jDD" :min="new Date()"/>
      </div>

      <div class="mt-4">
        <DatePicker auto-submit v-model="campaign.EndDate" placeholder="تاریخ پایان"
                    format="YYYY/MM/DD" displayFormat="jYYYY/jMM/jDD" :min="new Date()"/>
      </div>

      <VButton class="mt-4" type="submit">ثبت</VButton>
    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import {createCampaign, updateCampaign} from "@/API/API";
import {Dropzone} from "dropzone";
import DatePicker from "vue3-persian-datetime-picker";

const campaignModel = {
  Name: '',
  StartDate: '',
  EndDate: '',
  InventoryId: '',
  Image: null,
}

export default {
  name: "CreateCampaignModal",
  emits: ['created', 'updated'],
  props: ['editCampaign'],
  components: {VButton, VInput, ModalView, DatePicker},
  data() {
    return {
      editMode: false,

      campaign: {...campaignModel},
    }
  },
  methods: {
    create() {
      if (!this.campaign.Image) {
        this.$errorModal.value = 'لطفا برای کمپین عکس بارگذاری کنید.'
        return
      }

      this.campaign.InventoryId = this.$store.getters.selectedInventory
      createCampaign(this.campaign)
          .then(resp => {
            this.$emit('created', resp.data.data)
            this.campaign = {...campaignModel}
          })
    },
    update() {
      this.campaign.Image = this.campaign.Image?.ReferenceId ? null : this.campaign.Image

      updateCampaign(this.editCampaign.CampaignId, this.campaign)
          .then(resp => this.$emit('updated', resp.data.data))
    },
    initDropzone() {
      setTimeout(() => {
        let dropzone = new Dropzone(this.$refs.dropzone, {
          url: process.env.VUE_APP_STAFF_URL + 'image',
          headers: {'Authorization': this.$store.getters.token},
          maxFilesize: 1,
          dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
          dictDefaultMessage: 'انتخاب تصاویر',
          dictCancelUpload: 'لغو',
          dictRemoveFile: 'حذف',
          dictMaxFilesExceeded: 'حداکثر یک تصویر میتوانید آپلود کنید.',
          acceptedFiles: 'image/jpeg, image/jpg, image/png, image/webp',
          addRemoveLinks: true,
          maxFiles: 1,
          sending(file, xhr, formData) {
            formData.append('type', 'image')
          },
          success: (file, response) => {
            file.JCoFileId = response.JCoFileId;
            this.campaign.Image = {
              JCoFileId: response.JCoFileId,
              Path: response.Path
            }
          },
        });

        // Load Previous Images
        if (this.campaign.Image) {
          let mockFile = {name: 'image', size: 0, JCoFileId: this.campaign.Image.JCoFileId};
          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(this.campaign.Image.Path));
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          dropzone.options.maxFiles -= 1;
        }

        // Delete Image
        dropzone.on("removedfile", file => {
          if (file.JCoFileId) {
            this.campaign.Image = null
            dropzone.options.maxFiles += 1
          }
        })
      }, 50)
    }
  },
  watch: {
    editCampaign(editCampaign) {
      if (editCampaign.CampaignId) {
        this.editMode = true
        this.campaign = this.$helper.cloneObject(this.editCampaign, Object.keys(campaignModel))
      } else {
        this.editMode = false

        this.campaign = {...campaignModel}
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <!-- InvoiceReturn Info -->
  <InvoiceReturnDetailCard :invoiceReturn="invoiceReturn"/>

  <!-- Attachments -->
  <VCard class="mt-4">
    <h1 class="text-lg">صوت</h1>
    <audio class="mt-4 w-full" v-if="invoiceReturn.Audio" :src="$helper.imageURL(invoiceReturn.Audio.Path)" controls/>
    <div class="text-sm text-gray-400 mt-2" v-else>ندارد</div>
  </VCard>

  <!-- Customer Detail -->
  <KeyValueCard class="mt-4" title="اطلاعات مشتری">
    <KeyValueCardItem title="نام و نام خانوادگی" :value="invoiceReturn.User?.Name"/>
    <KeyValueCardItem title="شماره موبایل" :value="invoiceReturn.User?.Mobile" last/>
  </KeyValueCard>

  <!-- Distributor Detail -->
  <KeyValueCard class="mt-4" title="اطلاعات موزع">
    <KeyValueCardItem title="نام و نام خانوادگی" :value="invoiceReturn.Distributor?.Name"/>
    <KeyValueCardItem title="شماره موبایل" :value="invoiceReturn.Distributor?.Mobile" last/>
  </KeyValueCard>

</template>

<script>
import VCard from "@/components/General/VCard";
import KeyValueCard from "@/components/KeyValueCard";
import KeyValueCardItem from "@/components/KeyValueCardItem";
import InvoiceReturnDetailCard from "@/components/InvoiceReturn/InfoCard/InvoiceReturnDetailCard";

export default {
  name: "InvoiceReturnInfoCard",
  props: ['invoiceReturn'],
  components: {
    InvoiceReturnDetailCard,
    KeyValueCardItem,
    KeyValueCard,
    VCard,
  }
}
</script>

<style scoped>

</style>
<template>

  <router-view></router-view>

</template>

<script>
export default {
  name: "IndexPage"
}
</script>

<style scoped>

</style>
<template>
  <ModalView title="ثبت انباردار">

    <div class="mt-2">
      <span class="text-gray-400">انباردار: </span>
      <span>{{ invoiceReturn.StaffName }}</span>
    </div>

    <div class="flex justify-between mt-2 text-sm">
      <div>
        <span class="text-gray-400">وضعیت: </span>
        <span v-if="invoiceReturn.State">{{ invoiceReturn.State.Name }}</span>
      </div>

      <div>
        <span class="text-gray-400">کد تایید: </span>
        <span class="ltr">{{ invoiceReturn.DeliveryCode }}</span>
      </div>
    </div>

    <!-- Items -->
    <div class="max-h-96 overflow-y-scroll">
      <div
          class="text-sm mt-4 border rounded p-2"
          v-for="item in invoiceReturn.Items"
          :key="item.InvoiceReturnDetailId">

        <div class="flex items-center">
          <img :src="$helper.imageURL(item.StockDetail.Product.ImageUrl)" alt="" class="w-12">
          <div class="mr-2">
            <span>{{ item.StockDetail.Product.Name }}</span>

            <div class="grid grid-cols-2 mt-1">
              <div class="col-span">
                <span class="text-gray-500 text-xs">تعداد : </span>
                <span>{{ item.Amount }} عدد</span>
              </div>

              <div class="col-span">
                <span class="text-gray-500 text-xs">عدم بازگشت : </span>
                <span>{{ item.InventoryNoNeedAmount !== null ? item.InventoryNoNeedAmount + ' عدد' : '-' }}</span>
              </div>

              <div>
                <span class="text-gray-500 text-xs">سالم: </span>
                <span>{{ item.InventoryHealthyAmount !== null ? item.InventoryHealthyAmount + ' عدد' : '-' }}</span>
              </div>

              <div>
                <span class="text-gray-500 text-xs">ضایعاتی: </span>
                <span>{{ item.InventoryDefectAmount !== null ? item.InventoryDefectAmount + ' عدد' : '-' }}</span>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </ModalView>
</template>

<script>

import ModalView from "@/components/Modal/General/ModalView";

export default {
  name: "InvoiceReturnDetailModal",
  props: ['invoiceReturn'],
  components: {ModalView}
}
</script>

<style scoped>

</style>
<template>
  <ul>
    <li class="border-b border-white/20 pb-4">
      <h2 class="text-white text-lg">انبار:
        {{ userInventories?.find(item => item.InventoryId === selectedInventory)?.Name }}</h2>
    </li>

    <SideMenuItem
        v-for="menu in filteredMenu"
        :key="menu.name"
        :title="menu.title"
        :route-name="menu.name"
        :icon="menu.icon"
        :mobile="mobile">

      <SideMenuItem
          v-for="subMenu in menu.children"
          :key="subMenu.name"
          :title="subMenu.title"
          :route-name="subMenu.name"
          :icon="subMenu.icon"
          :mobile="mobile"
          sub-menu/>

    </SideMenuItem>

    <li class="h-12" v-if="mobile"></li>
  </ul>
</template>

<script>
import SideMenuItem from "@/components/Layout/SideMenuItem";
import {mapGetters, mapState} from "vuex";
import menu from '@/utils/menu.js'

export default {
  name: "SideMenuItems",
  props: {
    mobile: Boolean
  },
  components: {SideMenuItem},
  computed: {
    ...mapState(['menuIds']),
    ...mapGetters(['userInventories', 'selectedInventory', 'isLoggedIn']),
  },
  data() {
    return {
      filteredMenu: [],
    }
  },
  async created() {
    const menus = this.$helper.cloneObject(menu)
    if (this.isLoggedIn)
      this.filteredMenu = menus.filter(menu => {
        menu.children = menu.children.filter(subMenu => this.hasAccess(subMenu.name))
        return this.hasAccess(menu.name)
      })
  },
  methods: {
    hasAccess(name) {
      return this.menuIds.includes(this.$router.resolve({name}).meta.code)
    }
  },
}
</script>

<style scoped>

</style>
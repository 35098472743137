<template>
  <div class="rounded p-3 text-white my-2 text-sm"
       :class="bgColor">
    <slot></slot>
  </div>

</template>

<script>
export default {
  name: "VAlert",
  props: ['type'],
  computed: {
    bgColor() {
      switch (this.type) {
        case 'success':
          return 'bg-green-500'
        case 'error':
          return 'bg-red-500'
        default:
          return 'bg-green-500'
      }
    }
  }
}
</script>

<style scoped>

</style>
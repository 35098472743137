<template>
  <span class="border rounded-full px-4 py-0.5 text-xs mx-1"
        :class="bgColor">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "VBadge",
  props: ['type'],
  computed: {
    bgColor() {
      switch (this.type) {
        case 'success':
          return 'bg-green-50 border-green-500 text-green-500'
        case 'error':
          return 'bg-red-50 border-red-500 text-red-500'
        default:
          return 'bg-blue-50 border-blue-500 text-blue-500'
      }
    }
  }
}
</script>

<style scoped>

</style>